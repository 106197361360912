export const skillDevelopmentData = [
	{
		imageUrl: require("../assets/skill-development-center/1.png"),
		title: "Careers in Project Management Profiles:",
		description: (
			<>
				This program teaches students the essentials of managing projects,
				including project planning, execution, monitoring and control, and
				closure. The program prepares students for project management roles in
				various industries.
			</>
		),
	},
	// {
	// 	imageUrl: require("../assets/skill-development-center/2.png"),
	// 	title: "Career In Marketing",
	// 	description:
	// 		"This program trains students in the techniques and approaches used by consultants to solve complex business problems. The curriculum covers areas such as strategy, process improvement, change management, and risk assessment.",
	// },
	{
		imageUrl: require("../assets/skill-development-center/3.png"),
		title: "Careers in Cloud Computing",
		description:
			"This program provides students with in-depth knowledge of cloud technology, network security and cloud infrastructure.			",
	},
	{
		imageUrl: require("../assets/skill-development-center/4.png"),
		title: "Careers in Software Development",
		description:
			"This program that trains students in software design, software development methodologies and programming languages.			",
	},
	{
		imageUrl: require("../assets/skill-development-center/5.png"),
		title: "Careers Data Science and Machine Learning",
		description:
			"This program focuses on teaching students the theories, algorithms and tools required for big data analysis, machine learning and artificial intelligence.			",
	},
	{
		imageUrl: require("../assets/skill-development-center/5.png"),
		title: "Careers in Cybersecurity",
		description:
			"This program that trains students to secure information and communication systems, to detect and respond to cyber threats and to develop secure software.			",
	},
	// {
	// 	imageUrl: require("../assets/skill-development-center/5.png"),
	// 	title: "Careers in Cloud Computing",
	// 	description:
	// 		" This program provides students with in-depth knowledge of cloud technology, network security and cloud infrastructure.",
	// },
	// {
	// 	imageUrl: require("../assets/skill-development-center/5.png"),
	// 	title: "Careers in Business Analytics",
	// 	description:
	// 		"BA students are taught how to use Artificial Intelligence (AI), Data Quality, Data Analytics, Statistical Methods, and Data Visualization Models to assess past results and direct an organization's business planning.",
	// },
	// {
	// 	imageUrl: require("../assets/skill-development-center/5.png"),
	// 	title: "Careers in Software Development",
	// 	description:
	// 		"his program that trains students in software design, software development methodologies and programming languages",
	// },
	// {
	// 	imageUrl: require("../assets/skill-development-center/5.png"),
	// 	title: "Careers in Data Science and Machine Learning",
	// 	description:
	// 		"This program focuses on teaching students the theories, algorithms and tools required for big data analysis, machine learning and artificial intelligence. ",
	// },
	// {
	// 	imageUrl: require("../assets/skill-development-center/5.png"),
	// 	title: "Careers in Cybersecurity",
	// 	description:
	// 		"This program that trains students to secure information and communication systems, to detect and respond to cyber threats and to develop secure software.   ",
	// },
];
