import React from "react";
import { Image } from "react-bootstrap";
import "./ClubCard.style.scss";

const ClubCard = ({ title, imageUrl, onClick }) => {
	return (
		<div className="about-us-mini-card2" onClick={onClick}>
			<Image
				src={imageUrl.default ? imageUrl.default : imageUrl}
				alt={title}
				style={{
					width: " 40%",
					display: "block",
					marginLeft: "auto",
					marginRight: "auto",
				}}
				className="about-us-mini-card2--image"
			/>
			<div className="about-us-mini-card2--title">
				<h6 style={{ fontSize: "15px" }}>{title}</h6>
			</div>
		</div>
	);
};

export default ClubCard;
