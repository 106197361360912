import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";

import PageSection from "../PageSection/PageSection";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";

const IndustryInterfaceCenterResponsive = () => {
  const [currentBreakPoint, setCurrentBreakPoint] = useState("");

  const descriptionClasses = classNames("mb-4 text-center text-text-color", {
    "font-sm": currentBreakPoint === "xSmall" || currentBreakPoint === "small",
  });

  useEffect(() => {
    bsBreakpoints.init();
    setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

    const handleBreakPoint = (event) => {
      setCurrentBreakPoint(event.detail);
    };

    window.addEventListener("new.bs.breakpoint", handleBreakPoint);

    return () => {
      window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
    };
  }, []);

  return (
    <PageSection bgClass="background-color">
      <ResponsivePageHeader centered={true} title="Industry Interface Center" />
      <p className={descriptionClasses}>
        Since the last 14 years, our Industry Interface Center has a proven
        track record. Our centre is linked to over 1000+ MNCs and large
        industries in several core sectors, including IT, management consulting,
        NBFCs, and investment banking, as well as over 200 startups, including
        several unicorns in emerging technologies.
      </p>
      <p className={descriptionClasses}>
        Our super-specialized training provides students with a comprehensive
        understanding as well as the industry's required skills and expertise,
        ensuring that they are prepared to begin their promising career. In
        turn, candidates gain industry-based analytical knowledge and soft
        skills related to specific fields of expertise.
      </p>
    </PageSection>
  );
};

export default IndustryInterfaceCenterResponsive;
