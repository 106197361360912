import React from "react";
import { Col, Image, Row } from "react-bootstrap";

import "./skillDevelopmentCard.style.scss";

const SkillDevelopmentCard = ({ imageUrl, title, description }) => {
  return (
    <Row className="skill-development-card">
      <Col md="4" className="mb-2 mb-md-0">
        <Image
          src={imageUrl.default ? imageUrl.default : imageUrl}
          alt={title}
          className="img-fluid skill-development-card--image"
        />
      </Col>
      <Col md="8">
        <div className="skill-development-card--content">
          <h5 className="font-paragraph-font font-weigth-medium text-highlight-color">
            {title}
          </h5>
          <p className="text-white mb-0">{description}</p>
        </div>
      </Col>
    </Row>
  );
};

export default SkillDevelopmentCard;
