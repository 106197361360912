export const studentCellData = [
	{
		title: "Entrepreneurship Cell",
		imageUrl: require("../assets/clubs/club (1).png"),
	},
	{
		title: "Finance Club ",
		imageUrl: require("../assets/clubs/club (2).png"),
	},
	{
		title: "CP Club",
		imageUrl: require("../assets/clubs/club (3).png"),
	},
	{
		title: "Dance Club ",
		imageUrl: require("../assets/clubs/club (4).png"),
	},
	{
		title: "Music Club",
		imageUrl: require("../assets/clubs/club (5).png"),
	},
	{
		title: "Consulting Club",
		imageUrl: require("../assets/clubs/club (6).png"),
	},
	{
		title: "Dramatics Club",
		imageUrl: require("../assets/clubs/club (7).png"),
	},
	{
		title: "Debating Society ",
		imageUrl: require("../assets/clubs/club (8).png"),
	},
	{
		title: "Journalism Club",
		imageUrl: require("../assets/clubs/club (9).png"),
	},
];
