import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import { FaFacebookSquare, FaYoutube, FaLinkedin } from "react-icons/fa";

import NissLogo from "../../assets/logo/niis-logo.png";
import PageSection from "../PageSection/PageSection";
import { Link } from "react-router-dom";
import CustomIcon from "../CustomIcon/CustomIcon";

const CustomFooter = () => {
	return (
		<PageSection bgClass="heading-color">
			<h3 className="text-white font-weight-bold mb-4">
				Want to learn more <br /> about NIIS Group?
			</h3>
			<hr className="line-white" />
			<div className="py-4 text-white">
				<Row>
					<Col md="4" className="mb-5 mb-md-0">
						<Image src={NissLogo} alt="logo" className="img-fluid mb-4" />
						<p className="font-md font-weight-light">
							&copy; 2023 NIIS Institute of Engineering & Technology
							<br /> All rights reserved.
						</p>
						<div className="mb-4">
							<Link
								to="/"
								className=" font-weight-semibold text-white font-paragraph-font mr-2"
							>
								Privacy Policy
							</Link>
							<span className="font-sm font-weight-semibold text-white font-paragraph-font mr-2">
								|
							</span>
							<Link
								to="/"
								className=" font-weight-semibold text-white font-paragraph-font"
							>
								Terms & Conditions
							</Link>
						</div>
						<div>
							<a
								href="https://www.facebook.com/niisgrp"
								target="_blank"
								rel="noreferrer"
								className="mr-2"
							>
								<CustomIcon
									size="md"
									currentIcon={FaFacebookSquare}
									colorClass="text-white"
								/>
							</a>
							<a
								href="https://www.youtube.com/channel/UCSYzepBzIuYghyJXmdTRCjw"
								target="_blank"
								rel="noreferrer"
								className="mr-2"
							>
								<CustomIcon
									size="md"
									currentIcon={FaYoutube}
									colorClass="text-white"
								/>
							</a>
							<a
								href="https://www.linkedin.com/company/niis-group-of-institutions"
								target="_blank"
								rel="noreferrer"
							>
								<CustomIcon
									size="md"
									currentIcon={FaLinkedin}
									colorClass="text-white"
								/>
							</a>
						</div>
					</Col>
					<Col md="4" className="mb-5 mb-md-0">
						<p className="display-9 font-weight-normal font-heading-font">
							Visit our campus :
						</p>
						<p className="font-weight-light">
							At - Chhatabar <br/>
							P.o. - Chhatabar <br/>
							Via - Janla (Bhubaneswar) <br/>
							Dist - Khurdha <br/>
							Bhubaneswar, Khordha, Odisha, 752054
						</p>
					</Col>
					<Col md="4">
						<p className="display-9 font-weight-normal font-heading-font">
							Helpful Mobile Numbers :
						</p>
						<Row className="mb-4">
							<Col xs="6">
								<a
									href="tel:9040004375"
									className="mb-2 d-block font-weight-light font-paragraph-font font-default text-white text-white-hover text-white-active text-nowrap"
								>
									+91 9040004375
								</a>
							</Col>
							<Col xs="6">
								<a
									href="tel:9778899990"
									className="mb-2 d-block font-weight-light font-paragraph-font font-default text-white text-white-hover text-white-active text-nowrap"
								>
									+91 9778899990
								</a>
							</Col>
							<Col xs="6">
								<a
									href="tel:9778899991"
									className="mb-2 d-block font-weight-light font-paragraph-font font-default text-white text-white-hover text-white-active text-nowrap"
								>
									+91 9778899991
								</a>
							</Col>
							<Col xs="6">
								<a
									href="tel:9438767679"
									className="mb-2 d-block font-weight-light font-paragraph-font font-default text-white text-white-hover text-white-active text-nowrap"
								>
									+91-9438767679
								</a>
							</Col>
						</Row>
						<p className="display-9 font-weight-normal font-heading-font">
							Email us :
						</p>
						<a
							href="mailto:contactus@niisgroup.org"
							className="font-default font-weight-light text-white"
						>
							contactus@niisgroup.org
						</a>
					</Col>
				</Row>
			</div>
		</PageSection>
	);
};

export default CustomFooter;
