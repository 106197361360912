import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import PageHeader from "../../components/PageHeader/PageHeader";
import PageSectionHeader from "../../components/PageSectionHeader/PageSectionHeader";
import PageSection from "../../components/PageSection/PageSection";
import PageWrapper from "../../components/PageWrapper/PageWrapper";

const SkillDevelopmentCenterPage = () => {
  return (
    <PageWrapper>
      <PageHeader title="Skill Development Center" />
      <PageSection>
        <p>
          This pedagogy of super-specialized training provides students with a
          comprehensive understanding along with the need based skills and
          expertise by the industry, ensuring that they are ready to start off
          their promising career. The candidates in turn acquire industry based
          analytical knowledge and soft skills pertaining to particular fields
          of expertise.
        </p>
      </PageSection>
      <PageSection bgClass="light">
        <div className="ml-2">
          <ul>
            <li>
              <p>
                Career in Finance- Financial management is an essential
                component of every company.It is the practise of preparing,
                coordinating, managing, and reporting financial capital in order
                to meet the aims and objectives of an organisation.
              </p>
              <p>
                Students studying Finance as a specialisation receive
                comprehensive financial preparation, which includes gathering
                specific financial data about the business(es) and representing
                the data so that critical estimates about the firm, such as
                valuation, can be generated.
              </p>
            </li>
            <li>
              <p>
                Career In Marketing- Students in the Marketing Specialization
                are taught about marketing roles, problems, sales forecasting,
                and other topics.The discovery and comprehension of consumer
                desires is the focus of marketing and sales. The processes and
                practises involved in marketing and distributing products or
                services are referred to as sales, on the other hand, marketing
                refers to the method of advertising, delivering, and supplying a
                good or service.
              </p>
            </li>
            <li>
              <p>
                Careers in Human Resources- Students receive instruction in the
                general HR management role, which includes various day-to-day HR
                operations management. Human Resource Information Systems
                (HRIS), Employee Relations, Training and Development, Benefits,
                Compensation, and Organizational Development are all topics that
                need to be addressed.
              </p>
            </li>
            <li>
              <p>
                Careers in Digital Marketing Profiles- Organizations all over
                the globe are seeing the value of going new, and marketing is
                one of the first and most essential roles to evolve.Digital
                Marketing students are taught the cutting-edge skills that an
                organisation needs to establish its identity to attract the
                widest possible audience.
              </p>
            </li>
            <li>
              <p>
                Careers in Business Analytics- BA students are taught how to use
                Artificial Intelligence (AI), Data Quality, Data Analytics,
                Statistical Methods, and Data Visualization Models to assess
                past results and direct an organization's business planning.
              </p>
            </li>
          </ul>
        </div>
      </PageSection>
      <PageSection bgClass="light">
        <PageSectionHeader title="Gallery" rightStyle="bottom" />
        <Row className="justify-content-center">
          <Col md="6">
            <Image
              src={
                require("../../assets/skill-development-center/iic 4.JPG")
                  .default
              }
              className="img-fluid rounded mb-4"
            />
          </Col>
          <Col md="6">
            <Image
              src={
                require("../../assets/skill-development-center/iic 10.JPG")
                  .default
              }
              className="img-fluid rounded mb-4"
            />
          </Col>
          <Col md="6">
            <Image
              src={
                require("../../assets/skill-development-center/iicroom 2.jpg")
                  .default
              }
              className="img-fluid rounded mb-4"
            />
          </Col>
        </Row>
      </PageSection>
    </PageWrapper>
  );
};

export default SkillDevelopmentCenterPage;
