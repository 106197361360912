import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";
import { Row, Col, Image } from "react-bootstrap";
import PageHeader from "../PageHeader/PageHeader";
import PageSectionHeader from "../PageSectionHeader/PageSectionHeader";

import PageSection from "../PageSection/PageSection";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";

const FacultyDevelopmentProgramResponsive = () => {
	const [currentBreakPoint, setCurrentBreakPoint] = useState("");

	useEffect(() => {
		bsBreakpoints.init();
		setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

		const handleBreakPoint = (event) => {
			setCurrentBreakPoint(event.detail);
		};

		window.addEventListener("new.bs.breakpoint", handleBreakPoint);

		return () => {
			window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
		};
	}, []);

	const descriptionClasses = classNames("mb-4 text-center text-text-color", {
		"font-sm": currentBreakPoint === "xSmall" || currentBreakPoint === "small",
	});

	return (
		<PageSection bgClass="background-color">
			<ResponsivePageHeader
				centered={true}
				title="Faculty Development Program"
			/>
			<p className={descriptionClasses}>
				The academic excellence of NIIS has been spurred by the group of highly
				qualified, competent and motivated faculty members having updated
				knowledge in core subjects and experts in their concerned fields. But to
				help them keep up with the latest trends and technologies, to understand
				student psychology to the deepest, we guide the teachers with rigorous
				training programs and seminars as well- so they can be more interactive
				and malleable into the student world and understand their perspectives
				clearly as well.
			</p>
			<p className={descriptionClasses}>
				The programme aims to improve the faculty's instructional and other
				skills. Additionally, to educate them about contemporary teaching
				techniques and technologies. It offers a chance to learn more about
				recent technology advancements in pertinent domains. In addition to
				advancing professional practises pertinent to technical education, it
				will inspire the faculty to create a competitive atmosphere for teaching
				and learning, channelling growth in both personal and academic spheres.
			</p>
		</PageSection>
	);
};

export default FacultyDevelopmentProgramResponsive;
