import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";
import PageSection from "../PageSection/PageSection";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";
import { Row, Col, Image } from "react-bootstrap";
const InfrastructureCollegeResponsive = () => {
	const [currentBreakPoint, setCurrentBreakPoint] = useState("");

	useEffect(() => {
		bsBreakpoints.init();
		setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

		const handleBreakPoint = (event) => {
			setCurrentBreakPoint(event.detail);
		};

		window.addEventListener("new.bs.breakpoint", handleBreakPoint);

		return () => {
			window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
		};
	}, []);

	const descriptionClasses = classNames("mb-4 text-center text-text-color", {
		"font-sm": currentBreakPoint === "xSmall" || currentBreakPoint === "small",
	});

	return (
		<PageSection>
			<ResponsivePageHeader title="College Campus" />

			{/* <PageSectionHeader title="Collegeinfrastructure" rightStyle="bottom" /> */}
			<Row className="justify-content-center">
				<Col md="4" className="mb-4">
					<Image
						src={require("../../assets/infrastructure/campus (2).jpeg").default}
						className="img-fluid rounded mb-4 image-coverfit"
					/>
				</Col>

				<Col md="4" className="mb-4">
					<Image
						src={require("../../assets/infrastructure/campus (1).jpeg").default}
						className="img-fluid rounded mb-4 image-coverfit"
					/>
				</Col>
				<Col md="4" className="mb-4">
					<Image
						src={require("../../assets/infrastructure/campus (1).jpg").default}
						className="img-fluid rounded mb-4 image-coverfit"
					/>
				</Col>

				<Col md="4" className="mb-4">
					<Image
						src={require("../../assets/infrastructure/campus (2).jpg").default}
						className="img-fluid rounded mb-4 image-coverfit"
					/>
				</Col>
				<Col md="4" className="mb-4">
					<Image
						src={require("../../assets/infrastructure/campus (3).JPG").default}
						className="img-fluid rounded mb-4 image-coverfit"
					/>
				</Col>
				<Col md="4" className="mb-4">
					<Image
						src={require("../../assets/infrastructure/campus (4).jpg").default}
						className="img-fluid rounded mb-4 image-coverfit"
					/>
				</Col>
				<Col md="4" className="mb-4">
					<Image
						src={require("../../assets/infrastructure/campus (5).jpg").default}
						className="img-fluid rounded mb-4 image-coverfit"
					/>
				</Col>
				<Col md="4" className="mb-4">
					<Image
						src={require("../../assets/infrastructure/campus (6).JPG").default}
						className="img-fluid rounded mb-4 image-coverfit"
					/>
				</Col>

				<Col md="4" className="mb-4">
					<Image
						src={
							require("../../assets/infrastructure/aerial view 7.jpeg").default
						}
						className="img-fluid rounded mb-4 image-coverfit"
					/>
				</Col>
			</Row>
		</PageSection>
	);
};

export default InfrastructureCollegeResponsive;
