import React from "react";
import { Image } from "react-bootstrap";

const InfographyChartResponsive = () => {
  return (
    <div className="bg-white py-4 py-md-5 px-2 px-md-5">
      <Image
        src={require("../../assets/Inforaphics.png").default}
        className="img-fluid"
        alt="infography"
      />
    </div>
  );
};

export default InfographyChartResponsive;
