import { Row, Col, Image } from "react-bootstrap";
import React from "react";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import PageHeader from "../../components/PageHeader/PageHeader";
import PageSection from "../../components/PageSection/PageSection";
import PageSectionHeader from "../../components/PageSectionHeader/PageSectionHeader";
import AdmissionEnquiryForm from "../../components/AdmissionEnquiryForm/AdmissionEnquiryForm";

const AdmissionEnquiryPage = () => {
  return (
    <PageWrapper>
      <PageHeader title="Admission Enquiry" />
      <PageSection>
        <PageSectionHeader
          type="h5"
          title="Thank you for your interest in NIIS Group of Institutions."
        />
        <p>
          If you are interested in NIIS, we strongly recommend that you get in
          touch and come to our institute which we are so proud of and see how
          we work. Please fill in the enquiry form to log your interest in the
          NIIS and we will be in touch to arrange for a tour of our campus.
        </p>
      </PageSection>
      <PageSection bgClass="light">
        <PageSectionHeader title="Write to us" leftStyle="bottom" />
        {/* <AdmissionEnquiryForm /> */}
        <Row className="justify-content-between">
          <Col md="7">
            <div className="mb-4">
              <AdmissionEnquiryForm />
            </div>
          </Col>
          <Col md="4">
            <Image
              src={require("../../assets/enquiry/award delhi.JPG").default}
              className="img-fluid rounded mb-4"
            />
          </Col>
        </Row>
      </PageSection>
    </PageWrapper>
  );
};

export default AdmissionEnquiryPage;
