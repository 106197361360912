import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";
import { AiFillFilePdf } from "react-icons/ai";
import CustomIcon from "../CustomIcon/CustomIcon";
import PageSection from "../PageSection/PageSection";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";
import { Col, Row } from "react-bootstrap";

const AffiliationsResponsive = () => {
  const [currentBreakPoint, setCurrentBreakPoint] = useState("");

  const descriptionClasses = classNames("mb-4 text-center text-text-color", {
    "font-sm": currentBreakPoint === "xSmall" || currentBreakPoint === "small",
  });

  useEffect(() => {
    bsBreakpoints.init();
    setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

    const handleBreakPoint = (event) => {
      setCurrentBreakPoint(event.detail);
    };

    window.addEventListener("new.bs.breakpoint", handleBreakPoint);

    return () => {
      window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
    };
  }, []);

  return (
    <PageSection bgClass="background-color">
      <ResponsivePageHeader centered={true} title="Affiliations" />
      <Row className="justify-content-center">
        <Col md="3">
          <a
            href={process.env.PUBLIC_URL + "/LOA 2009 EOA 2010-11.pdf"}
            className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">LOA 2009 EOA 2010-11</span>
          </a>
        </Col>

        <Col md="3">
          <a
            href={process.env.PUBLIC_URL + "/EOA Report 2012-13.pdf"}
            className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">EOA Report 2012-13</span>
          </a>
        </Col>

        <Col md="3">
          <a
            href={process.env.PUBLIC_URL + "/EOA Report 2013-14.pdf"}
            className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">EOA Report 2013-14</span>
          </a>
        </Col>

        <Col md="3">
          <a
            href={process.env.PUBLIC_URL + "/EOA Report 2014-15.pdf"}
            className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">EOA Report 2014-15</span>
          </a>
        </Col>

        <br />
        <br />

        <Col md="3">
          <a
            href={process.env.PUBLIC_URL + "/EOA Report 2015-16.pdf"}
            className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">EOA Report 2015-16</span>
          </a>
        </Col>

        <Col md="3">
          <a
            href={process.env.PUBLIC_URL + "/EOA Report 2016-17.pdf"}
            className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">EOA Report 2016-17</span>
          </a>
        </Col>

        <Col md="3">
          <a
            href={process.env.PUBLIC_URL + "/EOA Report 2017-18.pdf"}
            className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">EOA Report 2017-18</span>
          </a>
        </Col>

        <Col md="3">
          <a
            href={process.env.PUBLIC_URL + "/EOA Report 2018-19.pdf"}
            className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">EOA Report 2018-19</span>
          </a>
        </Col>

        <br />
        <br />

        <Col md="3">
          <a
            href={process.env.PUBLIC_URL + "/EOA Report 2019-20.pdf"}
            className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">EOA Report 2019-20</span>
          </a>
        </Col>

        <Col md="3">
          <a
            href={process.env.PUBLIC_URL + "/EOA Report 2020-21.pdf"}
            className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">EOA Report 2020-21</span>
          </a>
        </Col>

        <Col md="3">
          <a
            href={process.env.PUBLIC_URL + "/EOA Report 2021-22.pdf"}
            className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">EOA Report 2021-22</span>
          </a>
        </Col>

        <br />
        <br />

        <Col md="3">
          <a
            href={process.env.PUBLIC_URL + "/EOA Report 2022-23.pdf"}
            className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">EOA Report 2022-23</span>
          </a>
        </Col>

        <Col md="3">
          <a
            href={process.env.PUBLIC_URL + "/EOA Report 2023-24.pdf"}
            className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">EOA Report 2023-24</span>
          </a>
        </Col>

        <Col md="3">
          <a
            href={process.env.PUBLIC_URL + "/Initial LOR 18-19.pdf"}
            className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
            target="_blank"
            rel="noopener noreferrer"
          >
            <CustomIcon currentIcon={AiFillFilePdf} size="md" />
            <span className="ml-2">Initial LOR 18-19</span>
          </a>
        </Col>
      </Row>
    </PageSection>
  );
};

export default AffiliationsResponsive;
