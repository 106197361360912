import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";
import PageSection from "../PageSection/PageSection";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";
import { Row, Col, Image } from "react-bootstrap";
const InfrastructureAuditoriumResponsive = () => {
	const [currentBreakPoint, setCurrentBreakPoint] = useState("");

	useEffect(() => {
		bsBreakpoints.init();
		setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

		const handleBreakPoint = (event) => {
			setCurrentBreakPoint(event.detail);
		};

		window.addEventListener("new.bs.breakpoint", handleBreakPoint);

		return () => {
			window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
		};
	}, []);

	const descriptionClasses = classNames("mb-4 text-center text-text-color", {
		"font-sm": currentBreakPoint === "xSmall" || currentBreakPoint === "small",
	});

	return (
		<PageSection>
			<ResponsivePageHeader title="Auditorium" />

			{/* <PageSectionHeader title="Collegeinfrastructure" rightStyle="bottom" /> */}
			<Row className="justify-content-center">
				<Row className="justify-content-center">
					<Col md="6">
						<Image
							src={
								require("../../assets/infrastructure/main hall 1.jpg").default
							}
							className="img-fluid rounded mb-4 image-coverfit"
						/>
					</Col>
					<Col md="6">
						<Image
							src={
								require("../../assets/infrastructure/main hall 2.jpg").default
							}
							className="img-fluid rounded mb-4 image-coverfit"
						/>
					</Col>
				</Row>
			</Row>
		</PageSection>
	);
};

export default InfrastructureAuditoriumResponsive;
