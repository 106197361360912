export const publicationData = [
	{
		SlNo: 1,
		Nameoftheteacher: "Dr. Rajesh Kumar Sain",
		Titleofthebook: "Financial Markets, institutions and services",
		type: "National",
		Yearofpublication: 2018,
		ISBNnumber: "978-93-86714-78-7",
		Affiliating: "NIBA",
		Nameofthepublisher: "Kunal Books, New Delhi",
	},
	{
		SlNo: 2,
		Nameoftheteacher: "Dr. Rajesh Kumar Sain",
		Titleofthebook: "Advance Management Accounting",
		type: "National",
		Yearofpublication: 2020,
		ISBNnumber: "978-93-90110-64-3",
		Affiliating: "NIBA",
		Nameofthepublisher: "Sawaranjali PublicationPvt. Ltd., Ghaziabad",
	},
	{
		SlNo: 3,
		Nameoftheteacher: "Dr. Rajesh Kumar Sain",
		Titleofthebook: "Financial Statement Analysis",
		type: "National",
		Yearofpublication: 2020,
		ISBNnumber: "978-93-89657-46-3",
		Affiliating: "NIBA",
		Nameofthepublisher: "Bharati Publication New Delhi",
	},
	{
		SlNo: 4,
		Nameoftheteacher: "Dr. Rajesh Kumar Sain",
		Titleofthebook: "International Financial Management",
		type: "National",
		Yearofpublication: 2020,
		ISBNnumber: "978-93-89234-32-9",
		Affiliating: "NIBA",
		Nameofthepublisher: "Kunal Books, New Delhi",
	},
	{
		SlNo: 5,
		Nameoftheteacher: "Dr. Rajesh Kumar Sain",
		Titleofthebook: "Contemporary Issues in Social Science ",
		type: "National",
		Yearofpublication: 2020,
		ISBNnumber: "978-93-8922-93-1",
		Affiliating: "NIBA",
		Nameofthepublisher: "Kunal Books, New Delhi",
	},
	{
		SlNo: 6,
		Nameoftheteacher: "Dr. Ratidev Samal",
		Titleofthebook: "Consumer Behaviour",
		type: "National",
		Yearofpublication: 2021,
		ISBNnumber: "978-93-8911-743-1",
		Affiliating: "NIBA",
		Nameofthepublisher: "City Publiation, New Delhi",
	},
	{
		SlNo: 7,
		Nameoftheteacher: "Dr. Rajesh Kumar Sain",
		Titleofthebook: "Cost Accounting",
		type: "National",
		Yearofpublication: 2021,
		ISBNnumber: "978-93-87684-91-1",
		Affiliating: "NIBA",
		Nameofthepublisher: "JSR Publishing House Llp, New Delhi",
	},
	{
		SlNo: 8,
		Nameoftheteacher: "Dr. Ratidev Samal",
		Titleofthebook: "Business, Economics and Society ",
		type: "National",
		Yearofpublication: 2021,
		ISBNnumber: "978-93-90937-31-8",
		Affiliating: "NIBA",
		Nameofthepublisher: "Red'Shine Publication Pvt. LTD., Lunawada",
	},
	{
		SlNo: 9,
		Nameoftheteacher: "Dr. Ratidev Samal",
		Titleofthebook:
			"Emerging Issues in Commerce, Economics Information Technology and Management",
		type: "National",
		Yearofpublication: 2021,
		ISBNnumber: "979-8-4997-8852-3",
		Affiliating: "NIBA",
		Nameofthepublisher: "Red'Shine Publication Pvt. LTD., Lunawada",
	},
	{
		SlNo: 10,
		Nameoftheteacher: "Dr. Rajesh Kumar Sain",
		Titleofthebook: "Financial Accounting",
		type: "National",
		Yearofpublication: 2022,
		ISBNnumber: "978-93-9168-11-1",
		Affiliating: "NIBA",
		Nameofthepublisher: "Bharati Publication New Delhi",
	},
	{
		SlNo: 11,
		Nameoftheteacher: "Dr.  N. Sugundan",
		Titleofthebook: "Leadership in Business and Change Management",
		type: "National",
		Yearofpublication: 2022,
		ISBNnumber: "978-93-95468-99-2",
		Affiliating: "NIBA",
		Nameofthepublisher: "AGPH Books (Academic Guru Publishing House",
	},
];

export const publicationData2 = [
	{
		titleOfPaper:
			"Factors Affecting Employee Job Satisfaction in Public Sector",
		name: "Prof. Gyana Ranjan Biswal",
		department: "MBA",
		nameofJournal: "Asia Pacific Journal of Research",
		year: "2017",
		issnNo: "2230-5504",
		linkToWebsite: "www.apior.com",
		linkToArtical: "S.N. 45797 of UGC",
		isListed: "Yes",
	},
	{
		titleOfPaper:
			"Implementation of Linear Discriminant Analysis for Odia Numeral Recognition",
		name: "Prof. Sradhanjali Nayak",
		department: "MCA",
		nameofJournal: "International Conference on Information Technology (ICIT)",
		year: "2018",
		issnNo: "978-1-728-0259-7/18",
		linkToWebsite: "ieeexplore.ieee.org",
		linkToArtical: "DOI: 10.1109/icit.2018.00043",
		isListed: "Yes",
	},
	{
		titleOfPaper:
			"International Conference on Image Information Processing ( ICIIP) in IEEE",
		name: "Prof. Sradhanjali Nayak",
		department: "MCA",
		nameofJournal:
			"Classification Performance Analysis of Printed Odia Alphabets using NeturalNetwork based Approach",
		year: "2019",
		linkToWebsite: "https://ieeexplore.ieee.org",
		linkToArtical: "DOI: 10.1109/ICIIP47207.2019.8985822",
		isListed: "Yes",
	},
	{
		titleOfPaper:
			"Recognition of Printed Odia Charactrs and Digits using Optimized Self-Organizing Map Network",
		name: "Prof. Sradhanjali Nayak",
		department: "MCA",
		nameofJournal: "IEEE Xplore",
		year: "2020",
		linkToWebsite: "ieeexplore.ieee.org",
		linkToArtical: "DOI:10.1109/ICCSEA49",
		isListed: "Yes",
	},
	{
		titleOfPaper:
			"International Conference on Image Information Processing ( ICIIP) in IEEE",
		name: "Prof. Sradhanjali Nayak",
		department: "MCA",
		nameofJournal:
			"Shape Feature Based Multi-Class Classification Approach towards Odia Characters employing Extreme Learning Machine",
		year: "2021",
		issnNo: "978-1-6654-3361-7/21",
		linkToWebsite: "www.researchgate.net",
		linkToArtical: "DOI: 10.1109/ICIIP53038.2021.9702563",
		isListed: "Yes",
	},
	{
		titleOfPaper:
			"Impact of Covid-19 on Broader  indices of Indian Stock Market",
		name: "Dr. Ratidev Samal",
		department: "MBA",
		nameofJournal: "International Journal of Monetary Economics & Finance",
		year: "2022",
		issnNo: "1752-0487",
		linkToWebsite: "Mandatory Economics and Finance",
		linkToArtical: "DOI:10.1504/ijmef.2022.10046830",
		isListed: "Yes",
	},
];
