import React from "react";
import PageSection from "../PageSection/PageSection";
import TrusteeMessageCard from "../TrusteeMessageCard/TrusteeMessageCard";
import { chairmanData, jmTrusteeData } from "../../static-data/chairman.data";
const ChairmanResponsive = () => {
	return (
		<PageSection bgClass="white">
			<TrusteeMessageCard
				type="chairman"
				titlePrefix="From Our"
				{...chairmanData}
			/>
			<TrusteeMessageCard
				type="trustee"
				titlePrefix="From Our"
				{...jmTrusteeData}
			/>
		</PageSection>
	);
};

export default ChairmanResponsive;
