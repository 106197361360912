import React from "react";
import { Image } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { publicationData } from "../../static-data/publication.data";
import { publicationData2 } from "../../static-data/publication.data";
import PageSection from "../PageSection/PageSection";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";
const PublicationResponsive = () => {
	return (
		<>
			<PageSection bgClass="bg-10">
				<ResponsivePageHeader centered={true} title="Book Publications" />

				<Table striped bordered hover>
					<thead>
						<tr>
							<th>Sl. No.</th>
							<th>Name of the teacher</th>
							<th>Title of the book/chapters published</th>
							<th>National/ International</th>
							<th>Year of publication</th>
							<th>ISBN number of the proceeding</th>
							<th>Affiliating Institute at the time of publication </th>
							<th>Name of the publisher</th>
						</tr>
					</thead>
					<tbody>
						{publicationData.map((val) => {
							return (
								<tr>
									<td>{val.SlNo}</td>
									<td>{val.Nameoftheteacher}</td>
									<td>{val.Titleofthebook}</td>
									<td>{val.type}</td>
									<td>{val.Yearofpublication}</td>
									<td>{val.ISBNnumber}</td>
									<td>{val.Affiliating}</td>
									<td>{val.Nameofthepublisher}</td>
								</tr>
							);
						})}
					</tbody>
				</Table>
			</PageSection>
			<PageSection bgClass="white">
				<ResponsivePageHeader centered={true} title="Journals Publications" />

				<Table striped bordered hover>
					<thead>
						<tr>
							<th>Title of paper</th>
							<th>Name and Department of the author/s</th>
							{/* <th> of the teacher</th> */}
							<th>Name of journal</th>
							<th>ISSN number(Year of publication )</th>
							<th>
								Link to website of the Journal(Is it listed in UGC Care list)
							</th>
							<th>Link to article / paper / abstract of the article</th>
						</tr>
					</thead>
					<tbody>
						{publicationData2.map((val) => {
							return (
								// {
								// 	titleOfPaper:
								// 		"Factors Affecting Employee Job Satisfaction in Public Sector",
								// 	name: "Prof. Gyana Ranjan Biswal",
								// 	department: "MBA",
								// 	nameofJournal: "Asia Pacific Journal of Research",
								// 	year: "2017",
								// 	issnNo: "2230-5504",
								// 	linkToWebsite: "www.apior.com",
								// 	linkToArtical: "S.N. 45797 of UGC",
								// 	isListed: "Yes",
								// },
								<tr>
									<td>{val.titleOfPaper}</td>
									<td>
										{val.name} ({val.department})
									</td>
									{/* <td>{val.department}</td> */}
									<td>{val.nameofJournal}</td>
									<td>
										{val.issnNo} (Year: {val.year})
									</td>
									<td>
										{" "}
										{val.linkToWebsite} (Listed : {val.isListed})
									</td>
									<td>{val.linkToArtical}</td>
								</tr>
							);
						})}
					</tbody>
				</Table>
			</PageSection>
		</>
	);
};

export default PublicationResponsive;
