import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import CampusCommunityServiceResponsive from "../../components/CampusCommunityServiceResponsive/CampusCommunityServiceResponsive";
import PageHeader from "../../components/PageHeader/PageHeader";
import PageSection from "../../components/PageSection/PageSection";
import PageSectionHeader from "../../components/PageSectionHeader/PageSectionHeader";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import HeroSection from "../../components/HeroSection/HeroSection";
import HeroCaption from "../../components/HeroCaption/HeroCaption";
import CampusCommunityServiceGallaryResponsive from "../../components/CampusCommunityServiceGallaryResponsive/CampusCommunityServiceGallaryResponsive";
const CampusCommunityServicePage = () => {
	return (
		<PageWrapper>
			<HeroSection className="bg-page-background-4">
				<HeroCaption
					title="CSR & Sustainability"
					position="bottom-left"
					breadcrumb={true}
					breadcrumbPrefix="Home"
				/>
			</HeroSection>
			<CampusCommunityServiceResponsive />
			<CampusCommunityServiceGallaryResponsive />
		</PageWrapper>
	);
};

export default CampusCommunityServicePage;
