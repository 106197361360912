import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";
import { Col, Row } from "react-bootstrap";
// import EnquiryFormResponsive from "../EnquiryFormResponsive/EnquiryFormResponsive";
import PageSection from "../PageSection/PageSection";
// import ContactUsForm from "../ContactUsFrom/ContactUsForm";
import { BsFillHouseFill } from "react-icons/bs";
import CustomIcon from "../../components/CustomIcon/CustomIcon";

import { BiWorld } from "react-icons/bi";
import { AiFillPhone, AiTwotoneMail } from "react-icons/ai";
import { aboutUsCardsData } from "../../static-data/aboutus.data";
import AboutUsMiniCard from "../AboutUsMiniCard/AboutUsMiniCard";
// import PageSectionHeader from "../../components/PageSectionHeader/PageSectionHeader";
const AboutNibaResponsive = () => {
	const [currentBreakPoint, setCurrentBreakPoint] = useState("");

	useEffect(() => {
		bsBreakpoints.init();
		setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

		const handleBreakPoint = (event) => {
			setCurrentBreakPoint(event.detail);
		};

		window.addEventListener("new.bs.breakpoint", handleBreakPoint);

		return () => {
			window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
		};
	}, []);

	const descriptionClasses = classNames("mb-4 text-center text-text-color", {
		"font-sm": currentBreakPoint === "xSmall" || currentBreakPoint === "small",
	});

	return (
		<PageSection bgClass="background-color">
			<ResponsivePageHeader centered={true} title="About Us" />
			<p className={descriptionClasses}>
				The organization was founded in the year 2000 under the guidance of the
				Nilachal Management Trust (NEMT). We are a group of visionaries striving
				to break new ground in the fields of IT services, management, and social
				studies. We have developed constituent colleges of several areas,
				including NIIS Institute of Engineering & Technology, NIIS Institute of
				Business Administration, NIIS Institute of Information Science and
				Management, and NIIS Higher Secondary School, with the goal of
				delivering a world-class learning curriculum to all of our students.
				NIIS Institute of Engineering & Technology, affiliated under the Biju
				Patnaik University of Technology, Odisha offers a wide range of
				engineering & diploma programs designed to equip our students with the
				knowledge and skills they need to succeed in their chosen careers.
			</p>

			<Row className="mb-5">
				{aboutUsCardsData.map((data, index) => (
					<Col
						md="3"
						className="d-flex align-items-stretch mb-4 mb-md-0"
						key={"about-us-home" + index}
					>
						<AboutUsMiniCard {...data} />
					</Col>
				))}
			</Row>

			<Row className="justify-content-between">
				<Col md="6" className="mb-5 mb-md-0 ">
					<div
						className="border mb-4 rounded px-5 py-4 bg-light"
						style={{ height: "85%" }}
					>
						<p className="display-9 font-weight-normal font-heading-font">
							Vision
						</p>
						<p style={{ textAlign: "justify" }}>
							To become a nationally reckoning institute by developing value
							based transformational leaders who can contribute to the economic
							growth and social development of our nation.
						</p>
					</div>
				</Col>
				<Col md="6" className="mb-5 mb-md-0 ">
					<div
						className="border mb-4 rounded px-5 py-4 bg-light"
						style={{ height: "85%" }}
					>
						<p className="display-9 font-weight-normal font-heading-font">
							Mission
						</p>
						<p style={{ textAlign: "justify" }}>
							Our mission is to foster knowledge, wisdom, skill and competence
							among new age young minds to face the adversity and challenges of
							complex business scenarios having high adversity quotient and
							positive attitude with social values and ethos.
						</p>
					</div>
				</Col>
			</Row>
		</PageSection>
	);
};

export default AboutNibaResponsive;
