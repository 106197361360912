export const electricalMaterials = [
	{
		id: 1,
		title: "Sem-1",
		link: "https://drive.google.com/drive/folders/1L6QL6zVf2AizQywNnf6yETs38_eQiKY2?usp=drive_link",
	},
	{
		id: 2,
		title: "Sem-2",
		link: "https://drive.google.com/drive/folders/1_ZRrcHvOnlX-GKMztPIqlYX_Rav9TDPE?usp=drive_link",
	},
	{
		id: 3,
		title: "Sem-3",
		link: "https://drive.google.com/drive/folders/1kiYIhtk0ZE05SpgNWS6POQpReSRVJYJZ?usp=drive_link",
	},
	{
		id: 4,
		title: "Sem-4",
		link: "https://drive.google.com/drive/folders/1ecz1B9GEQX94ZNN_avU7i9lXqMXcjawn?usp=drive_link",
	},
	{
		id: 5,
		title: "Sem-5",
		link: "https://drive.google.com/drive/folders/1r6UQ9-g4F5R0fKpTzvfKAKz7zbJ5jKLp?usp=drive_link",
	},
	{
		id: 6,
		title: "Sem-6",
		link: "https://drive.google.com/drive/folders/1FwFVu-S3B5CKXui8zAHKHUKleRqPF1_x?usp=drive_link",
	},
	
];

export const mechanicalMaterials = [
	{
		id: 1,
		title: "Sem-1",
		link: "https://drive.google.com/drive/folders/1L6QL6zVf2AizQywNnf6yETs38_eQiKY2?usp=drive_link",
	},
	{
		id: 2,
		title: "Sem-2",
		link: "https://drive.google.com/drive/folders/1_ZRrcHvOnlX-GKMztPIqlYX_Rav9TDPE?usp=drive_link",
	},
	{
		id: 3,
		title: "Sem-3",
		link: "https://drive.google.com/drive/folders/1tNFm3RvAS2ZOkvBP8DgWIzxVMS45OcHx?usp=drive_link",
	},

	{
		id: 4,
		title: "Sem-4",
		link: "https://drive.google.com/drive/folders/1oeuMQS3kFqqAoEYC2iaI0Gmz0mb4rD4n?usp=drive_link",
	},
	{
		id: 5,
		title: "Sem-5",
		link: "https://drive.google.com/drive/folders/14A67tJFUSQMlwLvLwIXKEmkecYQ8pHQA?usp=drive_link",
	},
	{
		id: 6,
		title: "Sem-6",
		link: "https://drive.google.com/drive/folders/1_xajC0QA_UWKBbzuGW30Ogz-KxuzrATS?usp=drive_link",
	},
	
];

export const civilMaterials = [
	{
		id: 1,
		title: "Sem-1",
		link: "https://drive.google.com/drive/folders/1L6QL6zVf2AizQywNnf6yETs38_eQiKY2?usp=drive_link",
	},
	{
		id: 2,
		title: "Sem-2",
		link: "https://drive.google.com/drive/folders/1_ZRrcHvOnlX-GKMztPIqlYX_Rav9TDPE?usp=drive_link",
	},
	{
		id: 3,
		title: "Sem-3",
		link: "https://drive.google.com/drive/folders/1WrKXQKZT88_CIqFdSwgxnCa4SKNkcXTE?usp=drive_link",
	},
	{
		id: 4,
		title: "Sem-4",
		link: "https://drive.google.com/drive/folders/1bEItGeSu0VCa-8KYx9a1T_zvPDmdGCXn?usp=drive_link",
	},
	{
		id: 5,
		title: "Sem-5",
		link: "https://drive.google.com/drive/folders/11qqg47Q80WF6N-kmyWlCp2FY2VQn6a3R?usp=drive_link",
	},
	{
		id: 6,
		title: "Sem-6",
		link: "https://drive.google.com/drive/folders/1UoRae5XGEU5mRu77PHuYABxRDP_OuT9i?usp=drive_link",
	},
	
];

