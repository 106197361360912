import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import { newsData } from "../../static-data/news.data";
import NewsCardResponsive from "../NewsCardResponsive/NewsCardResponsive";
import PageSection from "../PageSection/PageSection";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";

const NewsResponsive = () => {
	const [start, setStart] = useState(0);
	const [end, setEnd] = useState(4);
	useEffect(() => {
		// viewMore();
	});

	const viewMore = () => {
		if (end < newsData.length) {
			setStart(end);
			setEnd(end + 4);
		}
	};
	const viewLess = () => {
		if (start > 0) {
			setEnd(start);
			setStart(start - 4);
		}
	};

	return (
		<PageSection bgClass="white">
			<div className="d-flex align-items-baseline justify-content-between">
				<ResponsivePageHeader title="Latest News" />
				<u>
					<Link
						onClick={viewLess}
						className="text-link-color text-underline font-paragraph-font font-md"
					>
						<img
							src="https://cdn-icons-png.flaticon.com/512/545/545682.png"
							alt=""
							style={{
								height: "36px",
								transform: "rotate(180deg)",
								marginRight: "20px",
							}}
						/>
					</Link>
					<Link
						onClick={viewMore}
						className="text-link-color text-underline font-paragraph-font font-md"
					>
						<img
							src="https://cdn-icons-png.flaticon.com/512/545/545682.png"
							alt=""
							style={{ height: "36px" }}
						/>
					</Link>
				</u>
			</div>
			<Row>
				{newsData
					.slice(start, end)

					.map((data, index) => (
						<Col
							md="6"
							key={`news-home${index}`}
							className="d-flex align-items-stretch"
						>
							<NewsCardResponsive {...data} />
						</Col>
					))}
			</Row>
		</PageSection>
	);
};

export default NewsResponsive;
