import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";
import { Row, Col, Image } from "react-bootstrap";
import PageHeader from "../PageHeader/PageHeader";
import PageSectionHeader from "../PageSectionHeader/PageSectionHeader";

import PageSection from "../PageSection/PageSection";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";

const CommerceConclaveResponsive = () => {
	const [currentBreakPoint, setCurrentBreakPoint] = useState("");

	useEffect(() => {
		bsBreakpoints.init();
		setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

		const handleBreakPoint = (event) => {
			setCurrentBreakPoint(event.detail);
		};

		window.addEventListener("new.bs.breakpoint", handleBreakPoint);

		return () => {
			window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
		};
	}, []);

	const descriptionClasses = classNames("mb-4 text-center text-text-color", {
		"font-sm": currentBreakPoint === "xSmall" || currentBreakPoint === "small",
	});

	return (
		<PageSection bgClass="background-color">
			<ResponsivePageHeader centered={true} title="Commerce Conclave" />
			<p className={descriptionClasses}>
				The Commerce Conclave held at NIIS Institute of Engineering & Technology
				is an annual event that brings together leading academicians and
				industry professionals to engage in meaningful discussions and debates
				on the latest trends and developments in the field of economics and
				business. This event has been successfully conducted for the past five
				years and has become a platform for thought leaders and experts in the
				industry to share their insights and best practices with students and
				faculty. The focus of the Commerce Conclave is to bridge the gap between
				industry and academia, and to ensure that students have a clear
				understanding of the current business landscape and the skills that are
				in demand. The Commerce Conclave is an excellent opportunity for
				students to hear from successful professionals and to learn about the
				challenges and opportunities facing businesses today. It provides an
				enriching experience for students to engage with industry professionals
				and to network with like-minded individuals. The event is also an
				opportunity for industry professionals to learn about the latest
				research and trends in the field of economics and business, and to
				connect with the next generation of business leaders. At the Commerce
				Conclave, attendees can expect to hear from inspiring speakers,
				participate in interactive sessions, and network with industry
				professionals. The event is a great opportunity for students and
				professionals to learn, grow and make meaningful connections that can
				impact their future careers. The Commerce Conclave at NIIS Institute of
				Business Administration is a unique and valuable event that brings
				together the best minds in the field of economics and business to share
				their expertise and help shape the future of the industry.
			</p>
		</PageSection>
	);
};

export default CommerceConclaveResponsive;
