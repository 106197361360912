import React from "react";
import { Row, Col, Image } from 'react-bootstrap';
import PageHeader from "../../components/PageHeader/PageHeader";
import PageSection from "../../components/PageSection/PageSection";
import PageSectionHeader from "../../components/PageSectionHeader/PageSectionHeader";
import PageWrapper from "../../components/PageWrapper/PageWrapper";

const OnlineExamPage = () => {
  return (
    <PageWrapper>
      <PageHeader title="Online Test Platform" />
      <PageSection>
        <PageSectionHeader title="Gallery" rightStyle="bottom" />
        <Row className="justify-content-center">
          <Col md="6" className="mb-4">
            <Image
              src={
                require("../../assets/online-exam-platform/online-exam-platform-1.jpg")
                  .default
              }
              className="img-fluid w-100 rounded"
            />
          </Col>
          <Col md="6" className="mb-4">
            <Image
              src={
                require("../../assets/online-exam-platform/online-exam-platform-2.jpg")
                  .default
              }
              className="img-fluid w-100 rounded"
            />
          </Col>
          <Col md="6" className="mb-4">
            <Image
              src={
                require("../../assets/online-exam-platform/online-exam-platform-3.jpg")
                  .default
              }
              className="img-fluid w-100 rounded"
            />
          </Col>
          <Col md="6" className="mb-4">
            <Image
              src={
                require("../../assets/online-exam-platform/online-exam-platform-4.jpg")
                  .default
              }
              className="img-fluid w-100 rounded"
            />
          </Col>

        </Row>
      </PageSection>
    </PageWrapper>
  );
};

export default OnlineExamPage;
