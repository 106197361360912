export const newsData = [
	{
		date: "16th December  2022",
		link: "/",
		title: "Industrial Visit to CTTC for MCA 1st Year Students ",
		description:
			"NIBA organised an industrial visit to CTTC for MCA 1st year students on 16th of December. The students witnessed the cutting edge technologies for manufacturing and the new tools that are going to help in Industry 4.0 or the 4th Industrial Revolution. ",
		imageUrl: require("../assets/news/15.jpeg"),
	},
	{
		date: "14th December  2022",
		link: "/",
		title: "IQAC, NIBA celebrated National Energy Conservation Day ",
		description:
			"IQAC Cell celebrated National Energy Conservation Day at NIBA on 14th of December for MBA & MCA students. Energy is going to play a vital role in the future development of the nation and it is important that our future managers & technocrats understand the situation and plan accordingly. ",
		imageUrl: require("../assets/news/14.jpeg"),
	},
	// {
	// 	date: "14th December  2022",
	// 	link: "/",
	// 	title: "+3 Arts 3rd semester GD class on the topic - Cyber crime",
	// 	description:
	// 		"should it enrooted in individual level/should we use group efforts to eradicate this stigma, conducted by Dipanjali Madam, Psychology Department.",
	// 	imageUrl: require("../assets/news/13.jpeg"),
	// },
	{
		date: "13th December  2022",
		link: "/",
		title: "+3 Science freshers Welcome",
		description: "",
		imageUrl: require("../assets/news/12.jpeg"),
	},
	// {
	// 	date: "13th December  2022",
	// 	link: "/",
	// 	title: "Observation visit to state tribal museum (msw 1st year students )",
	// 	description: "",
	// 	imageUrl: require("../assets/news/11.jpeg"),
	// },
	// {
	// 	date: "13th December  2022",
	// 	link: "/",
	// 	title: "Industrial visit of B.com 3rd yr students @Parle G",
	// 	description: "",
	// 	imageUrl: require("../assets/news/10.jpeg"),
	// },
	{
		date: "10th & 11th December 2022 ",
		link: "/",
		title:
			"NIBA, in association with Ascent Conclave 2022 organised a 2-day National MUN. ",
		description:
			"Diplomacy is vital to the interests and development of every nation. Our institute organised a 2-day MUN to develop confidence & leadership skills within our students. ",
		imageUrl: require("../assets/news/9.jpeg"),
	},
	// {
	// 	date: "10th December  2022",
	// 	link: "/",
	// 	title:
	// 		"Msw & B.A. Sociology hons students at weavers community workshop at kala bhoomi Bhubaneswar",
	// 	description: "",
	// 	imageUrl: require("../assets/news/8.jpeg"),
	// },
	{
		date: "6th December  2022",
		link: "/",
		title:
			"Poster Making Competition organised by NIIS Group of Institutions in collaboration with Switchon Foundation.",
		description: "",
		imageUrl: require("../assets/news/7.jpeg"),
	},
	{
		date: "6th December  2022",
		link: "/",
		title:
			"Industrial visit to PARLE INDUSTRY by MFC and MCOM first year students..",
		description: "",
		imageUrl: require("../assets/news/6.jpeg"),
	},
	{
		date: "6th December  2022",
		link: "/",
		title:
			"Industrial visit to PARLE INDUSTRY by MFC and MCOM first year students..",
		description: "",
		imageUrl: require("../assets/news/5.jpeg"),
	},
	{
		date: "12th June 2022",
		link: "/",
		title:
			"IQAC, NIBA conducted a Mentor Development Program in association withBharatiya Yuva Shakti Trust.",
		description:
			"Our aim with the Mentor Development Program was to encourage people to connect with others on a personal level and build strong, trusting relations.",
		imageUrl: require("../assets/news/4.jpeg"),
	},

	{
		date: "7th June 2022",
		link: "/",
		title:
			"IQAC NIBA initiated a 5day long Faculty Development Program for our Computer Science Department",
		description:
			" We had an eminent panel of resource persons, Dr Prafulla Kumar Behera of Utkal University, Bhubaneswar; Dr. Alok Ranjan Tripathy, Ravenshaw University, Cuttack; Dr Manash Ranjan Kabat of VSSUT Burla; Dr. D.C. Rao of VSSUT Burla and Dr. Pradyut Biswal, IIIT Bhubaneswar.",
		imageUrl: require("../assets/news/3.jpeg"),
	},
	{
		date: "14th May 2022",
		link: "/",
		title: "National Seminar: “Industry 4.0: The Evolving Business Landscape.”",
		description:
			" We were privileged to host the Vice-Chancellor of Fakir Mohan University, Balasore,  Prof.(Dr) Santosh Kumar Tripathy; our keynote speakers Dr. Ramakrushna Padhy, Associate Professor, IIM Sambalpur; Dr W.S. William, Professor XIMB Bhubaneswar; Dr Suman Bhattacharya, Director CAAS & Head Next Gen Learning, KIIT University; Dr Debabrata Dash, VP HR Ops. MSPL.",
		imageUrl: require("../assets/news/2.jpg"),
	},
	{
		date: "16th April 2022",
		link: "/",
		title:
			"National Seminar: “Revisiting the landscape of IoT: A technology for common man”",
		description:
			"TWe were privileged to host the Vice-Chancellor of BPUT,  Prof.(Dr) Chitaranjan Tripathy, Prof.(Dr) Amiya Kumar Rath, Advisor, NAAC Bangalore and our keynote speaker Dr. Suraj Sharma, IIIT Bhubaneswar.",
		imageUrl: require("../assets/news/1.jpg"),
	},
];
