import React from "react";
import PageWrapper from "../../../components/PageWrapper/PageWrapper";
import HeroSection from "../../../components/HeroSection/HeroSection";
import HeroCaption from "../../../components/HeroCaption/HeroCaption";

import FacultyResponsive from "../../../components/FacultyResponsive/FacultyResponsive";
import CoursesResponsive from "../../../components/CoursesResponsive/CoursesResponsive";
import DepartmentInfoResponsive from "../../../components/DepartmentInfoResponsive/DepartmentInfoResponsive";
import DepartmentAboutInfoResponsive from "../../../components/DepartmentAboutInfoResponsive/DepartmentAboutInfoResponsive";
import HODResponsive from "../../../components/HODResponsive/HODResponsive";

const hodData = {
	imageUrl: "",
	designation: "Head of the Department",
	name: "Prof. Name",
	feedback: `Dear Students,
	As the Head of the Electronics and Telecommunication Engineering (ETC) Department at NIET, I would like to extend my warmest welcome to you. Our department is committed to providing you with a comprehensive education in the field of electronics and telecommunication engineering.
	Our faculty members are dedicated to delivering a challenging and rigorous curriculum that prepares you for a successful career in the industry. We offer a range of programs and courses that are designed to equip you with the skills and knowledge you need to excel in your chosen field.
	We also provide access to state-of-the-art facilities, including electronics and communication labs, simulation tools, and equipment, to ensure that you receive a hands-on learning experience. Our faculty members work closely with you to provide practical training and real-world experience, preparing you for the challenges of the industry.
	At NIET's ETC Department, we strive to provide you with an education that is not only rigorous and challenging but also enjoyable and rewarding. We encourage you to take advantage of the resources and opportunities available to you and to explore your interests and passions.
	I look forward to working with all of you and wish you the best of luck in your academic pursuits.
	
    `,
};

const ElectricalTelecommunicationPage = () => {
	return (
		<PageWrapper className="mt-n6">
			<HeroSection className="bg-page-background-7">
				<HeroCaption
					title="Electrical & Telecommunication"
					position="bottom-left"
					breadcrumb={true}
					breadcrumbPrefix="Home"
				/>
			</HeroSection>
			<DepartmentInfoResponsive
				title={"Electrical & Telecommunication"}
				desc={`The Electronics and Telecommunication Engineering (ETC) Department at NIET offers a comprehensive education in the field of electronics and telecommunication engineering. Our department is dedicated to preparing students for successful careers in the industry by providing them with the skills and knowledge they need to excel in their chosen field.

`}
			/>
			<HODResponsive hodData={hodData} />
			<DepartmentAboutInfoResponsive
				title={"About department"}
				desc={`The Electronics and Telecommunication Engineering (ETC) Department at NIET offers a comprehensive education in the field of electronics and telecommunication engineering. Our department is dedicated to preparing students for successful careers in the industry by providing them with the skills and knowledge they need to excel in their chosen field.
				Our faculty members are experts in their respective fields and have extensive experience in both academia and industry. They are committed to delivering a challenging and rigorous curriculum that covers a wide range of topics, including digital communication, analog communication, control systems, microprocessors, and microcontrollers.
				Our ETC Department offers a B.Tech in Electronics and Telecommunication Engineering program that covers core and elective courses, allowing students to specialize in their area of interest. Our department is equipped with state-of-the-art facilities, including electronics and communication labs, simulation tools, and equipment, to support students' practical learning experience.
				We encourage students to participate in co-curricular activities such as technical paper presentations, project exhibitions, and industry collaborations, which provide them with opportunities to showcase their skills and talents and network with industry professionals.
				Our goal at NIET's ETC Department is to produce graduates who are well-prepared to contribute to the industry and society as a whole. We are committed to providing our students with the best possible education in electronics and telecommunication engineering and equipping them with the skills they need to succeed in their chosen career paths.
				
                
`}
			/>
		</PageWrapper>
	);
};

export default ElectricalTelecommunicationPage;
