import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import PageHeader from "../../components/PageHeader/PageHeader";
import PageSection from "../../components/PageSection/PageSection";
import PageSectionHeader from "../../components/PageSectionHeader/PageSectionHeader";

const LibraryPage = () => {
  return (
    <PageWrapper>
      <PageHeader title="Library" />
      <PageSection>
        <p>
          The NIIS Library with its modern collection of knowledge resources and
          innovative information services fills an essential role for students,
          faculty, and the surrounding community in their intellectual pursuits.
          It is a hybrid library with the state-of-the-art technological
          applications. The Library holds knowledge resources predominantly
          related to management, Computer Science and allied subjects.
          Particularly institute online journals are made available through
          Institute's network. The faculty community & students can access the
          online books, magazines, periodicals etc. from their own computer
          terminals using DELNET.
        </p>

        <p>
          The Library offers a range of information services to support the
          learning process set to the highest professional standards.
        </p>
      </PageSection>
      <PageSection bgClass="light">
        <PageSectionHeader
          title="The primary objectives are"
          leftStyle="bottom"
        />
        <div className="ml-2">
          <ul>
            <li>
              <p>
                To provide appropriate knowledge resources to meet the
                information needs of the academic community of the institute.
              </p>
            </li>
            <li>
              <p>
                To build a state-of-the-art knowledge resources center for all.
              </p>
            </li>
          </ul>
        </div>
      </PageSection>
      <PageSection>
        <PageSectionHeader title="Gallery" rightStyle="bottom" />
        <Row className="justify-content-center">
          <Col md="6" className="mb-4">
            <Image
              src={
                require("../../assets/library/library-1.jpg").default
              }
              className="img-fluid rounded"
            />
          </Col>
          <Col md="6" className="mb-4">
            <Image
              src={
                require("../../assets/library/library-2.jpg").default
              }
              className="img-fluid rounded"
            />
          </Col>
        </Row>
      </PageSection>
    </PageWrapper>
  );
};

export default LibraryPage;
