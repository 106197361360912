import React from "react";
import PageHeader from "../../components/PageHeader/PageHeader";
import PageSection from "../../components/PageSection/PageSection";
import PageWrapper from "../../components/PageWrapper/PageWrapper";

const OtherFacilityPage = () => {
  return (
    <PageWrapper>
        <PageHeader title="Other Facilities"/>
      <PageSection>
        <div className="ml-2">
          <ul>
            <li>
              <p>Entrepreneurship Cell</p>
            </li>
            <li>
              <p>Community Cell</p>
            </li>
            <li>
              <p>IT Cell</p>
            </li>
            <li>
              <p>Club Advantages</p>
            </li>
            <li>
              <p>Language Lab</p>
            </li>
            <li>
              <p>Pre-placement Training</p>
            </li>
            <li>
              <p>Value enhancement Skill building programmes</p>
            </li>
            <li>
              <p>Indoor games</p>
            </li>
            <li>
              <p>Free Medical Check-up</p>
            </li>
            <li>
              <p>Hostel facilities inside Campus</p>
            </li>
            <li>
              <p>Employability building Programme</p>
            </li>
            <li>
              <p>Project facilitation at institute lab by experts</p>
            </li>
            <li>
              <p>Workshop in association with IIT's & IIM's.</p>
            </li>
            <li>
              <p>Social work/ activities Cell</p>
            </li>
            <li>
              <p>Study Tour</p>
            </li>
            <li>
              <p>Industrial Visit</p>
            </li>
            <li>
              <p>Yoga</p>
            </li>
          </ul>
        </div>
      </PageSection>
    </PageWrapper>
  );
};

export default OtherFacilityPage;
