import React from "react";
// import { AiFillFilePdf } from "react-icons/ai";
// import Blockquote from "../../components/Blockquote/Blockquote";
import PageHeader from "../../components/PageHeader/PageHeader";
import PageSection from "../../components/PageSection/PageSection";
// import PageSectionHeader from "../../components/PageSectionHeader/PageSectionHeader";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
// import CustomIcon from "../../components/CustomIcon/CustomIcon";

const AdmissionProcedurePage = () => {
  return (
    <PageWrapper>
      <PageHeader title="Admission Procedure" />
      <PageSection>
        <p>
          To be qualified for induction into this 2year full time MBA course the
          applicant ought to be a graduate from any discipline, along with 50%
          aggregate (including Engineering) given he/she qualifies any Public
          Entrance test directed by any association at public level or by the
          State Govt or affiliating College like OJEE, CAT, MAT, XAT, ATMA, CMAT
          and so forth according to the standards of government.
        </p>
        <p>
          To be qualified for induction into this 2 year full time MCA course,
          the applicant ought to be a graduate of science background(in +2 and
          graduation ) with Mathematics as a subject along with a 50% aggregate
          given he/she qualifies OJEE.
        </p>
      </PageSection>
      {/* <PageSection>
        <PageSectionHeader
          title="Master in Business Administration (MBA)"
          rightStyle="bottom"
        />
        <p>
          To be qualified for induction into this 2year all day MBA course an
          understudy ought to be a graduate of 10+2+3 from any discipline
          including Engineering given he/she qualifies any Public Entrance test
          directed by any association at public level or by the State Govt or
          affiliating College like OJEE, CAT, MAT, XAT, ATMA, CMAT and so forth
          according to the standards of government.
        </p>
        <Blockquote>
          <p>
            <span className="font-weight-bold">Eligibility : </span> (10+2+3)
            Arts / Science / Commerce or equivalent.
          </p>
        </Blockquote>
      </PageSection>
      <PageSection bgClass="light">
        <PageSectionHeader
          title="Master in Computer Application (3 year MCA)"
          leftStyle="bottom"
        />
        <p>
          To be eligible for admission to this 3 year fulltime MCA course a
          student should be a Graduate of 10+2+3 pattern from any discipline
          having mathematics in +2 provided he/she qualifies OJEE conducted by
          Govt. of Odisha.
        </p>
        <Blockquote>
          <p>
            <span className="font-weight-bold">Eligibility : </span> Any
            Graduates with Math in +2 level
          </p>
        </Blockquote>

        <PageSection>
          <div className="text-center">
            <a href="/#" className="download-link">
              <CustomIcon currentIcon={AiFillFilePdf} />
              <span className="ml-2">DOWNLOAD APPLICATION FORM</span>
            </a>
          </div>
        </PageSection>
      </PageSection> */}
    </PageWrapper>
  );
};

export default AdmissionProcedurePage;
