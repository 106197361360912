import React from "react";
import NiisHero from "../../components/NiisHero/NiisHero";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import AboutUsResponsive from "../../components/AboutUsResponsive/AboutUsResponsive";
import CoursesResponsive from "../../components/CoursesResponsive/CoursesResponsive";
import InfrastructureAndFacilitesResponsive from "../../components/InfrastructureAndFacilitesResponsive/InfrastructureAndFacilitesResponsive";
import InfographyResponsive from "../../components/InfographyResponsive/InfographyResponsive";
import QuickLinksResponsive from "../../components/QuickLinksResponsive/QuickLinksResponsive";
import ResponsiveContactForm from "../../components/ContactResponsive/ContactResponsive";
import NewsResponsive from "../../components/NewsResponsive/NewsResponsive";
import SocialBlogResponsive from "../../components/SocialBlogResponsive/SocialBlogResponsive";
import UpcommingEventsResponsive from "../../components/UpcommingEventsResponsive/UpcommingEventsResponsive";
import FromStudentsResponsive from "../../components/FromStudentsResponsive/FromStudentsResponsive";
import TrusteeResponsive from "../../components/TrusteeResponsive/TrusteeResponsive";

const HomePage = () => {
	return (
		<PageWrapper className="mt-n6">
			<NiisHero />
			<AboutUsResponsive />
			<CoursesResponsive />
			<TrusteeResponsive />
			{/* <FromStudentsResponsive /> */}
			<InfrastructureAndFacilitesResponsive />
			<InfographyResponsive />
			<QuickLinksResponsive />
			<UpcommingEventsResponsive />
			<SocialBlogResponsive />
			<ResponsiveContactForm />
			<NewsResponsive />
		</PageWrapper>
	);
};

export default HomePage;
