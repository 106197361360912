import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import PageHeader from "../../components/PageHeader/PageHeader";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import PageSection from "../../components/PageSection/PageSection";
import PageSectionHeader from "../../components/PageSectionHeader/PageSectionHeader";

const SeminarHallPage = () => {
  return (
    <PageWrapper>
      <PageHeader title="Seminar Hall" />
      <PageSection>
        <p>
          Periodic Seminars & Guest Lectures are conducted by inviting
          academicians, eminent persons & practicing professionals where
          students get an opportunity to interact closely with them and
          understand the practical application of various concepts and ideas as
          well as get first hand information on latest developments taking place
          in respective field for development of right kind of attitude for
          success in their careers and so also well built & well managed seminar
          halls in each floor cater to the need of holding seminars,
          presentation and workshops inside NIIS campus
        </p>
      </PageSection>
      <PageSection bgClass="light">
        <PageSectionHeader title="Gallery" leftStyle="bottom" />
        <Row className="justify-content-center">
          <Col md="6" className="mb-4">
            <Image
              src={
                require("../../assets/seminar-hall/seminar-hall-1.jpg").default
              }
              className="img-fluid rounded"
            />
          </Col>
        </Row>
      </PageSection>
    </PageWrapper>
  );
};

export default SeminarHallPage;
