import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";
import { Row, Col, Image } from "react-bootstrap";
import PageHeader from "../PageHeader/PageHeader";
import PageSectionHeader from "../PageSectionHeader/PageSectionHeader";

import PageSection from "../PageSection/PageSection";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";

const DreamFestResponsive = () => {
	const [currentBreakPoint, setCurrentBreakPoint] = useState("");

	useEffect(() => {
		bsBreakpoints.init();
		setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

		const handleBreakPoint = (event) => {
			setCurrentBreakPoint(event.detail);
		};

		window.addEventListener("new.bs.breakpoint", handleBreakPoint);

		return () => {
			window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
		};
	}, []);

	const descriptionClasses = classNames("mb-4 text-center text-text-color", {
		"font-sm": currentBreakPoint === "xSmall" || currentBreakPoint === "small",
	});

	return (
		<PageSection bgClass="background-color">
			<ResponsivePageHeader centered={true} title="Dream Fest" />
			<p className={descriptionClasses}>
				At NIIS, Dreamfest is a yearly celebration of societies and their
				activities. The event, which was planned by PG management students, is
				the ideal fusion of frolic and pleasure, thrill and ecstasy, passion and
				dedication. DreamFest is a wonderful venue for the magnificent showcase
				of the many talents of today's youngsters, and it always has a social
				undercurrent.
			</p>
			<p className={descriptionClasses}>
				To promote, practice, and preach their respective cultures, the various
				societies also hold events throughout the year. The society's activities
				also aim to inspire students to try new things and advance their
				knowledge in their chosen fields.
			</p>
		</PageSection>
	);
};

export default DreamFestResponsive;
