import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";
import { Swiper, SwiperSlide } from "swiper/react";
import { GoChevronRight, GoChevronLeft } from "react-icons/go";
import CustomIcon from "../CustomIcon/CustomIcon";
import PageSection from "../PageSection/PageSection";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";
import { skillDevelopmentData } from "../../static-data/skilldevelopmen.data";
import SkillDevelopmentCard from "../SkillDevelopmentCard/SkillDevelopmentCard";
const SkillDevelopmentCenterResponsive = () => {
	const [currentBreakPoint, setCurrentBreakPoint] = useState("");

	const descriptionClasses = classNames("mb-4 text-center text-white", {
		"font-sm": currentBreakPoint === "xSmall" || currentBreakPoint === "small",
	});

	useEffect(() => {
		bsBreakpoints.init();
		setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

		const handleBreakPoint = (event) => {
			setCurrentBreakPoint(event.detail);
		};

		window.addEventListener("new.bs.breakpoint", handleBreakPoint);

		return () => {
			window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
		};
	}, []);

	const paginationClasses = classNames(
		"skill-development-pagination swiper-pagination-container  swiper-pagination-container--bottom swiper-pagination-container--horizontal swiper-pagination-container--horizontal---right"
	);

	const navigationClasses = classNames(
		"swiper-navigation-horizontal-container swiper-navigation-horizontal-container--center"
	);

	const navigationLeftClasses = classNames(
		"skill-development-nav-prev swiper-navigation-control swiper-navigation-control--center---left",
		{
			"swiper-navigation-control--center---left--large": !(
				currentBreakPoint === "xSmall" || currentBreakPoint === "small"
			),
		}
	);

	const navigationRightClasses = classNames(
		"skill-development-nav-next swiper-navigation-control swiper-navigation-control--center---right",
		{
			"swiper-navigation-control--center---right--large": !(
				currentBreakPoint === "xSmall" || currentBreakPoint === "small"
			),
		}
	);

	return (
		<PageSection bgClass="page-background-12">
			<ResponsivePageHeader
				colorClass="text-white"
				centered={true}
				title="Skill Development Center"
			/>
			<p className={descriptionClasses}>
				This approach of super-specialized training gives students with a full
				grasp as well as the industry's required skills and expertise,
				guaranteeing that they are prepared to begin their promising career. In
				turn, candidates gain industry-based analytical knowledge and soft
				skills related to specific domains of specialisation.
			</p>
			<div className="position-relative pt-4 mb-4">
				<Swiper
					grabCursor={true}
					direction="horizontal"
					scrollbar={{ el: "", draggable: true, hide: true }}
					slidesPerView={1}
					spaceBetween={20}
					loop={true}
					autoplay={{ delay: 3000 }}
					navigation={{
						prevEl: ".skill-development-nav-prev",
						nextEl: ".skill-development-nav-next",
					}}
					pagination={{
						el: ".skill-development-pagination",
						clickable: true,
						renderBullet: (index, className) =>
							`<span class="bordered ${className}"></span>`,
					}}
				>
					{skillDevelopmentData.map((data, index) => (
						<SwiperSlide key={`skill-development-home${index}`}>
							<SkillDevelopmentCard {...data} />
						</SwiperSlide>
					))}
				</Swiper>

				<div className={paginationClasses}></div>

				<div className={navigationClasses}>
					<span className={navigationLeftClasses}>
						<CustomIcon
							currentIcon={GoChevronLeft}
							colorClass="text-white"
							size="lg"
						/>
					</span>
					<span className={navigationRightClasses}>
						<CustomIcon
							currentIcon={GoChevronRight}
							colorClass="text-white"
							size="lg"
						/>
					</span>
				</div>
			</div>
		</PageSection>
	);
};

export default SkillDevelopmentCenterResponsive;
