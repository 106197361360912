export const upcommingEventsData = [
	{
		date: "08 March, 2023.",
		title:
			"An Intra College meet followed by musical chair to Celebrate Women’s Day.",
		venue: "",
		time: "",
	},
	{
		date: "15 March, 2023.",
		title: "Workshop on the occasion of Consumer Rights Day. (CPE)",
		venue: "",
		time: "",
	},
	{
		date: "21 April, 2023.",
		title: "Seminar on Panchayat Raj Divas",
		venue: "",
		time: "",
	},
	{
		date: "26 April, 2023.",
		title: "Workshop on Intellectual Property Rights with CTTC",
		venue: "",
		time: "",
	},
	{
		date: "08 May, 2023.",
		title: "Celebration of Mother’s Day.",
		venue: "",
		time: "",
	},
	{
		date: "05 June, 2023.",
		title:
			"Workshop on Environment Day in collaboration with Switch On Foundation.",
		venue: "",
		time: "",
	},
	{
		date: "14 June, 2023.",
		title:
			"Blood Donation Camp on the occasion of World Red Cross Day with Commissioner of Police",
		venue: "",
		time: "",
	},
	{
		date: "21 June, 2023.",
		title:
			"Celebration of International Yoga Day in collaboration with Nehru Yuva Kendra (NYSK)",
		venue: "",
		time: "",
	},
	// {
	// 	date: "30 June, 2022",
	// 	title:
	// 		"LALNDC Conversation: Designing Learning Analytics Courses, Programs, and High-Impact Practices",
	// 	venue: "Online via zoom",
	// 	time: "5:00pm – 6:00pm",
	// },
	// {
	// 	date: "30 June, 2022",
	// 	title:
	// 		"LALNDC Conversation: Designing Learning Analytics Courses, Programs, and High-Impact Practices",
	// 	venue: "Online via zoom",
	// 	time: "5:00pm – 6:00pm",
	// },
	// {
	// 	date: "30 June, 2022",
	// 	title:
	// 		"LALNDC Conversation: Designing Learning Analytics Courses, Programs, and High-Impact Practices",
	// 	venue: "Online via zoom",
	// 	time: "5:00pm – 6:00pm",
	// },
	// {
	// 	date: "30 June, 2022",
	// 	title:
	// 		"LALNDC Conversation: Designing Learning Analytics Courses, Programs, and High-Impact Practices",
	// 	venue: "Online via zoom",
	// 	time: "5:00pm – 6:00pm",
	// },
	// {
	// 	date: "30 June, 2022",
	// 	title:
	// 		"LALNDC Conversation: Designing Learning Analytics Courses, Programs, and High-Impact Practices",
	// 	venue: "Online via zoom",
	// 	time: "5:00pm – 6:00pm",
	// },
];
