import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";

import PageSection from "../PageSection/PageSection";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";

const SeminarResponsive = () => {
	const [currentBreakPoint, setCurrentBreakPoint] = useState("");

	useEffect(() => {
		bsBreakpoints.init();
		setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

		const handleBreakPoint = (event) => {
			setCurrentBreakPoint(event.detail);
		};

		window.addEventListener("new.bs.breakpoint", handleBreakPoint);

		return () => {
			window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
		};
	}, []);

	const descriptionClasses = classNames("mb-4 text-center text-text-color", {
		"font-sm": currentBreakPoint === "xSmall" || currentBreakPoint === "small",
	});

	return (
		<PageSection bgClass="background-color">
			<ResponsivePageHeader centered={true} title="Seminars" />
			<p className={descriptionClasses}>
				Seminars are always considered as windows to the current leads of
				exposure to trends, techniques and tactics. With our profound guest
				lectures and seminars, we aim to imbibe in students the current world’s
				techniques and practices to transform them into dynamic persons. <br />
				Periodic seminars and lectures are conducted on various topics of
				academic and employment interest by academicians, industrialists and
				practicing professionals to make the students aware of various concepts,
				ideas, practices and latest developments in IT and management sector,
				with the scope for interaction with the presentator for development and
				success in professional career.
			</p>
		</PageSection>
	);
};

export default SeminarResponsive;
