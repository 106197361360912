import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import { AiFillFilePdf } from "react-icons/ai";
import CustomIcon from "../CustomIcon/CustomIcon";
import PageSection from "../PageSection/PageSection";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";

const RecruiterResponsive = () => {
	return (
		<>
			<PageSection bgClass="white">
				<ResponsivePageHeader centered={true} title="Our Recruiters" />
				<Image
					src={require("../../assets/recruiter/recruiter2.png").default}
					className="img-fluid"
					alt="recruiter"
				/>
			</PageSection>
			<PageSection bgClass="background-color">
				<ResponsivePageHeader centered={true} title="Archive" />
				<Row>
					<Col>
						<a
							href={process.env.PUBLIC_URL + "/2020-21_new.pdf"}
							className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
							target="_blank"
							rel="noopener noreferrer"
						>
							<CustomIcon currentIcon={AiFillFilePdf} size="md" />
							<span className="ml-2">DOWNLOAD 2020-21 PLACEMENT BROCHURE</span>
						</a>
					</Col>
				</Row>
			</PageSection>
		</>
	);
};

export default RecruiterResponsive;
