import React from "react";
import { Row,Col,Image } from "react-bootstrap";
import PageHeader from "../../components/PageHeader/PageHeader";
import PageSection from "../../components/PageSection/PageSection";
import PageSectionHeader from "../../components/PageSectionHeader/PageSectionHeader";
import PageWrapper from "../../components/PageWrapper/PageWrapper";

const CanteenPage = () => {
  return (
    <PageWrapper>
      <PageHeader title="Canteen" />

      <PageSection>
        <p>
          We believe that a healthy mind lives in a healthy body. NIIS has an in
          house modern canteen functioning in the campus, which can conveniently
          accommodate a good number of students at any time. The canteen is
          provided with modern furniture and automatic cooking system and
          storage facilities, which provides hot lunch, dinner, snacks and
          beverages both to the students and staff at reasonable rates. The food
          is nutritious and wholesome to ensure a well-balanced diet. NIIS,
          cafeteria fills-up the gap which arises between lovely parents and
          their homely atmosphere.
        </p>

        <p>
          Health is Wealth. During stepwise education system, student needs to
          maintaining their health for smooth mind and developing concentration
          for study. They live in an atmosphere separated from their family.
          NIIS continuously tries to maintain a good culture for the students
          and staff inside the campus.
        </p>
      </PageSection>
      <PageSection bgClass="light">
        <PageSectionHeader title="Gallery" leftStyle="bottom" />
        <Row className="justify-content-center">
          <Col md="6" className="mb-4">
            <Image
              src={
                require("../../assets/canteen/canteen-1.jpg").default
              }
              className="img-fluid rounded"
            />
          </Col>
          <Col md="6" className="mb-4">
            <Image
              src={
                require("../../assets/canteen/canteen-2.jpg").default
              }
              className="img-fluid rounded"
            />
          </Col>
        </Row>
      </PageSection>
    </PageWrapper>
  );
};

export default CanteenPage;
