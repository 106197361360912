import React from "react";
import { AiFillFilePdf } from "react-icons/ai";
import PageWrapper from "../../../components/PageWrapper/PageWrapper";
import HeroSection from "../../../components/HeroSection/HeroSection";
import HeroCaption from "../../../components/HeroCaption/HeroCaption";
import CustomIcon from "../../../components/CustomIcon/CustomIcon"

import FacultyResponsive from "../../../components/FacultyResponsive/FacultyResponsive";
import CoursesResponsive from "../../../components/CoursesResponsive/CoursesResponsive";
import DepartmentInfoResponsive from "../../../components/DepartmentInfoResponsive/DepartmentInfoResponsive";
import DepartmentAboutInfoResponsive from "../../../components/DepartmentAboutInfoResponsive/DepartmentAboutInfoResponsive";
import HODResponsive from "../../../components/HODResponsive/HODResponsive";
import ResourcesResponsive from "../../../components/ResourcesResponsive/ResourcesResponsive";
import { electricalMaterials } from "../../../static-data/coursematerial.data";
import { electricalLessonPlan } from "../../../static-data/courselessonplan.data";

// import { electrical } from "../../static-data/coursemater.data";

const hodData = {
	imageUrl: require("../../../assets/hod-images/ee.png"),
	designation: "Head of the Department",
	name: "Mrutyunjay Senapati",
	feedback: `Dear Students,
	As the Head of the Electrical Engineering Department at NIET, it gives me immense pleasure to welcome you to our department. Our department is committed to providing you with a comprehensive education in electrical engineering that equips you with the skills and knowledge you need to succeed in the industry.
	Our faculty members are experts in their respective fields and are committed to providing you with a challenging and rigorous curriculum that prepares you for a successful career in the field of electrical engineering. We constantly update our course offerings to keep up with the latest industry trends and practices.
	Our department is equipped with state-of-the-art facilities, including electrical labs, simulation tools, and equipment, to support your practical learning experience. Our faculty members work closely with you to provide hands-on training and real-world experience, preparing you for the challenges of the industry.
	We also encourage you to participate in co-curricular activities such as technical paper presentations, project exhibitions, and industry collaborations, which provide you with opportunities to showcase your skills and talents and network with industry professionals.
	At NIET's Electrical Engineering Department, we are committed to providing you with a well-rounded education that prepares you for the challenges and opportunities of the industry. We are confident that our programs and courses will equip you with the skills and knowledge you need to succeed in your chosen career path.
	I look forward to working with all of you and wish you the best of luck in your academic pursuits.
	
    `,
};

const eeSyllabus = "https://drive.google.com/drive/folders/1qPIJ1VwWL8ttsjeuEml9QrA-4W3Hg-i3?usp=drive_link";

const ElectricalAndElectronicsPage = () => {
	return (
		<PageWrapper className="mt-n6">
			<HeroSection className="bg-page-background-7">
				<HeroCaption
					title="Electrical & Electronics Department"
					position="bottom-left"
					breadcrumb={true}
					breadcrumbPrefix="Home"
				/>
			</HeroSection>
			<DepartmentInfoResponsive
				title={"Electrical & Electronics"}
				desc={`The Electrical and Electronics Engineering (EEE) Department at NIET is dedicated to providing a comprehensive education in the field of electrical and electronics engineering. Our department offers a range of programs and courses designed to equip students with the skills and knowledge they need to succeed in the industry.

`}
			/>
			<HODResponsive hodData={hodData} />
			<DepartmentAboutInfoResponsive
				title={"About department"}
				desc={`The Electrical and Electronics Engineering (EEE) Department at NIET is dedicated to providing a comprehensive education in the field of electrical and electronics engineering. Our department offers a range of programs and courses designed to equip students with the skills and knowledge they need to succeed in the industry.
				Our faculty members are experts in their respective fields and have extensive experience in both academia and industry. They are committed to providing students with a challenging and rigorous curriculum that prepares them for successful careers in the field of electrical and electronics engineering.
				Our EEE Department offers a B.Tech in Electrical Engineering program that covers a wide range of topics including electrical circuits, power systems, electrical machines, control systems, instrumentation, and electrical and electronics design. We also offer elective courses in emerging areas such as renewable energy, power electronics, and artificial intelligence, allowing students to specialize in their area of interest.
				Our department is equipped with state-of-the-art facilities, including electrical and electronics labs, simulation tools, and equipment, to support students' practical learning experience. Our faculty members work closely with students to provide hands-on training and real-world experience, preparing them for successful careers in the industry
				We also encourage students to participate in co-curricular activities such as robotics competitions, technical paper presentations, and project exhibitions, providing them with opportunities to showcase their skills and talents, and network with industry professionals.
				
                
`}
			/>

			<ResourcesResponsive syllabusLink={eeSyllabus} courseMaterial={electricalMaterials} courseLessonPlan={electricalLessonPlan}/>

			
		</PageWrapper>
	);
};

export default ElectricalAndElectronicsPage;
