import React from "react";
import { Button, Col, Row } from "react-bootstrap";
// import EnquiryFormResponsive from "../EnquiryFormResponsive/EnquiryFormResponsive";
import PageSection from "../PageSection/PageSection";
import ContactUsForm from "../../components/ContactUsFrom/ContactUsForm";
// import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";

const ContactUsResponsive = () => {
	return (
		<PageSection
			className="bg-position--top-center"
			bgClass="background-color"
			id="enquiry"
		>
			<Row className="justify-content-between">
				<Col md="6" className="mb-5 mb-md-0 ">
					<h3 className="mb-5 font-weight-bold ">
						Interested In Taking Admission?
					</h3>
					<h5 className="mb-5 font-weight-semibold ">
						Thank you for taking an interest in NIIS Institute of Engineering &
						Technology.
					</h5>
					<p className="mb-5">
						If you’re interested to know more about us, we recommend you to get
						in touch with us or fill up the enquiry form to receive a call from
						us!
					</p>
					<Button variant="highlight" className="px-4">
						Read More
					</Button>
				</Col>
				<Col md="5" className="mb-md-0">
					<ContactUsForm />
				</Col>
			</Row>
		</PageSection>
	);
};

export default ContactUsResponsive;
