import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";
import { AiFillFilePdf } from "react-icons/ai";
import PageSection from "../PageSection/PageSection";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";
import CustomIcon from "../CustomIcon/CustomIcon";

const MBAResponsive = () => {
  const [currentBreakPoint, setCurrentBreakPoint] = useState("");

  const descriptionClasses = classNames("text-center mb-4 text-text-color", {
    "font-sm": currentBreakPoint === "xSmall" || currentBreakPoint === "small",
  });

  useEffect(() => {
    bsBreakpoints.init();
    setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

    const handleBreakPoint = (event) => {
      setCurrentBreakPoint(event.detail);
    };

    window.addEventListener("new.bs.breakpoint", handleBreakPoint);

    return () => {
      window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
    };
  }, []);

  return (
    <PageSection bgClass="background-color">
      <ResponsivePageHeader
        centered={true}
        title="Master in Business Administration (MBA)"
      />
      <p className={descriptionClasses}>
        A reputable MBA Degree can be a difference-maker for your future-driving
        you forward and helping you become a great business leader. Our
        curriculum is focused on equipping you with skills and credentials that
        give you respect and value in the business world.
      </p>
      <p className={descriptionClasses}>
        The MBA course will shape and guide you as a reflective, open- thinking,
        adaptive learner. This is facilitated through an enhanced understanding
        of the interplay of theory and practice in management.
      </p>
      <a
        href={process.env.PUBLIC_URL + "/NIIS_MBA_Brochure.pdf"}
        className="d-flex align-items-center justify-content-center text-uppercase text-center text-link-color font-weight-medium font-sm font-paragraph-font"
        target="_blank"
        rel="noopener noreferrer"
      >
        <CustomIcon currentIcon={AiFillFilePdf} size="md" />
        <span className="ml-2">Download MBA Brochure</span>
      </a>
    </PageSection>
  );
};

export default MBAResponsive;
