import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";
import { Swiper, SwiperSlide } from "swiper/react";
import PageSection from "../PageSection/PageSection";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";
import AdvisorMiniCard from "../AdvisorMiniCard/AdvisorMiniCard";
import { advisorData } from "../../static-data/advisor.data";

const AdvisorResponsive = () => {
	const [currentBreakPoint, setCurrentBreakPoint] = useState("");

	useEffect(() => {
		bsBreakpoints.init();
		setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

		const handleBreakPoint = (event) => {
			setCurrentBreakPoint(event.detail);
		};

		window.addEventListener("new.bs.breakpoint", handleBreakPoint);

		return () => {
			window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
		};
	}, []);

	const swiperContainerClasses = classNames("position-relative", {
		"mb-4": currentBreakPoint === "small" || currentBreakPoint === "xSmall",
	});

	const paginationClasses = classNames(
		"advisor-feedback-pagination swiper-pagination-container swiper-pagination-container--horizontal swiper-pagination-container--horizontal---right",
		{
			"swiper-pagination-container--top": !(
				currentBreakPoint === "small" || currentBreakPoint === "xSmall"
			),
		},
		{
			"swiper-pagination-container--bottom":
				currentBreakPoint === "small" || currentBreakPoint === "xSmall",
		}
	);

	return (
		<PageSection bgClass="page-background-6">
			<ResponsivePageHeader colorClass="text-white" title="Academic Council " />

			<div className={swiperContainerClasses}>
				<Swiper
					grabCursor={true}
					direction="horizontal"
					scrollbar={{ el: "", draggable: true, hide: true }}
					slidesPerView={
						currentBreakPoint === "small" || currentBreakPoint === "xSmall"
							? 1
							: 3
					}
					spaceBetween={20}
					loop={true}
					autoplay={{ delay: 3000 }}
					pagination={{
						el: ".advisor-feedback-pagination",
						clickable: true,
						renderBullet: (index, className) =>
							`<span class="bordered ${className}"></span>`,
					}}
				>
					{advisorData.map((data, index) => (
						<SwiperSlide key={`advisor-events-home${index}`}>
							<AdvisorMiniCard {...data} />
						</SwiperSlide>
					))}
				</Swiper>
				<div className={paginationClasses}></div>
			</div>
		</PageSection>
	);
};

export default AdvisorResponsive;
