import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";
import { Col, Row } from "react-bootstrap";
// import EnquiryFormResponsive from "../EnquiryFormResponsive/EnquiryFormResponsive";
import PageSection from "../PageSection/PageSection";
// import ContactUsForm from "../ContactUsFrom/ContactUsForm";
import { BsFillHouseFill } from "react-icons/bs";
import CustomIcon from "../CustomIcon/CustomIcon";
import { AiFillFilePdf } from "react-icons/ai";
import { BiWorld } from "react-icons/bi";
import { AiFillPhone, AiTwotoneMail } from "react-icons/ai";
import { aboutUsCardsData } from "../../static-data/aboutus.data";
import AboutUsMiniCard from "../AboutUsMiniCard/AboutUsMiniCard";
// import PageSectionHeader from "../../components/PageSectionHeader/PageSectionHeader";
const AboutAlumniResponsive = () => {
	const [currentBreakPoint, setCurrentBreakPoint] = useState("");

	useEffect(() => {
		bsBreakpoints.init();
		setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

		const handleBreakPoint = (event) => {
			setCurrentBreakPoint(event.detail);
		};

		window.addEventListener("new.bs.breakpoint", handleBreakPoint);

		return () => {
			window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
		};
	}, []);

	const descriptionClasses = classNames("mb-4 text-center text-text-color", {
		"font-sm": currentBreakPoint === "xSmall" || currentBreakPoint === "small",
	});

	return (
		<PageSection bgClass="background-color">
			<ResponsivePageHeader centered={true} title="Alumni " />
			<p className={descriptionClasses}>
				Welcome to the alumni webpage of NIIS Institute of Business
				Administration (NIBA), where we aim to create a vibrant community of
				alumni who have passed through the halls of our institution. Our alumni
				are our pride and joy, and we are always excited to hear about their
				success stories and accomplishments. <br />
				At NIBA, we believe in nurturing long-lasting relationships with our
				alumni. We believe that our alumni are our ambassadors, and we are proud
				to showcase their achievements and success stories to the world. We also
				believe that alumni are valuable resources that can help us grow and
				improve our institution. Our alumni have gone on to work in various
				industries and companies across the globe. They have become successful
				entrepreneurs, executives, and leaders in their respective fields. Many
				of them have also chosen to give back to the institution that helped
				shape their careers.
			</p>
			<a
				href={process.env.PUBLIC_URL + "/CRS.pdf"}
				className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
				target="_blank"
				rel="noopener noreferrer"
			>
				<CustomIcon currentIcon={AiFillFilePdf} size="md" />
				<span className="ml-2">
					DOWNLOAD Certificate of Registration of Societies
				</span>
			</a>
		</PageSection>
	);
};

export default AboutAlumniResponsive;
