export const advisorData = [
	{
		imageUrl: require("../assets/advisor/an.mohanty.jpg"),
		name: "Prof. (Dr.) A. N. Mohanty",
		designation: "Dean School of Humanities",
		content:
			"The Calling of the humanities is to make us truly human in the best sense of the word",
	},
	{
		imageUrl: require("../assets/advisor/suryakanta.jpeg"),
		name: "Prof. Suryakanta Mahapatra",
		designation: "Dean School of Computer Science",
		content: "The science of today is the technology of tomorrow. ",
	},
	// {
	//   imageUrl: require("../assets/advisor/sain.jpeg"),
	//   name: "Prof. (Dr.) R. Sain",
	//   designation: "Dean School of Management",
	//   content: "If you love what you’re doing you will be successful",
	// },
	{
		imageUrl: require("../assets/advisor/kalyani.singh.jpg"),
		name: "Prof. (Dr.) Kalyani Singh",
		designation: "HOD Dept. of Biotechnology",
		content:
			"Research is to see what everybody else has seen, and to think what nobody else has thought",
	},
	{
		imageUrl: require("../assets/advisor/bk.nayak.jpeg"),
		name: "Prof. (Dr.) B.K. Nayak",
		designation: "Dept. of Social Work",
		content:
			"The best way to find yourself is to lose yourself in the service of others.",
	},
];
