import React from "react";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import PageHeader from "../../components/PageHeader/PageHeader";
import PageSection from "../../components/PageSection/PageSection";
import PageSectionHeader from "../../components/PageSectionHeader/PageSectionHeader";

const SoftSkillLabPage = () => {
  return (
    <PageWrapper>
      <PageHeader title="Soft Skills Lab" />
      <PageSection>
        <p>
          The Institutional Soft Skill lab plays a vital role in developing the
          pre-requisite for a successful corporate career. The exclusive design
          and the right mix of teaching and training processes of the soft
          skills using lab helps the students to add new dimension to their
          personality.
        </p>
        <p>
          An activity based, learning -centric curriculum like Goal Setting,
          SWOT Analysis, Assertiveness, Anger Management, Motivated Mind Set,
          Positive Attitude, Creativity, Good English Speaking, Time Management,
          Presentation Skills, Attributes of a Successful Person, Group
          Discussion, Manners, Etiquettes, Interview & Resume, Body Language,
          Business Ethics and Values, Transactional Analysis etc ensures that
          the student will be well trained to understand the subtleties of the
          corporate world. The overwhelming responses of the student towards
          their grooming surely enable them to meet the corporate challenges and
          create difficulties for corporate houses to select the better one
          among the bests.
        </p>
      </PageSection>
      <PageSection bgClass="light">
        <PageSectionHeader title="Gallery" leftStyle="bottom" />
        <p>Comming Soon</p>
      </PageSection>
    </PageWrapper>
  );
};

export default SoftSkillLabPage;
