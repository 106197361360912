export const electricalLessonPlan= [
	{
		id: 1,
		title: "Sem-1",
		link: "https://drive.google.com/drive/folders/1CWgOA-Wx1uDSTvgJH6olqZrAm62F6df_?usp=drive_link",
	},
	{
		id: 2,
		title: "Sem-2",
		link: "https://drive.google.com/drive/folders/1H1DaFjNYU1_pnwDFyMkadHrV8ERpXUZU?usp=drive_link",
	},
	{
		id: 3,
		title: "Sem-3",
		link: "https://drive.google.com/drive/folders/1AzkWpcf2T-xtHXiRh3I2WjcF0rIDP9xK?usp=drive_link",
	},
	{
		id: 4,
		title: "Sem-4",
		link: "https://drive.google.com/drive/folders/1mani1M0QIbdCPBq85ZU3aovKN62AWkPQ?usp=drive_link",
	},
	{
		id: 5,
		title: "Sem-5",
		link: "https://drive.google.com/drive/folders/17aB1xemOSe0cj4ER7xoht6nkAP2m62j0?usp=drive_link",
	},
	{
		id: 6,
		title: "Sem-6",
		link: "https://drive.google.com/drive/folders/1DyZpG3el89_nseVNdEO4UDAuUV30Dkh6?usp=drive_link",
	},
];

export const mechanicalLessonPlan = [
	{
		id: 1,
		title: "Sem-1",
		link: "https://drive.google.com/drive/folders/1CWgOA-Wx1uDSTvgJH6olqZrAm62F6df_?usp=drive_link",
	},
	{
		id: 2,
		title: "Sem-2",
		link: "https://drive.google.com/drive/folders/1H1DaFjNYU1_pnwDFyMkadHrV8ERpXUZU?usp=drive_link",
	},
	{
		id: 3,
		title: "Sem-3",
		link: "https://drive.google.com/drive/folders/1QysZxB9lEnDtNMF6gBFrQkiaXIYCljXg?usp=drive_link",
	},

	{
		id: 4,
		title: "Sem-4",
		link: "https://drive.google.com/drive/folders/1EJX-60bksB7q4R5ToQral4BlOsFmiXGG?usp=drive_link",
	},
	{
		id: 5,
		title: "Sem-5",
		link: "https://drive.google.com/drive/folders/1jYUfgFgnFbnZKEg1NBEDlOWThfJ7pnL5?usp=drive_link",
	},
	{
		id: 6,
		title: "Sem-6",
		link: "https://drive.google.com/drive/folders/1bCstLlyGwZPLe4ke3J1vY1MvveSx1j_M?usp=drive_link",
	},
];

export const civilLessonPlan= [
	{
		id: 1,
		title: "Sem-1",
		link: "https://drive.google.com/drive/folders/1CWgOA-Wx1uDSTvgJH6olqZrAm62F6df_?usp=drive_link",
	},
	{
		id: 2,
		title: "Sem-2",
		link: "https://drive.google.com/drive/folders/1H1DaFjNYU1_pnwDFyMkadHrV8ERpXUZU?usp=drive_link",
	},
	{
		id: 3,
		title: "Sem-3",
		link: "https://drive.google.com/drive/folders/19pwqWEnFg8Rl7W09uS3QMf5kuW-9PYvK?usp=drive_link",
	},
	{
		id: 4,
		title: "Sem-4",
		link: "https://drive.google.com/drive/folders/1I4Mo1ByDiye4nJJJN8rKqq9SjxYDHoIo?usp=drive_link",
	},
	{
		id: 5,
		title: "Sem-5",
		link: "https://drive.google.com/drive/folders/1PmeBhvF461atwAa6gEPM4gxkWbnmqYZ8?usp=drive_link",
	},
	{
		id: 6,
		title: "Sem-6",
		link: "https://drive.google.com/drive/folders/1e7noTEtw9lwimi2apVm8nA5OtyibNeH0?usp=drive_link",
	},
	
];

