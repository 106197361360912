import React from "react";
import PageSection from "../PageSection/PageSection";
import TrusteeMessageCard from "../TrusteeMessageCard/TrusteeMessageCard";
import { chairmanData, jmTrusteeData } from "../../static-data/chairman.data";
const HODResponsive = (props) => {
	return (
		<PageSection bgClass="white">
			<TrusteeMessageCard
				type="chairman"
				titlePrefix="Message From"
				{...props.hodData}
			/>
		</PageSection>
	);
};

export default HODResponsive;
