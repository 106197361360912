import React from "react";
import { Row, Col } from "react-bootstrap";
import { BsFillHouseFill } from "react-icons/bs";
import { BiWorld } from "react-icons/bi";
import { AiFillPhone, AiTwotoneMail } from "react-icons/ai";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import PageHeader from "../../components/PageHeader/PageHeader";
import PageSection from "../../components/PageSection/PageSection";
import CustomIcon from "../../components/CustomIcon/CustomIcon";
import ContactUsForm from "../../components/ContactUsFrom/ContactUsForm";
import PageSectionHeader from "../../components/PageSectionHeader/PageSectionHeader";
import HeroSection from "../../components/HeroSection/HeroSection";
import HeroCaption from "../../components/HeroCaption/HeroCaption";
import StudentCellResponsive from "../../components/StudentCellResponsive/StudentCellResponsive";
import ContactUsResponsive from "../../components/ContactUsResponsive/ContactUsResponsive";
import ContactInformationResponsive from "../../components/ContactInformationResponsive/ContactInformationResponsive";
const ContactUsPage = () => {
	return (
		<PageWrapper className="mt-n6">
			<HeroSection className="bg-page-background-14">
				<HeroCaption
					title="Contact Us"
					position="bottom-left"
					breadcrumb={true}
					breadcrumbPrefix="Home"
				/>
			</HeroSection>
			<ContactUsResponsive />
			<ContactInformationResponsive />
			{/* <PageHeader title="Contact Us" />
			<PageSection>
				<Row className="align-items-stretch">
					<Col md="7">
						<div className="mb-4">
							<ContactUsForm />
						</div>
					</Col>
					<Col md="5">
						<div className="border mb-4 rounded px-5 py-4 bg-light">
							<PageSectionHeader title="Campus" rightStyle="bottom" />
							<Row className="mb-2 align-items-start">
								<Col xs="2">
									<CustomIcon
										currentIcon={BsFillHouseFill}
										size="md"
										colorClass="text-brand-2"
									/>
								</Col>
								<Col xs="10">
									<p>Sarada Vihar, Madanpur, BBSR - 54</p>
								</Col>
							</Row>
							<Row className="mb-2 align-items-start">
								<Col xs="2">
									<CustomIcon
										currentIcon={AiFillPhone}
										size="md"
										colorClass="text-brand-2"
									/>
								</Col>
								<Col xs="10">
									<p>(0674) 2113232 / Fax : (0674) 2113242</p>
								</Col>
							</Row>
							<Row className="mb-2 align-items-start">
								<Col xs="2">
									<CustomIcon
										currentIcon={AiFillPhone}
										size="md"
										colorClass="text-brand-2"
									/>
								</Col>
								<Col xs="10">
									<p>9040004375, 9778899991, 9778899990, 8144278720</p>
								</Col>
							</Row>
							<Row className="mb-2 align-items-start">
								<Col xs="2">
									<CustomIcon
										currentIcon={AiTwotoneMail}
										size="md"
										colorClass="text-brand-2"
									/>
								</Col>
								<Col xs="10">
									<a
										href="mailto:contactus@niisgroup.org"
										className="download-link"
									>
										contactus@niisgroup.org
									</a>
								</Col>
							</Row>
						</div>
					</Col>
				</Row>
			</PageSection>
			<PageSection bgClass="light">
				<Row>
					<Col md="6">
						<div className="border mb-4 rounded px-5 py-4 bg-white">
							<PageSectionHeader
								title="Administrative Office"
								leftStyle="bottom"
							/>
							<Row className="mb-2 align-items-start">
								<Col xs="2">
									<CustomIcon
										currentIcon={BsFillHouseFill}
										size="md"
										colorClass="text-brand-2"
									/>
								</Col>
								<Col xs="10">
									<p>
										Plot No. - 339 (P), Goutam Nagar, BMC-Mausimaa Main Road,
										BBSR -751014, Odisha,India.
									</p>
								</Col>
							</Row>
							<Row className="mb-2 align-items-start">
								<Col xs="2">
									<CustomIcon
										currentIcon={AiFillPhone}
										size="md"
										colorClass="text-brand-2"
									/>
								</Col>
								<Col xs="10">
									<p>(0674) 2435745 / 2435746 Fax: (0674) 2536559</p>
								</Col>
							</Row>

							<Row className="mb-2 align-items-start">
								<Col xs="2">
									<CustomIcon
										currentIcon={BiWorld}
										size="md"
										colorClass="text-brand-2"
									/>
								</Col>
								<Col xs="10">
									<a
										href="mailto:contactus@niisgroup.org"
										className="download-link"
									>
										contactus@niisgroup.org
									</a>
								</Col>
							</Row>
						</div>
					</Col>
					<Col md="12">
						<div class="map">
							<iframe
								src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14973.781804997008!2d85.7252298!3d20.2403525!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe8052118d487bb86!2sNIIS+Institute+Of+Business+Administration!5e0!3m2!1sen!2sin!4v1527159121709"
								width="100%"
								height="400"
								className="border shadow"
								title="Contact Map"
							></iframe>
						</div>
					</Col>
				</Row>
			</PageSection> */}
		</PageWrapper>
	);
};

export default ContactUsPage;
