import React from "react";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import HeroSection from "../../components/HeroSection/HeroSection";
import HeroCaption from "../../components/HeroCaption/HeroCaption";
import MBAResponsive from "../../components/MBAResponsive/MBAResponsive";
import ResourcesResponsive from "../../components/ResourcesResponsive/ResourcesResponsive";
import MBAChoiceOfElectivesResponsive from "../../components/MBAChoiceOfElectivesResponsive/MBAChoiceOfElectivesResponsive";

const MBACoursePage = () => {
	return (
		<PageWrapper className="mt-n6">
			<HeroSection className="bg-page-background-7">
				<HeroCaption
					title="Academics"
					position="bottom-left"
					breadcrumb={true}
					breadcrumbPrefix="Home"
				/>
			</HeroSection>
			<MBAResponsive />
			{/* <MBAChoiceOfElectivesResponsive /> */}
			<ResourcesResponsive headerContent={""} />
		</PageWrapper>
	);
};

export default MBACoursePage;
