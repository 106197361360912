import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";
import { Swiper, SwiperSlide } from "swiper/react";
import { GoChevronRight, GoChevronLeft } from "react-icons/go";
import CustomIcon from "../CustomIcon/CustomIcon";
import EventsCard from "../EventsCard/EventsCard";
import PageSection from "../PageSection/PageSection";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";
import { upcommingEventsData } from "../../static-data/upcommingevents.data";

const UpcommingEventsResponsive = () => {
  const [currentBreakPoint, setCurrentBreakPoint] = useState("");

  useEffect(() => {
    bsBreakpoints.init();
    setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

    const handleBreakPoint = (event) => {
      setCurrentBreakPoint(event.detail);
    };

    window.addEventListener("new.bs.breakpoint", handleBreakPoint);

    return () => {
      window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
    };
  }, []);

  const paginationClasses = classNames(
    "events-pagination swiper-pagination-container  swiper-pagination-container--bottom swiper-pagination-container--horizontal swiper-pagination-container--horizontal---right",
    {
      hide: !(currentBreakPoint === "small" || currentBreakPoint === "xSmall"),
    }
  );

  const navigationClasses = classNames(
    "swiper-navigation-horizontal-container swiper-navigation-horizontal-container--center",
    {
      hide: currentBreakPoint === "small" || currentBreakPoint === "xSmall",
    }
  );

  return (
    <PageSection bgClass="page-background-3">
      <div className="py-5">
        <ResponsivePageHeader
          centered={true}
          colorClass="text-white"
          marginClass="mb-2"
          title="Upcoming Events"
        />
        <p className="text-uppercase text-center text-white mb-5">
          NIIS like you’ve never seen it before
        </p>

        <div className="position-relative">
          <Swiper
            grabCursor={true}
            direction="horizontal"
            scrollbar={{ el: "", draggable: true, hide: true }}
            slidesPerView={
              currentBreakPoint === "small" || currentBreakPoint === "xSmall"
                ? 1
                : 3
            }
            spaceBetween={20}
            loop={true}
            autoplay={{ delay: 3000 }}
            navigation={{
              prevEl: ".events-nav-prev",
              nextEl: ".events-nav-next",
            }}
            pagination={{
              el: ".events-pagination",
              clickable: true,
              renderBullet: (index, className) =>
                `<span class="bordered ${className}"></span>`,
            }}
          >
            {upcommingEventsData.map((data, index) => (
              <SwiperSlide key={`upcoming-events-home${index}`}>
                <EventsCard {...data} />
              </SwiperSlide>
            ))}
          </Swiper>

          <div className={paginationClasses}></div>

          <div className={navigationClasses}>
            <span className="events-nav-prev swiper-navigation-control swiper-navigation-control--center---left swiper-navigation-control--center---left--large">
              <CustomIcon
                currentIcon={GoChevronLeft}
                colorClass="text-white"
                size="lg"
              />
            </span>
            <span className="events-nav-next swiper-navigation-control swiper-navigation-control--center---right swiper-navigation-control--center---right--large">
              <CustomIcon
                currentIcon={GoChevronRight}
                colorClass="text-white"
                size="lg"
              />
            </span>
          </div>
        </div>
      </div>
    </PageSection>
  );
};

export default UpcommingEventsResponsive;
