import React from "react";

import PageSection from "../PageSection/PageSection";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";
import { Col, Row } from "react-bootstrap";
import WeekMiniCard from "../WeekMiniCard/WeekMiniCard";
import { infrastructureData } from "../../static-data/infrastructure.data";

const InfrastructureAndFacilitesResponsive = () => {
	return (
		<PageSection bgClass="background-color">
			<ResponsivePageHeader centered={true} title="A typical week at NIIS" />
			<div className="px-2 px-md-5">
				<Row className="justify-content-center gx-1">
					{infrastructureData.map((data, index) => (
						<Col
							md="3"
							className="d-flex justify-content-center align-items-baseline justify-content-md-center"
							key={"infrastructure-home" + index}
						>
							<WeekMiniCard {...data} />
						</Col>
					))}
				</Row>
			</div>
		</PageSection>
	);
};

export default InfrastructureAndFacilitesResponsive;
