export const chairmanData = {
	imageUrl: require("../assets/managing-trustee/chairman.png"),
	designation: "Founder Chairman",
	name: "Dr. Subhash Chandra Nayak",
	feedback: `When you first start your academic career, you should look for an institution that will
   take you on an exciting trip that will help you to grow and emerge as a leader. To better
   prepare you for future difficulties and disruptions, and to serve as a pillar in the progress
   of our great country. The NIIS Institute of Engineering & Technology is the perfect place to begin your career because
   of its academic brilliance and philanthropic mission to develop global leaders.
   We are committed to making a difference in professional and higher education in India,
   and we are taking steps to develop a model that recognises and meets the
   requirements of students.`,
};

export const jmTrusteeData = {
	imageUrl: require("../assets/managing-trustee/trustee2.png"),
	designation: "Joint Managing Trustee",
	name: "Prof. Smrutisudha Nayak",
	feedback: `We also provide you with an exceptional opportunity to push yourself to new heights while also creating an environment that fosters interactive learning and life skills. If any of this rings true for you, and you're dedicated to achieving success in your chosen industry, we look forward to meeting you on our bustling campus!`,
};

export const memberData = [
	{
		imageUrl: require("../assets/faculty/STAFF PHOTO/Satyapira Choudhury.jpg"),
		designation: "Faculty Nominee",
		name: "Satyapira Choudhury",
		feedback: `"Education is not just about learning, it's about empowering the next generation to make a difference in the world"`,
	},
	{
		imageUrl: require("../assets/faculty/STAFF PHOTO/Bhabani Sankar Rath.JPG"),
		designation: "Faculty Nominee",
		name: "Prof. Bhabani Shankar Rath",
		feedback: `"At NIIS, we believe in fostering an environment that encourages creativity, innovation, and excellence.""`,
	},
	{
		imageUrl: require("../assets/faculty/STAFF PHOTO/D. K. Pattanaik.JPG"),
		designation: "Faculty Nominee",
		name: "Dr. Dusmanta Kumar Pattanaik",
		feedback: `"Our goal is to provide our students with a holistic education that prepares them for both personal and professional success."`,
	},
	{
		imageUrl: require("../assets/faculty/STAFF PHOTO/Rajesh Sain.JPG"),
		designation: "Faculty Nominee",
		name: "Dr. Rajesh Sain",
		feedback: `"As members of the Board of Governors, we are committed to ensuring that NIIS continues to be a leading institution in the field of technical education."`,
	},
];
