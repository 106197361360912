import React from "react";
import { Container } from "react-bootstrap";
import classNames from "classnames";

import "./PageHeader.styles.scss";

const PageHeader = ({ title, imageUrl }) => {
  const pageHeaderClassNames = classNames("text-center", "w-100", {
    "heading-background": imageUrl,
  });

  const styles = imageUrl
    ? {
        backgroundImage: `linear-gradient(180deg, rgba(255, 255, 255, 0.3) 50%, 
        rgba(255, 255, 255, 0.3) 100%), url(${
          imageUrl.default ? imageUrl.default : imageUrl
        })`,
      }
    : {};

  return (
    <Container>
      <div className={pageHeaderClassNames} style={styles}>
        <h1 className="heading-text heading-text--styled-left-bottom heading-text--styled-right-top mt-4">
          {title}
        </h1>
        {!imageUrl ? (
          <div className="w-50 mx-auto">
            <hr />
          </div>
        ) : null}
      </div>
    </Container>
  );
};

export default PageHeader;
