import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import PageHeader from "../../components/PageHeader/PageHeader";
import PageSection from "../../components/PageSection/PageSection";
import PageSectionHeader from "../../components/PageSectionHeader/PageSectionHeader";
import PageWrapper from "../../components/PageWrapper/PageWrapper";

const ProjectAndInternshipPage = () => {
  return (
    <PageWrapper>
      <PageHeader title="Projects and Internships" />
      <PageSection>
        <p>
          We have established our secure ties with global/public corporate
          houses, like Essar Groups, Indian Express, Axis Bank, ITC Foods, Magma
          Leasing, and Vedanta. Other than we approach various HR consultancies
          who work for various reputed associations. It fills us with growing
          paced corporate's biz and keeps us on the track of modernization as
          well.
        </p>
        <p>
          We also practise providing our students with internships where they
          can get all the exposure required for building a true office
          environment spirit and learn to be a team player. Companies like BSNL,
          NALCO, TISCO, BAJAJ Auto, NIC, BSNL, ICICI, HDFC Bank, LG Electronics,
          Reliance Industries, Airtel, MRF, P&G, OPGC, PPT, IMFA, AMUL, HCL,
          TATA CAPITAL, Pantaloons, Asian Paints, LLOYDS are known to take up
          our assets for rigorous training and internships.
        </p>
      </PageSection>
      <PageSection bgClass="light">
        <PageSectionHeader title="Gallery" rightStyle="bottom" />
        <Row className="justify-content-center">
          <Col md="6">
            <Image
              src={
                require("../../assets/projects-interships/iic 8.JPG")
                  .default
              }
              className="img-fluid rounded mb-4"
            />
          </Col>
          <Col md="6">
            <Image
              src={
                require("../../assets/projects-interships/iic 12.jpg")
                  .default
              }
              className="img-fluid rounded mb-4"
            />
          </Col>
        </Row>
      </PageSection>
    </PageWrapper>
  );
};

export default ProjectAndInternshipPage;
