import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";

import PageSection from "../PageSection/PageSection";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";
import { Col, Row } from "react-bootstrap";
import { aboutUsCardsData } from "../../static-data/aboutus.data";
import AboutUsMiniCard from "../AboutUsMiniCard/AboutUsMiniCard";

const AboutUsResponsive = () => {
	const [currentBreakPoint, setCurrentBreakPoint] = useState("");

	const descriptionClasses = classNames("mb-4 text-text-color", {
		"font-sm": currentBreakPoint === "xSmall" || currentBreakPoint === "small",
	});

	useEffect(() => {
		bsBreakpoints.init();
		setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

		const handleBreakPoint = (event) => {
			setCurrentBreakPoint(event.detail);
		};

		window.addEventListener("new.bs.breakpoint", handleBreakPoint);

		return () => {
			window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
		};
	}, []);

	return (
		<PageSection bgClass="background-color">
			<ResponsivePageHeader centered={true} title="About Us" />
			<p className={descriptionClasses}>
				At NIET, we are dedicated to providing our students with a world-class
				education that prepares them for successful careers in the dynamic and
				rapidly evolving field of engineering. Our faculty members are highly
				qualified and experienced, and they are committed to ensuring that our
				students receive the best possible education.
			</p>
			<Row>
				{aboutUsCardsData.map((data, index) => (
					<Col
						md="3"
						className="d-flex align-items-stretch mb-4 mb-md-0"
						key={"about-us-home" + index}
					>
						<AboutUsMiniCard type="about" {...data} />
					</Col>
				))}
			</Row>
		</PageSection>
	);
};

export default AboutUsResponsive;
