import React from "react";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import HeroSection from "../../components/HeroSection/HeroSection";
import HeroCaption from "../../components/HeroCaption/HeroCaption";
import MCAResponsive from "../../components/MCAResponsive/MCAResponsive";
import ResourcesResponsive from "../../components/ResourcesResponsive/ResourcesResponsive";
import MCAChoiceOfElectivesResponsive from "../../components/MCAChoiceOfElectivesResponsive/MCAChoiceOfElectivesResponsive";

const MCACoursePage = () => {
	return (
		<PageWrapper className="mt-n6">
			<HeroSection className="bg-page-background-7">
				<HeroCaption
					title="Academics"
					position="bottom-left"
					breadcrumb={true}
					breadcrumbPrefix="Home"
				/>
			</HeroSection>
			<MCAResponsive />
			<MCAChoiceOfElectivesResponsive />
			<ResourcesResponsive headerContent={""} lessonPlan={""} copo={""} />
		</PageWrapper>
	);
};

export default MCACoursePage;
