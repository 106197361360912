import React from "react";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import PageHeader from "../../components/PageHeader/PageHeader";
import PageSection from "../../components/PageSection/PageSection";
import PageSectionHeader from "../../components/PageSectionHeader/PageSectionHeader";

const DirectorsMessagePage = () => {
  return (
    <PageWrapper>
      <PageHeader title="Director's Message" />
      <PageSection>
        <PageSectionHeader title="Why NIIS?" rightStyle="bottom" />
        <p>
          Away from city pollution, nestled in the lap of the nature, having
          established green lush campus, within the smart city jurisdiction,
          NIIS Group of Institutions has accomplished much in its little over 18
          years of existence(founded since 2000).
        </p>

        <p>
          Our motto is to “create socially responsible leaders”. NIIS strives to
          impart wisdom through education. Our mantra is hard-work, discipline &
          honesty In NIIS, we believe in Educate, Empower and Elevate as a
          continued process. Learn from the leaders and game changers in the
          industry.
        </p>

        <p>
          Master classes are a welcome bonus for the NIIS students. Long
          cherished dream of our founder and the determination of our entire
          team members to break the status grow, has led to NIIS being ranked as
          one of the top professional institute in the state of Odisha. During
          last 3 years, we have bagged a record number of awards to our credit
          including UGC directly recognizing us under sec-2f of UGC act 1956.
        </p>

        <p>
          NIIS is highly aggressive organization. We have to be that. We are
          attempting a pioneering, and path-breaking effort in education. We are
          bringing together Science, Commerce, IT, Social work and Management to
          make them co-exist in what can easily be called the hardest 5-way
          marriage ever. And, like all pioneering efforts, we do not have the
          mistakes of others to learn from, which makes each of decisions much
          harder.
        </p>
      </PageSection>
      <PageSection bgClass="light">
        <p>
          The combination of our vision, facilities, infrastructures and faculty
          is a potent mix. We provide resources that are above industry
          standards to every aspirant to discover their inner talent in today’s
          corporate world.
        </p>

        <p>
          The unique elements are our curriculum, skill based training and
          pedagogies used have seen NIIS students mind growing to four-fold over
          the past 18 years of dedicated efforts.
        </p>

        <p>
          Our endeavors like all true pioneering efforts are never complete. We
          prepare our students to suit to industry standards and support them
          with enough placement opportunities. This have paved the way for
          larger students to put their interest to join our institute.
        </p>

        <p>
          We find that we are constantly refining and redesigning our efforts
          looking at the lightning change to make the level of education, the
          experience and the environment better for our students, faculties and
          the industry.
        </p>

        <p>
          At NIIS, we have a vision to build a cadre of strong creative people,
          IT professionals, multi skillful professionals and passionate business
          professionals with a deep understanding of the ever changing global
          demands.
        </p>

        <p>I invite you to join us in this journey. </p>
      </PageSection>
    </PageWrapper>
  );
};

export default DirectorsMessagePage;
