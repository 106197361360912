export const socialMediaData = [
	{
		avatarUrl: require("../assets/social-blogs/social-avatar.png"),
		iconUrl: require("../assets/social-blogs/instagram.png"),
		bannerUrl: require("../assets/social-blogs/5.jpeg"),
		name: "NIIS Institute of Engineering & Technology",
		username: "@niba",
		likes: "102",
		dateTime: "17 April, 2022",
		desciption: `Dr.Subhash Chandra Nayak, founder cum Secretary of NIIS group of Institutions and Mrs.Smrutisudha Nayak, CEO of NIIS group of Institutions have been felicitated by his Excellency ,honourable Governor of Andhra Pradesh Biswabhusan Harichandan.`,
	},
	{
		avatarUrl: require("../assets/social-blogs/social-avatar.png"),
		iconUrl: require("../assets/social-blogs/instagram.png"),
		bannerUrl: require("../assets/social-blogs/4.jpg"),
		name: "NIIS Institute of Engineering & Technology",
		username: "@niba",
		likes: "95",
		dateTime: "20 April, 2022",
		desciption: `NIIS Institute of Engineering & Technology, a constituent college of NIIS Group of Institutions conducted a National Seminar: "Revisiting the landscape of IoT: A technology for common man"`,
	},
	{
		avatarUrl: require("../assets/social-blogs/social-avatar.png"),
		iconUrl: require("../assets/social-blogs/instagram.png"),
		bannerUrl: require("../assets/social-blogs/1.jpeg"),
		name: "NIIS Institute of Engineering & Technology",
		username: "@niba",
		likes: "125",
		dateTime: "26 April, 2022",
		desciption:
			"Our beloved Founder Chairman was awarded the Business Eminence Award for 2022 by Tathagata Satpathy, Former Member of Parliament & editor of renowned English daily Orissa POST & Odiya daily, Dharitri & Naveen Jindal, Chairman of Jindal Steel & Power Limited & Chancellor of O.P Jindal Global University.",
	},
	{
		avatarUrl: require("../assets/social-blogs/social-avatar.png"),
		iconUrl: require("../assets/social-blogs/instagram.png"),
		bannerUrl: require("../assets/social-blogs/2.jpg"),
		name: "NIIS Institute of Engineering & Technology",
		username: "@niba",
		likes: "73",
		dateTime: "21 May, 2022",
		desciption: `NIIS Institute of Engineering & Technology, a constituent college of NIIS Group of Institutions conducted a National Seminar: "Industry 4.0: The Evolving Business Landscape."`,
	},
	{
		avatarUrl: require("../assets/social-blogs/social-avatar.png"),
		iconUrl: require("../assets/social-blogs/instagram.png"),
		bannerUrl: require("../assets/social-blogs/3.jpg"),
		name: "NIIS Institute of Engineering & Technology",
		username: "@niba",
		likes: "73",
		dateTime: "30 December, 2021",
		desciption:
			"With the divine blessings of Shri Sai Baba,the inauguration of new temple on NIIS premises took place on 1st December 2021.",
	},
	// add more entry here
];

/*

step 1 : copy below code and place it above to add new entry (with curly brackets)

{
  avatarUrl: require("../assets/social-blogs/social-avatar.png"),
  iconUrl: require("../assets/social-blogs/instagram.png"),
  bannerUrl: require("../assets/social-blogs/YOUR_IMAGE_FILENAME.extension"),
  name: "NIIS Institute of Business Administration",
  username: "@niba",
  likes: "YOUR_POST_NO_OF_LIKES",
  dateTime: "YOUR_POST_DATE",
  desciption: "YOUR_POST_DESCRIPTION"
}

step 2 : put the post image in assets/social-blogs/ folder

step 3 : change the photo filename with extension in bannerUrl

step 4 : update likes, dateTime and description with the relevant content

step 5 : save the file and push to github main branch main to deploy it use below command
`
  git add .
  git commit -m 'social media update'
  git push origin main
`

*/
