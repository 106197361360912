import React from "react";
import { AiFillFilePdf } from 'react-icons/ai'
import CustomIcon from '../../components/CustomIcon/CustomIcon'
import PageHeader from "../../components/PageHeader/PageHeader";
import { Col, Image, Row } from "react-bootstrap";
import PageSection from "../../components/PageSection/PageSection";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import { newRecruiterImages } from "../../static-data/recruiter.data";

const RecruitersAndPlacementPage = () => {
  return (
    <PageWrapper>
      <PageHeader title="Our recruiters & Placement Records" />
      <PageSection>
        <p>
          Over the years, we have established proud endeavours in the forms of
          our great alumni achiever students who have been placed into some of
          the most prestigious companies pan India. Tied up with groundbreaking
          and progressing companies at all times, it gives us an absolute
          pleasure to present to you- our most trusted and promising companies
          joining us for placement ventures throughout these fruitful years!
        </p>
        <p className="text-center mt-4">
          <a href="/#" className="download-link">
            <CustomIcon currentIcon={AiFillFilePdf} />
            <span className="ml-2">Placement Brochure</span>
          </a>
        </p>
        
      </PageSection>
      <PageSection bgClass="light">
        <Row>
          {newRecruiterImages.map((img, index) => (
            <Col sm="2" key={`new-recruiter-${index}`}>
              <Image
                src={img.default ? img.default : img}
                className="m-3 img-fluid w-100"

              />
            </Col>
          ))}
        </Row>
      </PageSection>
    </PageWrapper>
  );
};

export default RecruitersAndPlacementPage;
