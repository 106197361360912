import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { db } from "../../services/firebase";

const EnquiryFormResponsive = () => {
	const [enquiryForm, setEnquiryForm] = useState({});

	const handleChange = (event) => {
		const { name, value } = event.target;

		setEnquiryForm({ ...enquiryForm, [name]: value });
	};

	const handleSubmit = async (event) => {
		event.preventDefault();
		const enquiryRef = db.collection("inquiriesNIET");

		try {
			await enquiryRef.add({
				...enquiryForm,
			});

			setEnquiryForm({});
			alert("From submitted");
			window.location.reload();
		} catch (err) {
			alert(err);
		}
	};

	return (
		<Form className="text-white" onSubmit={handleSubmit}>
			<h3 className="mb-4">Fill the Form</h3>

			<Form.Group className="mb-4" controlId="info-science-enquiry-name">
				{/* <Form.Label>Name*</Form.Label> */}
				<Form.Control
					type="text"
					placeholder="Enter Name"
					required
					className="form-control--transparent shadow-sm"
					name="name"
					onChange={handleChange}
				/>
			</Form.Group>

			<Form.Group className="mb-4" controlId="info-science-enquiry-email">
				{/* <Form.Label>Email*</Form.Label> */}
				<Form.Control
					type="email"
					placeholder="Enter Email"
					required
					className="form-control--transparent shadow-sm"
					name="email"
					onChange={handleChange}
				/>
			</Form.Group>

			<Form.Group className="mb-4" controlId="info-science-enquiry-phone">
				{/* <Form.Label>Phone Number*</Form.Label> */}
				<Form.Control
					type="tel"
					size="12"
					placeholder="Enter Phone number"
					pattern="[0-9]{10,12}"
					required
					className="form-control--transparent shadow-sm"
					name="phoneNumber"
					onChange={handleChange}
				/>
			</Form.Group>

			<Form.Group className="mb-4" controlId="info-science-enquiry-course">
				{/* <Form.Label>Select a course*</Form.Label> */}
				<Form.Control
					as="select"
					required
					className="form-control--transparent shadow-sm"
					name="course"
					onChange={handleChange}
				>
					<option value="">Select courses</option>
					<option value="MCA">B.Tech</option>
					<option value="MBA">Diploma</option>
				</Form.Control>
			</Form.Group>

			<Form.Group className="mb-4" controlId="info-science-enquiry-content">
				{/* <Form.Label>Write to us*</Form.Label> */}
				<Form.Control
					as="textarea"
					rows={5}
					required
					placeholder="Write your query here"
					className="form-control--transparent shadow-sm"
					name="message"
					onChange={handleChange}
				/>
			</Form.Group>

			<Button variant="highlight" className="px-4" type="submit">
				Submit
			</Button>
		</Form>
	);
};

export default EnquiryFormResponsive;
