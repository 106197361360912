import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import PageHeader from "../../components/PageHeader/PageHeader";
import PageSectionHeader from "../../components/PageSectionHeader/PageSectionHeader";
import PageSection from "../../components/PageSection/PageSection";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import HeroSection from "../../components/HeroSection/HeroSection";
import HeroCaption from "../../components/HeroCaption/HeroCaption";
import FacultyDevelopmentProgramResponsive from "../../components/FacultyDevelopmentProgram/FacultyDevelopmentProgramResponsive";
import FacultyDevelopmentProgramGallary from "../../components/FacultyDevelopmentProgramGallary/FacultyDevelopmentProgramGallary";

const FacultyDevelopmentProgramPage = () => {
	return (
		<PageWrapper className="mt-n6">
			<HeroSection className="bg-page-background-fdp">
				<HeroCaption
					title="Faculty Development Program"
					position="bottom-left"
					breadcrumb={true}
					breadcrumbPrefix="Home"
				/>
			</HeroSection>
			<FacultyDevelopmentProgramResponsive />
			<FacultyDevelopmentProgramGallary />
		</PageWrapper>

		// <PageWrapper>
		//   <PageHeader title="Faculty Development Program" />
		//   <PageSection>
		//     <p>
		// The academic excellence of NIIS has been spurred by the group of
		// highly qualified, competent and motivated faculty members having
		// updated knowledge in core subjects and experts in their concerned
		// fields. But to help them keep up with the latest trends and
		// technologies, to understand student psychology to the deepest, we
		// guide the teachers with rigorous training programs and seminars as
		// well- so they can be more interactive and malleable into the student
		// world and understand their perspectives clearly as well.
		//     </p>
		//   </PageSection>
		//   <PageSection bgClass="light">
		//     <PageSectionHeader title="Gallery" rightStyle="bottom" />
		//     <Row className="justify-content-center">
		//       <Col md="6">
		//         <Image
		//           src={
		//             require("../../assets/faculty-dev-prog/iic 10.JPG")
		//               .default
		//           }
		//           className="img-fluid rounded mb-4"
		//         />
		//       </Col>
		//       <Col md="6">
		//         <Image
		//           src={
		//             require("../../assets/faculty-dev-prog/iic 14.jpg")
		//               .default
		//           }
		//           className="img-fluid rounded mb-4"
		//         />
		//       </Col>
		//     </Row>
		//   </PageSection>
		// </PageWrapper>
	);
};

export default FacultyDevelopmentProgramPage;
