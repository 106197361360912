export const facultyData = [
	{
	  email: "debswaruprath@gmail.com",
	  name: "Debswarup Rath",
	  designation: "Assistant Professor",
	  department: "Electrical and Electronics Engineering",
	  phone: 8763836509,
	  areaOfInterest: "Fractional Order Controllers",
	  imageUrl: require("../assets/faculty/NIET/Debswarup Rath.png"),
	},
	{
	  email: "msenapati2010@gmail.com",
	  name: "MRUTYUNJAY SENAPATI",
	  designation: "Vice-Principal",
	  department: "Electrical Engineering",
	  phone: 7978098460,
	  areaOfInterest: "Load frequency control",
	  imageUrl: require("../assets/faculty/NIET/MRUTYUNJAY SENAPATI.png"),
	},
	{
	  email: "dibyashreebhuyan96@gmail.com",
	  name: "Dibyashree bhuyan",
	  designation: "Assistant Professor",
	  department: "Civil Engineering",
	  phone: 7381151182,
	  areaOfInterest: "Under water Construction",
	  imageUrl: require("../assets/faculty/NIET/Dibyashree bhuyan.png"),
	},
	{
	  email: "chandan.lsb@gmail.com",
	  name: "CHINTAMANI TRIPATHY",
	  designation: "System Administrator",
	  department: "Computer Science Engineering",
	  phone: 7008947849,
	  areaOfInterest: "Ethical hacking",
	  imageUrl: require("../assets/faculty/NIET/CHINTAMANI TRIPATHY.png"),
	},
	{
	  email: "champatiraysandeep12@gmail.com",
	  name: "Sandeep Kumar Champatiray",
	  designation: "Assistant Professor",
	  department: "Electrical Engineering",
	  phone: 7008426624,
	  areaOfInterest: "Generation Transmission & Distribution",
	  imageUrl: require("../assets/faculty/NIET/Sandeep Kumar Champatiray.png"),
	},
	{
	  email: "nhs.ray@gmail.com",
	  name: "Dr. N.H.S.Ray",
	  designation: "Principal",
	  department: "Mechanical Engineering",
	  phone: 9437172490,
	  areaOfInterest: "Renewable energy",
	  imageUrl: require("../assets/faculty/NIET/Dr. N.H.S.Ray.png"),
	},
	{
	  email: "umeshnx@gmail.com",
	  name: "UMESH CHANDRA SAMAL",
	  designation: "System Administrator",
	  department: "Computer Science Engineering",
	  phone: 9853712269,
	  areaOfInterest: "NA",
	  imageUrl: require("../assets/faculty/NIET/UMESH CHANDRA SAMAL.png"),
	},
	{
	  email: "biswajitkanha150@gmail.com",
	  name: "Biswajit Mahunta",
	  designation: "Assistant Professor",
	  department: "Basic Science and Humanities",
	  phone: 7008552106,
	  areaOfInterest: "Differential Equation",
	  imageUrl: require("../assets/faculty/NIET/Biswajit Mahunta.png"),
	},
	{
	  email: "pragnya0165.lenka@gmail.com",
	  name: "Pragnyasinee lenka",
	  designation: "Assistant Professor",
	  department: "Electrical Engineering",
	  phone: 8984369767,
	  areaOfInterest: "EV Technology",
	  imageUrl: require("../assets/faculty/NIET/Pragnyasinee lenka.png"),
	},
	{
	  email: "anirban.bhattacharjee13@gmail.com",
	  name: "ANIRBAN BHATTACHARJEE",
	  designation: "Assistant Professor",
	  department: "Basic Science and Humanities",
	  phone: 9658242821,
	  areaOfInterest: "Nanostructured Biomaterials",
	  imageUrl: require("../assets/faculty/NIET/ANIRBAN BHATTACHARJEE.png"),
	},
	{
	  email: "jagannath.parida3119@gmail.com",
	  name: "JAGANNATH PARIDA",
	  designation: "Account Officer",
	  department: "Accounts",
	  phone: 8327700672,
	  areaOfInterest: "NA",
	  imageUrl: require("../assets/faculty/NIET/JAGANNATH PARIDA.png"),
	},
	{
	  email: "puravipanda59@gmail.com",
	  name: "Prachi Puravi Tripathy",
	  designation: "Assistant Professor",
	  department: "Computer Science Engineering",
	  phone: 7978199235,
	  areaOfInterest: "Artificial Intellegence",
	  imageUrl: require("../assets/faculty/NIET/Prachi Puravi Tripathy.png"),
	},
	{
	  email: "swapnaswadhina44@gmail.com",
	  name: "Swapna Swadhina Sahoo",
	  designation: "Assistant Professor",
	  department: "Mechanical Engineering",
	  phone: 8328804051,
	  areaOfInterest: "Thermo mechanical study on sisal fiber reinforced epoxy based hybrid composites",
	  imageUrl: require("../assets/faculty/NIET/Swapna Swadhina Sahoo.png"),
	},
	{
	  email: "sahoopreeti99dav@gmail.com",
	  name: "PREETI SAHOO",
	  designation: "Assistant Professor",
	  department: "Mechanical Engineering",
	  phone: 7991068575,
	  areaOfInterest: "Robotics",
	  imageUrl: require("../assets/faculty/NIET/PREETI SAHOO.png"),
	},
	{
	  email: "bijayalaxmisingh3@gmail.com",
	  name: "Bijayalaxmi singh",
	  designation: "Office Assistant",
	  department: "Administration",
	  phone: 9861007833,
	  areaOfInterest: "NA",
	  imageUrl: require("../assets/faculty/NIET/Bijayalaxmi singh.png"),
	},
	{
	  email: "somanath.itipuri@gmail.com",
	  name: "SOMANATHA JENA",
	  designation: "Lab Instructor",
	  department: "Mechanical Engineering",
	  phone: 6371691874,
	  areaOfInterest: "Chemical composition of lithium ion battery",
	  imageUrl: require("../assets/faculty/NIET/SOMANATHA JENA.png"),
	},
	{
	  email: "sasmitamishra.niet@gmail.com",
	  name: "Sasmita Mishra",
	  designation: "Assistant Librarian",
	  department: "Library",
	  phone: 9437757518,
	  areaOfInterest: "NA",
	  imageUrl: require("../assets/faculty/NIET/Sasmita Mishra.png"),
	},
	{
	  email: "abinashp753@gmail.com",
	  name: "Abinash Panda",
	  designation: "Assistant Librarian",
	  department: "Library",
	  phone: 6370959583,
	  areaOfInterest: "NA",
	  imageUrl: require("../assets/faculty/NIET/Abinash Panda.png"),
	},
	{
	  email: "mukteswarsahoo143@gmail.com",
	  name: "MUKTESWAR SAHOO",
	  designation: "Electrician",
	  department: "Administration",
	  phone: 6371965003,
	  areaOfInterest: "NA",
	  imageUrl: require("../assets/faculty/NIET/MUKTESWAR SAHOO.png"),
	},
	{
	  email: "",
	  name: "Rakesh Kumar Pradhan",
	  designation: "Office Assistant",
	  department: "Administration",
	  phone: 9776594284,
	  areaOfInterest: "NA",
	  imageUrl: require("../assets/faculty/NIET/Rakesh Kumar Pradhan.png"),
	},
	{
	  email: "",
	  name: "Silpa Sahoo",
	  designation: "Receptionist",
	  department: "Administration",
	  phone: 9853109486,
	  areaOfInterest: "NA",
	  imageUrl: require("../assets/faculty/NIET/Silpa Sahoo.png"),
	},
	{
	  email: "himanshusahoo57@gmail.com",
	  name: "Himanshu Sahoo",
	  designation: "Account Officer",
	  department: "Accounts",
	  phone: 9583128479,
	  areaOfInterest: "NA",
	  imageUrl: require("../assets/faculty/NIET/Himanshu Sahoo.png"),
	},
	{
	  email: "",
	  name: "Prafulla Jena",
	  designation: "Peon",
	  department: "Administration",
	  phone: 7440152057,
	  areaOfInterest: "NA",
	  imageUrl: require("../assets/faculty/NIET/Prafulla Jena.png"),
	},
	{
	  email: "",
	  name: "Ajay Muduli",
	  designation: "Supervisor",
	  department: "Administration",
	  phone: 7008563991,
	  areaOfInterest: "NA",
	  imageUrl: require("../assets/faculty/NIET/Ajay Muduli.png"),
	}
  ]