import React from "react";
import PageWrapper from "../../../components/PageWrapper/PageWrapper";
import HeroSection from "../../../components/HeroSection/HeroSection";
import HeroCaption from "../../../components/HeroCaption/HeroCaption";

import FacultyResponsive from "../../../components/FacultyResponsive/FacultyResponsive";
import CoursesResponsive from "../../../components/CoursesResponsive/CoursesResponsive";
import DepartmentInfoResponsive from "../../../components/DepartmentInfoResponsive/DepartmentInfoResponsive";
import DepartmentAboutInfoResponsive from "../../../components/DepartmentAboutInfoResponsive/DepartmentAboutInfoResponsive";
import HODResponsive from "../../../components/HODResponsive/HODResponsive";

const hodData = {
	imageUrl: "",
	designation: "Head of the Department",
	name: "Prof. Name",
	feedback: `Dear Students,
	As the Head of the Basic Sciences and Humanities Department at NIET, I am pleased to welcome you to our department. Our department is committed to providing you with a strong foundation in the basic sciences and humanities, which are essential for success in any field.
	Our faculty members are highly qualified and experienced in their respective fields and are dedicated to providing you with a challenging and engaging curriculum that covers a wide range of subjects, including mathematics, physics, chemistry, communication skills, and social sciences.
	We believe that a strong foundation in the basic sciences and humanities is essential for success in any profession, and we strive to provide you with the skills and knowledge you need to excel in your academic and professional pursuits.
	Our department is equipped with state-of-the-art facilities, including computer labs and libraries, to support your learning experience. We also provide opportunities for you to participate in co-curricular activities such as debates, quizzes, and cultural events, which help you to develop your communication and interpersonal skills.
	We are committed to providing you with a comprehensive education that prepares you for a successful career in your chosen field. We encourage you to take advantage of the resources and opportunities provided by our department and to work hard to achieve your academic and professional goals.
	I look forward to working with all of you and wish you the best of luck in your academic pursuits.
	Sincerely,
	
    `,
};

const BasicSciencesPage = () => {
	return (
		<PageWrapper className="mt-n6">
			<HeroSection className="bg-page-background-7">
				<HeroCaption
					title="Basic Sciences & Humanities"
					position="bottom-left"
					breadcrumb={true}
					breadcrumbPrefix="Home"
				/>
			</HeroSection>
			<DepartmentInfoResponsive
				title={"Basic Sciences & Humanities"}
				desc={`The Basic Sciences and Humanities Department at NIET plays a vital role in providing a strong foundation in the basic sciences and humanities to all students. Our department offers a range of courses that cover subjects such as mathematics, physics, chemistry, communication skills, and social sciences.

`}
			/>
			<HODResponsive hodData={hodData} />
			<DepartmentAboutInfoResponsive
				title={"About department"}
				desc={`The Basic Sciences and Humanities Department at NIET plays a vital role in providing a strong foundation in the basic sciences and humanities to all students. Our department offers a range of courses that cover subjects such as mathematics, physics, chemistry, communication skills, and social sciences.
				Our faculty members are highly qualified and experienced in their respective fields, and they are dedicated to providing students with a challenging and engaging curriculum. We believe that a strong foundation in the basic sciences and humanities is essential for success in any profession, and we strive to provide students with the skills and knowledge they need to excel in their academic and professional pursuits.
				Our department is equipped with state-of-the-art facilities, including computer labs and libraries, to support the learning experience. We also provide opportunities for students to participate in co-curricular activities such as debates, quizzes, and cultural events, which help them develop their communication and interpersonal skills.
				We are committed to providing a comprehensive education that prepares students for successful careers in their chosen fields. Our department strives to instill in students a passion for learning and a curiosity about the world around them. We encourage all students to take advantage of the resources and opportunities provided by our department and to work hard to achieve their academic and professional goals.
				
                
`}
			/>
		</PageWrapper>
	);
};

export default BasicSciencesPage;
