import React from "react";
import { AiFillFilePdf } from "react-icons/ai";
import CustomIcon from "../../components/CustomIcon/CustomIcon";
import PageHeader from "../../components/PageHeader/PageHeader";
import PageSection from "../../components/PageSection/PageSection";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import HeroSection from "../../components/HeroSection/HeroSection";
import HeroCaption from "../../components/HeroCaption/HeroCaption";
import AntiRagingResponsive from "../../components/AntiRagingResponsive/AntiRagingResponsive";

const AntiRaggingPolicyPage = () => {
	return (
		<PageWrapper className="mt-n6">
			<HeroSection className="bg-page-background-11">
				<HeroCaption
					title="Anti-Ragging Policy"
					position="bottom-left"
					breadcrumb={true}
					breadcrumbPrefix="Home"
				/>
			</HeroSection>
			<AntiRagingResponsive />
		</PageWrapper>
	);
};

export default AntiRaggingPolicyPage;
