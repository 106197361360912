import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import PageHeader from "../../components/PageHeader/PageHeader";
import PageSectionHeader from "../../components/PageSectionHeader/PageSectionHeader";
import PageSection from "../../components/PageSection/PageSection";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import HeroSection from "../../components/HeroSection/HeroSection";
import HeroCaption from "../../components/HeroCaption/HeroCaption";
import FacultyDevelopmentProgramResponsive from "../../components/FacultyDevelopmentProgram/FacultyDevelopmentProgramResponsive";
import FacultyDevelopmentProgramGallary from "../../components/FacultyDevelopmentProgramGallary/FacultyDevelopmentProgramGallary";
import DreamFestResponsive from "../../components/DreamFestResponsive/DreamFestResponsive";
import DreamFestGallary from "../../components/DreamFestGallary/DreamFestGallary";

const DreamFestPage = () => {
	return (
		<PageWrapper className="mt-n6">
			<HeroSection className="bg-page-background-dream-fest">
				<HeroCaption
					title="Dream Fest"
					position="bottom-left"
					breadcrumb={true}
					breadcrumbPrefix="Home"
				/>
			</HeroSection>
			<DreamFestResponsive />
			<DreamFestGallary />
		</PageWrapper>
	);
};

export default DreamFestPage;
