export const quickLinksData = [
  {
    title: "ABOUT US",
    link: "/about/about-us",
    imageUrl: require("../assets/quick-links/1.png"),
  },
  {
    title: "ACADEMICS",
    link: "/academics/courses",
    imageUrl: require("../assets/quick-links/2.png"),
  },
  {
    title: "Student’s Corner",
    link: "/student-corner/student-cell",
    imageUrl: require("../assets/quick-links/3.png"),
  },
  {
    title: "Social Media",
    link: "https://www.google.com/url?q=https://www.facebook.com/niisgrp&sa=D&source=docs&ust=1658054604145333&usg=AOvVaw2TUHi2oOQSWXFVW_pYtC27",
    external: true,
    imageUrl: require("../assets/quick-links/4.png"),
  },
];
