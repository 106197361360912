import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import PageSection from "../../components/PageSection/PageSection";
import PageSectionHeader from "../../components/PageSectionHeader/PageSectionHeader";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import PageHeader from "../../components/PageHeader/PageHeader";

const HostelPage = () => {
  return (
    <PageWrapper>
      <PageHeader title="Hostel" />
      <PageSection>
        <p>
          The Institute provides separate accommodation for both boys and girls
          within the campus. The campus incorporates cafeteria & canteen in
          close proximity to the departments, library and classrooms to
          facilitate easy access. All rooms are well-furnished and equipped with
          basic facilities. For availing such facilities the student must submit
          his preferences for accommodation early in the process of admission.
        </p>
      </PageSection>
      <PageSection bgClass="light">
        <PageSectionHeader title="Admission Criteria" leftStyle="bottom" />
        <div className="ml-2">
          <ul>
            <li>
              <p>
                The hostel admission is carried out on branch and merit basis
                and as per State Govt. reservation rules.
              </p>
            </li>
            <li>
              <p>
                Merit list will be prepared on the basis of marks with no
                backlog courses. The students with backlogs will be given a
                lower priority during the room allotment procedure.
              </p>
            </li>
            <li>
              <p>
                Hostel administration reserves the right to deny admission or
                cancel admission at any stage.
              </p>
            </li>
          </ul>
        </div>
      </PageSection>
      <PageSection>
        <PageSectionHeader title="Infrastructure" rightStyle="bottom" />
        <p>
          Currently the hostel block houses more than 200 students. All hostels
          comprise of main office, security room and a guest house. The two
          boys' blocks are called Balabhadra & Sudarshan and the girls block is
          Subhadra.
        </p>
      </PageSection>
      <PageSection bgClass="light">
        <PageSectionHeader title="Amenities" leftStyle="bottom" />
        <p>
          The students are provided with basic amenities like a cot, table and a
          cupboard in their respective rooms. The rooms are spacious and airy
          with a full view of the lush greenery outside the block perimeters.
          All Common Rooms are provided with TV sets, carrom boards and other
          recreational and amusement facilities. The hostels have a 24 hr. water
          supply and a generator to ensure constant power supply to Messes and
          Common Rooms. The sports grounds are situated at a close distance to
          encourage students to stay fit by regularly playing cricket, hockey,
          volley ball, football, basket ball, tennis or by taking a simple
          refreshing run along the tracks. Hostel campus maintenance like
          cleaning/sweeping, pest control is outsourced. Electrical repairs and
          security services are available round the clock.
        </p>
      </PageSection>
      <PageSection>
        <PageSectionHeader title="Medical Facilities" rightStyle="bottom" />
        <p>
          TMedical facilities provided to the hostel boarders as per their
          request in consultation with hostel warden. NIIS incorporates a
          dispensary block within the hostel premises with separate visiting
          doctors for both boys and girls. The centre is attended by a part time
          Specialists in general medicine. Consultations from the dispensary is
          available free of cost to all students.
        </p>
      </PageSection>
      <PageSection bgClass="light">
        <PageSectionHeader title="Other Facilities" leftStyle="bottom" />
        <div className="ml-2">
          <ul>
            <li>
              <p>Proximity of other hospitals within 10km from the campus.</p>
            </li>
            <li>
              <p>The institute provides vehicles to avail such facilities.</p>
            </li>
            <li>
              <p>
                Emergency mobile van to ensure timely healthcare services to the
                students residing in hostels.
              </p>
            </li>
          </ul>
        </div>
      </PageSection>
      <PageSection>
        <PageSectionHeader title="Gallery" rightStyle="bottom" />
        <Row className="justify-content-center">
          <Col md="6" className="mb-4">
            <Image
              src={
                require("../../assets/hostel/hostel-1.jpg").default
              }
              className="img-fluid rounded"
            />
          </Col>
          <Col md="6" className="mb-4">
            <Image
              src={
                require("../../assets/hostel/hostel-2.jpg").default
              }
              className="img-fluid rounded"
            />
          </Col>
          <Col md="6" className="mb-4">
            <Image
              src={
                require("../../assets/hostel/hostel-3.jpg").default
              }
              className="img-fluid rounded"
            />
          </Col>
        </Row>
      </PageSection>
    </PageWrapper>
  );
};

export default HostelPage;
