import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import PageHeader from "../../components/PageHeader/PageHeader";
import PageSectionHeader from "../../components/PageSectionHeader/PageSectionHeader";
import PageSection from "../../components/PageSection/PageSection";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import SeminarResponsive from "../../components/SeminarResponsive/SeminarResponsive";
import SeminarGallaryResponsive from "../../components/SeminarGallaryResponsive/SeminarGallaryResponsive";

import HeroSection from "../../components/HeroSection/HeroSection";
import HeroCaption from "../../components/HeroCaption/HeroCaption";

const SeminarPage = () => {
	return (
		<PageWrapper className="mt-n6">
			<HeroSection className="bg-page-background-seminar">
				<HeroCaption
					title="Seminars"
					position="bottom-left"
					breadcrumb={true}
					breadcrumbPrefix="Home"
				/>
			</HeroSection>
			<SeminarResponsive />
			<SeminarGallaryResponsive />
			{/* <PageHeader title="Seminars" /> */}
			{/* <PageSection>
        <p>
          Seminars are always considered as windows to the current leads of
          exposure to trends, techniques and tactics. With our profound guest
          lectures and seminars, we aim to imbibe in students the current
          world’s techniques and practices to transform them into dynamic
          persons.
        </p>
        <p>
          Periodic seminars and lectures are conducted on various topics of
          academic and employment interest by academicians, industrialists and
          practicing professionals to make the students aware of various
          concepts, ideas, practices and latest developments in IT and
          management sector, with the scope for interaction with the presentator
          for development and success in professional career.
        </p>
      </PageSection>
      <PageSection bgClass="light">
        <PageSectionHeader title="Gallery" rightStyle="bottom" />
        <Row className="justify-content-center">
          <Col md="6">
            <Image
              src={
                require("../../assets/seminar-hall/sem1.JPG")
                  .default
              }
              className="img-fluid rounded mb-4"
            />
          </Col>
          <Col md="6">
            <Image
              src={
                require("../../assets/seminar-hall/sem2.JPG")
                  .default
              }
              className="img-fluid rounded mb-4"
            />
          </Col>
          <Col md="6">
            <Image
              src={
                require("../../assets/seminar-hall/sem3.JPG")
                  .default
              }
              className="img-fluid rounded mb-4"
            />
          </Col>
        </Row>
      </PageSection> */}
		</PageWrapper>
	);
};

export default SeminarPage;
