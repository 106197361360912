import React, { useState } from "react";
import { Form, Row, Col, Button } from "react-bootstrap";
import { db } from "../../services/firebase";

const AdmissionEnquiryForm = () => {
  const [enquiryForm, setEnquiryForm] = useState({});

  const handleChange = (event) => {
    const { name, value } = event.target;

    setEnquiryForm({ ...enquiryForm,[name]: value });
  };

  const handleSubmit = async (event) => {
      event.preventDefault();
      const enquiryRef = db.collection('inquiriesBA'); 

    try {
      await enquiryRef.add({
        ...enquiryForm
      });

      setEnquiryForm({});
      alert('From submitted');
      window.location.reload();

    } catch (err) {
      alert(err);
    }
  }


  return (
    <Form onSubmit={handleSubmit}>
      <Row>
        <Col md="6">
          <Form.Group controlId="info-science-enquiry-name">
            <Form.Label>Name*</Form.Label>
            <Form.Control
              type="text"
              placeholder="Enter Name"
              required
              className="shadow-sm"
              name="name"
              onChange={handleChange}
            />
          </Form.Group>
        </Col>
        <Col md="6">
          <Form.Group controlId="info-science-enquiry-email">
            <Form.Label>Email*</Form.Label>
            <Form.Control
              type="email"
              placeholder="Enter Email"
              required
              className="shadow-sm"
              name="email"
              onChange={handleChange}
            />
          </Form.Group>
        </Col>
        <Col md="6">
          <Form.Group controlId="info-science-enquiry-phone">
            <Form.Label>Phone Number*</Form.Label>
            <Form.Control
              type="tel"
              size="12"
              placeholder="Enter Phone number"
              pattern="[0-9]{10,12}"
              required
              className="shadow-sm"
              name="phoneNumber"
              onChange={handleChange}
            />
          </Form.Group>
        </Col>
        <Col md="6">
          <Form.Group controlId="info-science-enquiry-course">
            <Form.Label>Select a course*</Form.Label>
            <Form.Control
              as="select"
              required
              className="shadow-sm"
              name="course"
              onChange={handleChange}
            >
              <option value="">Select courses</option>
              <option value="MCA">MCA</option>
              <option value="MBA">MBA</option>
            </Form.Control>
          </Form.Group>
        </Col>
        <Col>
          <Form.Group controlId="info-science-enquiry-content">
            <Form.Label>Write to us*</Form.Label>
            <Form.Control
              as="textarea"
              rows={5}
              required
              placeholder="Write your query here"
              className="shadow-sm"
              name="message"
              onChange={handleChange}
            />
          </Form.Group>
        </Col>
      </Row>
      <Button type="submit">Submit</Button>
    </Form>
  );
};

export default AdmissionEnquiryForm;
