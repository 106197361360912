import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";
import PageSection from "../PageSection/PageSection";
import { Swiper, SwiperSlide } from "swiper/react";
import TrusteeMessageCard from "../TrusteeMessageCard/TrusteeMessageCard";
import { trusteeData } from "../../static-data/trustee.data";

const TrusteeResponsive = () => {
	const [currentBreakPoint, setCurrentBreakPoint] = useState("");

	useEffect(() => {
		bsBreakpoints.init();
		setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

		const handleBreakPoint = (event) => {
			setCurrentBreakPoint(event.detail);
		};

		window.addEventListener("new.bs.breakpoint", handleBreakPoint);

		return () => {
			window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
		};
	}, []);

	const swiperContainerClasses = classNames("position-relative ", {
		"mt-5": currentBreakPoint === "small" || currentBreakPoint === "xSmall",
	});

	const paginationClasses = classNames(
		"trustee-feedback-pagination  swiper-pagination-container swiper-pagination-container--horizontal swiper-pagination-container--horizontal---right swiper-pagination-container--top"
	);

	return (
		<PageSection bgClass="white">
			<div className={swiperContainerClasses}>
				<Swiper
					grabCursor={true}
					direction="horizontal"
					scrollbar={{ el: "", draggable: true, hide: true }}
					slidesPerView={
						currentBreakPoint === "small" || currentBreakPoint === "xSmall"
							? 1
							: 1
					}
					spaceBetween={20}
					loop={true}
					autoplay={{ delay: 3000 }}
					pagination={{
						el: ".trustee-feedback-pagination",
						clickable: true,
					}}
				>
					{trusteeData.map((data, index) => (
						<SwiperSlide key={`trustee-feedback-home${index}`}>
							<TrusteeMessageCard titlePrefix="From Our" {...data} />
						</SwiperSlide>
					))}
				</Swiper>
				<div className={paginationClasses}></div>
			</div>
		</PageSection>
	);
};

export default TrusteeResponsive;
