import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import PageHeader from "../../components/PageHeader/PageHeader";
import PageSection from "../../components/PageSection/PageSection";
import PageSectionHeader from "../../components/PageSectionHeader/PageSectionHeader";

const TransportationPage = () => {
  return (
    <PageWrapper>
      <PageHeader title="Transport Facility" />
      <PageSection>
        <p>
          NIIS MBA college in bhubaneswar runs a fleet of busses to help
          students and staff members commute to and fro from regions around the
          city. The bus service is available over a 25km radius around the
          institute. A nominal fee is collected for the transportation on a
          yearly basis. Details of the routes and their respective timings are
          also available. This service is offered to provide hassle-free and
          safe transportation. Parents are urged to convince their wards to
          travel in the college busses only in view of safety issues on the
          highway. We also provide special pre-arranged transportation services
          for field trips and sporting events within the state. For the
          hostelites, they have the provisions to go out to market or other
          hangouts in the city. This bus operates in all the seven days of week
          as per a schedule notified from time to time. Transportation facility
          is also available every time for any kind of emergency. We are also
          planning to extend bus services to Puri & Cuttack if number of day
          scholars exceeds & demand is generated.
        </p>
      </PageSection>
      <PageSection bgClass="light">
        <PageSectionHeader title="Route: 01" leftStyle="bottom" />
        <p>
          Mancheswar , V.S.S.Nagar , Rasulgarh , Bomikhal , Laxmi Sagar ,
          Chintamaniswar , Kalpana , Ravi Takies , Oldtown , Forestpark ,
          Airport Area , Pokhariput , Jagamara, Khandagiri & Madanpur.
        </p>
      </PageSection>
      <PageSection>
        <PageSectionHeader title="Route: 02" rightStyle="bottom" />
        <p>
          Mancheswar , V.S.S.Nagar , Palasuni , GGP Colonyl , Satya sai temple
          ,Laxmis agar, Chintamaniswar , Kalpana , Mausima Chak , Sisubhaban ,
          Delta , Fire Station , Khandagiri & Madanpur.
        </p>
      </PageSection>
      <PageSection bgClass="light">
        <PageSectionHeader title="Route: 03" leftStyle="bottom" />
        <p>
          Mancheswar ,V.S.S Nagar,Railway Colony, Omfed Chak , Chandrasekharpur
          , Sailashree Vihar , DAV C.S.Pur , Damana , Kiit Square , Nalco Chhak
          , Acahraya Vihar , Niccopark , Jayadev Vihar , CRP Chhak , Baramunda,
          Khandagiri & Madanpur.
        </p>
      </PageSection>
      <PageSection>
        <PageSectionHeader title="Route: 04" rightStyle="bottom" />
        <p>
          Mancheswar ,V.S.S Nagar,Rasulgarh , Vani Vihar , Acharya Vihar ,
          Nayapalli , CRP Chhak , Fire Station , Baramunda, Khandagiri
          ,Patrapada & Madanpur.
        </p>
      </PageSection>
      <PageSection bgClass="light">
        <PageSectionHeader title="Route: 05" leftStyle="bottom" />
        <p>
          Mancheswar ,V.S.S Nagar,Vani Vihar ,Ram Mandir,Raj Mahal, AG Chak
          ,UNIT-4, Sastri Nagar, Power House Chak, Dav School Unit-8
          ,Delta,Khandagiri,Kalinga Nagar & Madanpur.
        </p>
      </PageSection>
      <PageSection>
        <PageSectionHeader title="Gallery" rightStyle="bottom" />
        <Row className="justify-content-center">
          <Col md="6" className="mb-4">
            <Image
              src={
                require("../../assets/transportation/transport-1.jpg").default
              }
              className="img-fluid rounded"
            />
          </Col>
        </Row>
      </PageSection>
    </PageWrapper>
  );
};

export default TransportationPage;
