import React from "react";
import PageHeader from "../../components/PageHeader/PageHeader";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import PageSection from "../../components/PageSection/PageSection";
import HeroSection from "../../components/HeroSection/HeroSection";
import HeroCaption from "../../components/HeroCaption/HeroCaption";
import GuidelineResponsive from "../../components/GuidelineResponsive/GuidelineResponsive";
import GuidelineContentResponsive from "../../components/GuidelineContentResponsive/GuidelineContentResponsive";
import NAACResponsive from "../../components/NAACResponsive/NAACResponsive";

const NAACPage = () => {
	return (
		<PageWrapper className="mt-n6">
			<HeroSection className="bg-page-background-4">
				<HeroCaption
					title="NAAC"
					position="bottom-left"
					breadcrumb={true}
					breadcrumbPrefix="Home"
				/>
			</HeroSection>
			<NAACResponsive />
		</PageWrapper>
	);
};

export default NAACPage;
