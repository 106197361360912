import React from "react";
import PageHeader from "../../components/PageHeader/PageHeader";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import PageSection from "../../components/PageSection/PageSection";
import PageSectionHeader from "../../components/PageSectionHeader/PageSectionHeader";

const ClassRoomPage = () => {
  return (
    <PageWrapper>
      <PageHeader title="Classroom" />
      <PageSection>
        <p>
          Classrooms at NIIS are equipped with the latest in educational
          technology and software, from interactive whiteboards and data
          projectors, to plasma screens and control panels. We also aim to
          support your use of this equipment with expert advice, and the latest
          technical updates. The classroom are equipped with Modern facilitate
          for effective learning. NIIS facilitates its students with state of
          art classrooms & ideal learning atmosphere.
        </p>

        <PageSectionHeader
          title="The main features of the classrooms are:"
          type="h5"
        />
        <p>
          Fully Air Conditioned, Audio & Visual Aids, Lecture Recording, Sound
          proof, Maximum Interaction, Laptop Connectivity, Liquid Crystal
          Display, Over Head Projectors.
        </p>
      </PageSection>
      <PageSection bgClass="light">
        <PageSectionHeader title="Gallery" leftStyle="bottom" />
        <p>
            Comming Soon
        </p>
      </PageSection>
    </PageWrapper>
  );
};

export default ClassRoomPage;
