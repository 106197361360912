import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";
import { Image } from "react-bootstrap";

import "./electivesCard.style.scss";

const ElectivesCard = ({
  iconUrl,
  imageUrl,
  title,
  overlayType,
  electivesList,
}) => {
  const [currentBreakPoint, setCurrentBreakPoint] = useState("");

  useEffect(() => {
    bsBreakpoints.init();
    setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

    const handleBreakPoint = (event) => {
      setCurrentBreakPoint(event.detail);
    };

    window.addEventListener("new.bs.breakpoint", handleBreakPoint);

    return () => {
      window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
    };
  }, []);

  const electivesCardClasses = classNames(
    "elective-card",
    {
      [`overlay-${overlayType}`]: overlayType,
    },
    {
      "p-4": currentBreakPoint === "xSmall" || currentBreakPoint === "small",
    }
  );

  const listClasses = classNames({
    "font-sm": currentBreakPoint === "xSmall" || currentBreakPoint === "small",
  });

  return (
    <div className={electivesCardClasses}>
      <Image
        src={imageUrl.default ? imageUrl.default : imageUrl}
        alt={title}
        className="elective-card--image"
      />
      <div className="elective-card-content">
        <Image
          src={iconUrl.default ? iconUrl.default : iconUrl}
          alt={title}
          className="img-fluid elective-card-content--logo"
        />
        <h5 className="font-weight-bold text-uppercase text-highlight-color">
          {title}
        </h5>
        <ul className="list-style-white text-white mb-0">
          {electivesList.map((elective, index, arr) => (
            <li key={`elective-list-${index}`}>
              <p
                className={`${listClasses} ${
                  index === arr.length - 1 ? "mb-0" : "mb-2"
                }`}
              >
                {elective}
              </p>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default ElectivesCard;
