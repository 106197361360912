import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";
import { AiFillFilePdf } from "react-icons/ai";
import CustomIcon from "../CustomIcon/CustomIcon";
import PageSection from "../PageSection/PageSection";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";

const RecruiterAndPlacementResponsive = () => {
	const [currentBreakPoint, setCurrentBreakPoint] = useState("");

	useEffect(() => {
		bsBreakpoints.init();
		setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

		const handleBreakPoint = (event) => {
			setCurrentBreakPoint(event.detail);
		};

		window.addEventListener("new.bs.breakpoint", handleBreakPoint);

		return () => {
			window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
		};
	}, []);

	const descriptionClasses = classNames("mb-4 text-center  text-white", {
		"font-sm": currentBreakPoint === "xSmall" || currentBreakPoint === "small",
	});

	return (
		<PageSection bgClass="page-background-9">
			<ResponsivePageHeader
				colorClass="text-white"
				centered={true}
				title="Our recruiters & Placement Records"
			/>
			<p className={descriptionClasses}>
				Over the years, we have established proud endeavours in the forms of our
				great alumni achiever students who have been placed into some of the
				most prestigious companies pan India. Tied up with groundbreaking and
				progressing companies at all times, it gives us an absolute pleasure to
				present to you- our most trusted and promising companies joining us for
				placement ventures throughout these fruitful years!
			</p>
			<a
				href={process.env.PUBLIC_URL + "/NIIS_Placement_Brochure.pdf"}
				className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
				target="_blank"
				rel="noopener noreferrer"
			>
				<CustomIcon currentIcon={AiFillFilePdf} size="md" />
				<span className="ml-2">DOWNLOAD PLACEMENT BROCHURE</span>
			</a>
		</PageSection>
	);
};

export default RecruiterAndPlacementResponsive;
