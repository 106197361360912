import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";
import { AiFillFilePdf } from "react-icons/ai";
import PageSection from "../PageSection/PageSection";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";
import CustomIcon from "../CustomIcon/CustomIcon";

const AntiRagingResponsive = () => {
	const [currentBreakPoint, setCurrentBreakPoint] = useState("");

	const descriptionClasses = classNames("text-center mb-4 text-text-color", {
		"font-sm": currentBreakPoint === "xSmall" || currentBreakPoint === "small",
	});

	useEffect(() => {
		bsBreakpoints.init();
		setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

		const handleBreakPoint = (event) => {
			setCurrentBreakPoint(event.detail);
		};

		window.addEventListener("new.bs.breakpoint", handleBreakPoint);

		return () => {
			window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
		};
	}, []);

	return (
		<div>
			<PageSection bgClass="background-color">
				<ResponsivePageHeader centered={true} title="Anti-Ragging Policy" />
				<p style={{ textAlign: "left" }}>
					{/* Parents put an immense trust and faith in the hands of the institute
				while sending their children to us. We always try to do right by it and
				carry concern at all times for the physical and mental stability of the
				child. We have drafted the anti-ragging policy for the same reasons-so
				that no student should be disrupted any way by another student or staff.
				We urge the students to report any unwanted activity to the management
				without any fear and they are dealt with utmost strictness and
				responsibility. */}
					Ragging or harassment of fresher’s by senior students of the Institute
					in any form is strictly prohibited. This should not be treated as a
					mere statutory warning which will invite lot of trouble for erring
					students.
				</p>
				<p>Ragging is defined as :</p>
				<ul>
					<li>
						Forcing a student to perform some action against his / her will,
					</li>
					<li>Physical violence of any kind against the student,</li>
					<li>Any kind of mental harassment of intimidation</li>
				</ul>
				<p>
					A complaint of ragging must be submitted in writing to the Principal
					or The Chairman of the Anti-Ragging Committee. · Anybody found
					indulging in the act of ragging or reported for indulging in ragging
					will be liable to severe punishment. Such students may be expelled
					from the Institute by the Principal and the case will be reported to
					the local police station for necessary action in view of the directive
					of the Honourable Supreme Court of India
				</p>
				<p>
					Ragging of any sort is strictly prohibited. As per the order of the
					Hon’ble Supreme Court of India order, ragging is a cognizable offense
					under law and may invite punishment of imprisonment. Each student has
					to sign an Affidavit in prescribed format that he/she was aware of
					penal and administrative action to be taken against them for being
					involved in ragging as per directive of Hon’ble Supreme Court of
					India.
				</p>
				{/* <p className={descriptionClasses}>
				We make sure that a student stays fit and of sound mind all the time.
			</p> */}
				{/* <a
				href="#"
				className="d-flex align-items-center justify-content-center text-uppercase text-center text-link-color font-weight-medium font-sm font-paragraph-font"
			>
				<CustomIcon currentIcon={AiFillFilePdf} size="md" />
				<span className="ml-2">DOWNLOAD ANTI-RAGGING POLICY</span>
			</a> */}
			</PageSection>
			<PageSection bgClass="white">
				<ResponsivePageHeader centered={true} title="Anti-Ragging Action" />
				<p>
					The students having any complaint regarding ragging may submit an
					application to the Chairman or any member of Anti-ragging Grievance
					Redressal Committee. The Anti-ragging Committee consists of the
					following members:
				</p>
				<ol>
					<li> Dr. Rajesh Kumar Sain</li>
					<li> Prof. B. S. Rath,</li>
					<li> Dr. D.K. Pattanaik</li>
					<li> Prof. Pravakar Mishra, </li>
					<li> Prof. S.D. Samal </li>
					<li> Prof. Suryakanta Mohapatra, </li>
					<li> Prof Vinita Debyani Mishra </li>
					<li> Prof. Lipika Agasti </li>
					<li> Prof. Santosh Kumar Sahu </li>
					<li> Mr. Pitamar Chayani </li>
					<li> Mr. Kuber Biswal</li>
					<li> Mr. Nihar Ranjan Parida</li>
					<li> Prof. Satyajit Pattaniak </li>
					<li> Ms. Jogamaya Pattanaik </li>
					<li> Students’ Representative </li>
					<li> Parents</li>
					<li> Two nos. students</li>
				</ol>
				<p>
					Any ragging incident taking place outside the institute and
					subsequently reported by an insider or outsider will also be regarded
					as ragging, and action will be taken as deemed fit. Further, it may be
					noted that a ragging incident may be reported directly to the Police
					Station or to grievance cell operating directly under the Govt. or
					Judiciary.
					<br />
					National Anti-Ragging Helpline (UGC Crisis Hotline) – 24 x 7 Toll Free
					Number* 1800-180-5522 | helpline@antiragging.in
				</p>
			</PageSection>
			<PageSection bgClass="background-color">
				<ResponsivePageHeader centered={true} title="Anti-Ragging Squad" />
				<p>
					As per the directive of Hon’ble Supreme Court of India, the following
					persons are nominated as members of Anti-ragging Squad of NIIS and
					they will watch both inside and outside the institute premises
					including hostels to prevent ragging in any form and they will submit
					findings to the Chairman, Anti-ragging Committee for further action.
					In case of any harassment by senior students, the affected students of
					the junior batch may report to the squad at first instance.
				</p>
				<ol>
					<li>Dr. Ajit Narayan Mohanty</li>
					<li>Prof. Sradhanjali Nayak,</li>
					<li>Prof. Satyajit Pattnaik</li>
					<li>Prof. Gyana Ranjan Biswal</li>
					<li>Prof. Chinmay Kumar Rout</li>
					<li>Prof. Ajit Kumar Sabat</li>
					<li>Prof. Uttam Kumar Giri</li>
					<li>Prof. Lipika Agasti</li>
					<li>Prof. S. D. Samal</li>
					<li>Prof. Indrajit Das</li>
					<li>Ms. Darshana Pattnaik</li>
				</ol>
				<p>
					Anti-Ragging squad will investigate incidents of Ragging within or
					outside institute campus as well as at Boys’ Hostels and Girls’
					Hostel. The report may be submitted to the Principal-Cum-Chairman of
					the Anti-ragging Committee recommending necessary action.
				</p>
			</PageSection>
		</div>
	);
};

export default AntiRagingResponsive;
