import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";
import { AiFillFilePdf } from "react-icons/ai";
import CustomIcon from "../CustomIcon/CustomIcon";
import PageSection from "../PageSection/PageSection";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";

const ResearchInfoResponsive = () => {
	const [currentBreakPoint, setCurrentBreakPoint] = useState("");

	const descriptionClasses = classNames("mb-4 text-center text-text-color", {
		"font-sm": currentBreakPoint === "xSmall" || currentBreakPoint === "small",
	});

	useEffect(() => {
		bsBreakpoints.init();
		setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

		const handleBreakPoint = (event) => {
			setCurrentBreakPoint(event.detail);
		};

		window.addEventListener("new.bs.breakpoint", handleBreakPoint);

		return () => {
			window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
		};
	}, []);

	return (
		<PageSection bgClass="background-color">
			<ResponsivePageHeader centered={true} title="Research" />
			<p className={descriptionClasses}>
				NIIS has a well-established research centre that offers numerous
				opportunities for students, scholars, and researchers. The research
				centre aims to promote and encourage research and development in various
				fields, including engineering, management, and technology. The centre is
				equipped with the latest tools and technologies to support research
				activities and provide a conducive environment for scholars to conduct
				their research. The research centre provides a platform for students to
				engage in research and development activities, which can contribute to
				their academic growth and also provide valuable insights into real-world
				problems. The centre also offers several opportunities for students to
				collaborate with industry experts, academics, and researchers, which can
				help them gain valuable experience and enhance their skills. The
				research centre at NIIS also conducts various workshops, seminars, and
				training programs to enhance the research capabilities of students,
				faculty members, and researchers. The centre has a dedicated team of
				experienced and knowledgeable professionals who provide guidance and
				support to students and researchers throughout their research journey.{" "}
				<br />
				In addition to promoting research and development, the centre also
				focuses on providing solutions to real-world problems. The research
				centre collaborates with various industries, government agencies, and
				NGOs to find innovative solutions to complex problems. <br />
				Overall, the research centre at NIIS is a vibrant and dynamic platform
				that provides an excellent opportunity for students and researchers to
				explore and excel in their respective fields. With its state-of-the-art
				facilities and a team of experienced professionals, the centre is
				committed to fostering a culture of innovation, excellence, and research
			</p>
			<a
				href={process.env.PUBLIC_URL + "/RESEARCHPOLICY.docx"}
				className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
				target="_blank"
				rel="noopener noreferrer"
			>
				<CustomIcon currentIcon={AiFillFilePdf} size="md" />
				<span className="ml-2">DOWNLOAD RESEARCH POLICY</span>
			</a>
		</PageSection>
	);
};

export default ResearchInfoResponsive;
