import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import PageWrapper from '../../components/PageWrapper/PageWrapper';
import PageHeader from '../../components/PageHeader/PageHeader';
import PageSection from '../../components/PageSection/PageSection';
import PageSectionHeader from '../../components/PageSectionHeader/PageSectionHeader';

const ComputerLabPage = () => {
  return (
    <PageWrapper>
      <PageHeader title="Computer Lab" />
      <PageSection>
        <p>
          The air conditioned computer laboratory is fully networked with its
          required softwares as needed by the students of Management & Computer
          Science. The Internet facilities are provided to the students to know
          the World -Wide Web (WWW) and gaining knowledge on different issues in
          regular basis. The Internet connection is provided by Reliance
          Broadband through its 4mbps leased line to access data in faster
          manner and shows the differences how to work in the corporate firms
          with speed, accuracy and quality.
        </p>

        <p>
          CoreT i5-2328M CPU 32, 2.20GHz, Intel Original Desktop board DG33,
          256MB Graphics Card, 250GB SATA 2GB RAM DDR2,ATX Cabinet, Keyboard,
          Optical Mouse and TFT monitor. The Institution has requisite
          application and system softwares with Anti-Virus package as per need
          of lab.
        </p>
      </PageSection>
      <PageSection bgClass="light">
        <PageSectionHeader title="Gallery" leftStyle="bottom" />
        <Row className="justify-content-center">
          <Col md="6" className="mb-4">
            <Image
              src={
                require("../../assets/computer-lab/computer-lab-1.jpg").default
              }
              className="img-fluid rounded"
            />
          </Col>
          <Col md="6" className="mb-4">
            <Image
              src={
                require("../../assets/computer-lab/computer-lab-2.jpg").default
              }
              className="img-fluid rounded"
            />
          </Col>
          <Col md="6" className="mb-4">
            <Image
              src={
                require("../../assets/computer-lab/computer-lab-3.jpg").default
              }
              className="img-fluid rounded"
            />
          </Col>
        </Row>
      </PageSection>
    </PageWrapper>
  );
};

export default ComputerLabPage;
