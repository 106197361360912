import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import { AiFillFilePdf } from "react-icons/ai";
import CustomIcon from "../../components/CustomIcon/CustomIcon";
import PageHeader from "../../components/PageHeader/PageHeader";
import PageSection from "../../components/PageSection/PageSection";
import PageSectionHeader from "../../components/PageSectionHeader/PageSectionHeader";
import PageWrapper from "../../components/PageWrapper/PageWrapper";

const CommunityServicePage = () => {
	return (
		<PageWrapper>
			<PageHeader title="Community Services" />

			<PageSection>
				<p>
					Community service gives students the chance to get involved in their
					neighborhood and has a long-lasting, beneficial effect on society as a
					whole. Students can learn valuable life skills and knowledge through
					community service or volunteerism while also helping others in need.
					The National Service Scheme (NSS), a service programme offered by NIIS
					that is focused on the needs of its students, is an addition to
					education. This programme is an admirable example of academic
					extension that inculcates a spirit of volunteerism in both students
					and teachers through engagement with the local community. This project
					is a government-sponsored initiative run by the Department of Youth
					Affairs and Sports in India. The plan was formally introduced on
					September 24, 1969. More than 3 Crore kids have benefited from this
					extensive student volunteer programme so far during its 45 years of
					fruitful existence.
				</p>
				<a
					href={process.env.PUBLIC_URL + "/RESEARCHPOLICY.docx"}
					className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
					target="_blank"
					rel="noopener noreferrer"
				>
					<CustomIcon currentIcon={AiFillFilePdf} size="md" />
					<span className="ml-2">
						DOWNLOAD Certificate of Registration of Societies
					</span>
				</a>
			</PageSection>
			<PageSection bgClass="light">
				<PageSectionHeader title="Gallery" leftStyle="bottom" />
				<Row className="justify-content-center">
					<Col md="6" className="mb-4">
						<Image
							src={
								require("../../assets/community-service/community-service-1.jpg")
									.default
							}
							className="img-fluid rounded"
						/>
					</Col>
				</Row>
			</PageSection>
		</PageWrapper>
	);
};

export default CommunityServicePage;
