import React from "react";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import HeroSection from "../../components/HeroSection/HeroSection";
import HeroCaption from "../../components/HeroCaption/HeroCaption";
import ProgramDescriptionResponsive from "../../components/ProgramDescriptionResponsive/ProgramDescriptionResponsive";
import ResourcesResponsive from "../../components/ResourcesResponsive/ResourcesResponsive";
import MBAChoiceOfElectivesResponsive from "../../components/MBAChoiceOfElectivesResponsive/MBAChoiceOfElectivesResponsive";

const DiplomaProgramPage = () => {
	return (
		<PageWrapper className="mt-n6">
			<HeroSection className="bg-page-background-7">
				<HeroCaption
					title="Academics"
					position="bottom-left"
					breadcrumb={true}
					breadcrumbPrefix="Home"
				/>
			</HeroSection>
			<ProgramDescriptionResponsive
				title="Diploma Courses"
				desc="At NIET, we also offer diploma courses in various engineering disciplines. Our diploma courses are designed to provide students with a comprehensive understanding of the subject matter, as well as hands-on training in our well-equipped labs and workshops. Graduates of our diploma programs are well-equipped to pursue careers in their chosen fields or to continue their education with a higher degree.
				"
			/>
			{/* <MBAChoiceOfElectivesResponsive /> */}
			{/* <ResourcesResponsive headerContent={""} /> */}
		</PageWrapper>
	);
};

export default DiplomaProgramPage;
