export const navbarData = [
	{
		title: "Infrastructure",
		link: "/campus",
	},
	// {
	// 	title: "Campus Activities",
	// 	link: `/campus-activities`,
	// 	subLinks: [
	// 		{ title: "Dream Fest", link: "/dream-fest" },
	// 		{
	// 			title: "Faculty Development Program",
	// 			link: "/faculty-development-program",
	// 		},
	// 		{ title: "Seminars", link: "/seminars" },
	// 		{ title: "CSR & Sustainability", link: "/csr-&-sustainability" },
	// 		{ title: "Commerce Conclave", link: "/commerce-conclave" },
	// 	],
	// },
	{
		title: "Student's Corner",
		link: `/student-corner`,
		subLinks: [
			{ title: "Guidelines", link: "/guidelines" },
			{ title: "Anti-Ragging Cell", link: "/anti-ragging-policy" },
			{ title: "Clubs & Committees", link: "/clubs-committees" },
			{title: "Grievance Cell", link: "/grievance-cell"},
			// {
			//   title: "Events",
			//   link: "/",
			// },
		],
	},
	// {
	// 	title: "NAAC",
	// 	link: `/naac`,
	// 	internal: true,
	// },
	{
		title: "Enquire Now",
		link: `/#enquiry`,
		internal: true,
	},
	{
		title: "Home",
		leftMargin: true,
		mobileStart: true,
		link: "/",
	},
	{
		title: "About Us",
		link: "/about-us",
		subLinks: [
			{ title: "About NIET", link: "/about-niet" },
			{
				title: "Leadership & Management ",
				link: "/leadership-&-management",
			},
		],
	},

	{
		title: "Academics",
		link: "/academics",
		subLinks: [
			{ title: "Programs", link: "/programs" },
			// { title: "Academic Calenders", link: "/courses" },
			{ title: "Faculty", link: "/faculty" },
			{ title: "Research", link: "/research" },
			{ title: "Library & Resources ", link: "/library-resources" },
			{ title: "Affiliations", link: "/affiliations"},
		],
	},
	{
		title: "Departments",
		link: "/departments",
		subLinks: [
			{ title: "Computer Science", link: "/computer-science" },
			{ title: "Electrical & Electronics", link: "/electrical-&-electronics" },
			{
				title: "Electrical & Telecommunication ",
				link: "/electrical-&-telecommunication",
			},
			// { title: "Electrical", link: "/electrical" },
			{ title: "Mechanical", link: "/mechanical" },
			{ title: "Civil", link: "/civil" },
			{
				title: "Basic Sciences & Humanities",
				link: "/basic-sciences-&-humanities",
			},
			// {
			//   title: "Events",
			//   link: "/",
			// },
		],
	},
	{
		title: "Industry Interface Centre",
		link: "/industry-interface-centre",
	},
	{
		title: "Contact Us",
		link: "/contact-us",
	},
];

export const newLinks = [
	"/",
	"/about-us",
	"/about/academic-advisor",
	"/academics/courses",
	"/academics/courses/mba",
	"/industry-interface-centre",
];
