import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";

import PageSection from "../PageSection/PageSection";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";
import CustomIcon from "../CustomIcon/CustomIcon";
import { AiFillFilePdf } from "react-icons/ai";
import { Col, Row } from "react-bootstrap";
import CoursesMiniCard from "../CoursesMiniCard/CoursesMiniCard";
import { coursesData } from "../../static-data/courses.data";

const CoursesResponsive = () => {
	const [currentBreakPoint, setCurrentBreakPoint] = useState("");

	useEffect(() => {
		bsBreakpoints.init();
		setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

		const handleBreakPoint = (event) => {
			setCurrentBreakPoint(event.detail);
		};

		window.addEventListener("new.bs.breakpoint", handleBreakPoint);

		return () => {
			window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
		};
	}, []);

	const rowContainerClasses = classNames({
		"w-75 mx-auto": !(
			currentBreakPoint === "xSmall" || currentBreakPoint === "small"
		),
	});

	return (
		<PageSection type="lg" bgClass="page-background-1">
			<ResponsivePageHeader title="Explore Our Courses" />
			<div>
				<Row className="gx-1">
					{coursesData.map((data, index) => (
						<Col
							md="4"
							className="d-flex align-items-stretch mb-4 "
							key={"course-responsive" + index}
						>
							<CoursesMiniCard {...data} />
						</Col>
					))}
				</Row>
			</div>

			<br/>

			<a
					href={
						process.env.PUBLIC_URL + "/MANDATORY DISCLOSER_merged.pdf"
					}
					className="d-flex align-items-center justify-content-center text-uppercase text-center text-highlight-color text-highlight-color-hover text-highlight-color-active font-weight-medium font-sm font-paragraph-font"
					target="_blank"
					rel="noopener noreferrer"
				>
					<CustomIcon currentIcon={AiFillFilePdf} size="md" />
					<span className="ml-2">
						MANDATORY DISCLOSER
					</span>
			</a>
		</PageSection>
	);
};

export default CoursesResponsive;
