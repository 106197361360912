import React from "react";
import { Image } from "react-bootstrap";
import "./advisorMiniCard.style.scss";

const AdvisorMiniCard = ({ name, designation, content, imageUrl }) => {
  return (
    <div className="advisor-mini-card">
      <Image
        src={imageUrl.default ? imageUrl.default : imageUrl}
        alt={name}
        className="img-fluid advisor-mini-card--image"
      />
      <h6 className="advisor-mini-card--name">{name}</h6>
      <p className="advisor-mini-card--designation">{designation}</p>
      <p className="advisor-mini-card--content">{content}</p>
    </div>
  );
};

export default AdvisorMiniCard;
