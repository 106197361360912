import React from "react";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import HeroSection from "../../components/HeroSection/HeroSection";
import HeroCaption from "../../components/HeroCaption/HeroCaption";
import InfrastructreResponsive from "../../components/InfrastructureResponsive/InfrastructureResponsive";
import PageHeader from "../../components/PageHeader/PageHeader";
import { Row, Col, Image } from "react-bootstrap";
import PageSection from "../../components/PageSection/PageSection";
import InfrastructureCollegeResponsive from "../../components/InfrastructureCollegeResponsive/InfrastructureCollegeResponsive";
import InfrastructureClassroomResponsive from "../../components/InfrastructureClassroomResponsive/InfrastructureClassroomResponsive";
import InfrastructureAuditoriumResponsive from "../../components/InfrastructureAuditoriumResponsive/InfrastructureAuditoriumResponsive";
import InfrastructureITLabResponsive from "../../components/InfrastructureITLabResponsive/InfrastructureITLabResponsive";
import InfrastructureIICResponsive from "../../components/InfrastructureIICResponsive/InfrastructureIICResponsive";

const InfrastructurePage = () => {
	return (
		<PageWrapper className="mt-n6">
			<HeroSection className="bg-page-background-4">
				<HeroCaption
					title="Campus"
					position="bottom-left"
					breadcrumb={true}
					breadcrumbPrefix="Home"
				/>
			</HeroSection>
			<InfrastructreResponsive />
			<InfrastructureCollegeResponsive />
			<InfrastructureClassroomResponsive />
			<InfrastructureAuditoriumResponsive />
			<InfrastructureITLabResponsive />
		</PageWrapper>
	);
};

export default InfrastructurePage;
