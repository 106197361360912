import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";

import PageSection from "../PageSection/PageSection";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";

const ProjectsAndInternshipResponsive = () => {
  const [currentBreakPoint, setCurrentBreakPoint] = useState("");

  const descriptionClasses = classNames("mb-4 text-center text-text-color", {
    "font-sm": currentBreakPoint === "xSmall" || currentBreakPoint === "small",
  });

  useEffect(() => {
    bsBreakpoints.init();
    setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

    const handleBreakPoint = (event) => {
      setCurrentBreakPoint(event.detail);
    };

    window.addEventListener("new.bs.breakpoint", handleBreakPoint);

    return () => {
      window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
    };
  }, []);

  return (
    <PageSection bgClass="background-color">
      <ResponsivePageHeader centered={true} title="Projects and Internships" />
      <p className={descriptionClasses}>
        Our super-specialized training provides students with a comprehensive
        understanding as well as the industry's required skills and expertise,
        ensuring that they are prepared to begin their promising career. In
        turn, candidates gain industry-based analytical knowledge and soft
        skills related to specific fields of expertise.
      </p>
      <p className={descriptionClasses}>
        One part of our training program involves running our management
        students through 100s of simulations & our technical students through
        encouraging them to create new innovative projects. Students with
        internships can get all the exposure required for building a true office
        environment spirit and to be a team player.
      </p>
    </PageSection>
  );
};

export default ProjectsAndInternshipResponsive;
