export const coursesData = [
	{
		title: "B.Tech in Computer Science",
		link: "/academics/courses/cse",
		description: "",
		imageUrl: require("../assets/courses/card/mba.jpg"),
	},
	{
		title: "B.Tech in Electrical Engineering",
		link: "/academics/courses/ee",
		description: "Electrical & Electronics Engineering ",
		imageUrl: require("../assets/courses/card/mca.jpg"),
	},
	{
		title: "B.Tech in Civil Engineering",
		link: "/academics/courses/civil",
		description: "Electrical & Telecommunication Engineering",
		imageUrl: require("../assets/courses/card/mba.jpg"),
	},
	{
		title: "B.Tech in Electrical and Telecommunication Engineering",
		link: "/academics/courses/ente",
		description: " Electrical Engineering",
		imageUrl: require("../assets/courses/card/mca.jpg"),
	},
	{
		title: "B.Tech in Mechanical Engineering",
		link: "/academics/courses/mechanical",
		description: "Civil Engineering ",
		imageUrl: require("../assets/courses/card/mba.jpg"),
	},
	{
		title: "Diploma",
		link: "/academics/courses/diploma",
		description: "Civil Engineering ",
		imageUrl: require("../assets/courses/card/mca.jpg"),
	},
	// {
	// 	title: "ME",
	// 	link: "/departments/mechanical",
	// 	description: "Mechanical Engineering",
	// 	imageUrl: require("../assets/courses/card/mca.jpg"),
	// },
	// {
	// 	title: "Diploma ",
	// 	link: "/departments/basic-sciences-&-humanities",
	// 	description: "Diploma ",
	// 	imageUrl: require("../assets/courses/card/mca.jpg"),
	// },
];
