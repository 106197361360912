import React from "react";
import { Col, Row } from "react-bootstrap";
import { mcaElectivesData } from "../../static-data/electives.data";
import ElectivesCard from "../ElectivesCard/ElectivesCard";
import PageSection from "../PageSection/PageSection";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";

const MCAChoiceOfElectivesResponsive = () => {
	return (
		<PageSection bgClass="white">
			<ResponsivePageHeader title="Choice of Electives" />
			<Row>
				{mcaElectivesData.map((data, index) => (
					<Col
						key={`mba-electives-home${index}`}
						md="6"
						className="d-flex align-items-stretch mb-4"
					>
						<ElectivesCard {...data} />
					</Col>
				))}
			</Row>
		</PageSection>
	);
};

export default MCAChoiceOfElectivesResponsive;
