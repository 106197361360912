export const infographyData = [
	{
		value: "12000+",
		caption: (
			<>
				Passed Out <br /> Students
			</>
		),
	},
	{
		value: "200+",
		caption: (
			<>
				qualified <br /> staff
			</>
		),
	},
	{
		value: "10000+",
		caption: (
			<>
				Placed <br />
				Students
			</>
		),
	},
	{
		value: "1000+",
		caption: (
			<>
				STUDENTS <br /> EVERY YEAR
			</>
		),
	},
	{
		value: "1500+",
		caption: (
			<>
				graduates working in <br /> Fortune Global 500 Companies
			</>
		),
	},
];

export const industryInterfaceInfographyData = [
	{
		value: "2500+",
		caption: (
			<>
				Placed <br />
				Studnents
			</>
		),
	},
	{
		value: "300+",
		caption: (
			<>
				STUDENTS <br /> EVERY YEAR
			</>
		),
	},
	{
		value: "100+",
		caption: (
			<>
				Companies recruit from <br /> us every year
			</>
		),
	},
	{
		value: "500+",
		caption: (
			<>
				graduates working in <br /> Fortune Global 500 Companies
			</>
		),
	},
	{
		value: "6,00,000+",
		caption: (
			<>
				Median payscale offered <br /> by our recruiting companies
			</>
		),
	},
];
