import React from "react";
import { Button, Col, Row } from "react-bootstrap";
import EnquiryFormResponsive from "../EnquiryFormResponsive/EnquiryFormResponsive";
import PageSection from "../PageSection/PageSection";

const ResponsiveContactForm = () => {
	return (
		<PageSection
			className="bg-position--top-center"
			bgClass="page-background-10"
			id="enquiry"
		>
			<Row className="justify-content-between">
				<Col md="6" className="mb-5 mb-md-0 text-white">
					<h3 className="mb-5 font-weight-bold text-white">
						Interested In Taking Admission?
					</h3>
					<h5 className="mb-5 font-weight-semibold text-white">
						Thank you for taking an interest in NIIS Institute of Engineering &
						Technology.
					</h5>
					<p className="mb-5">
						If you’re interested to know more about us, we recommend you to get
						in touch with us or fill up the enquiry form to receive a call from
						us!
					</p>
					<Button variant="highlight" className="px-4">
						Read More
					</Button>
				</Col>
				<Col md="5" className="mb-md-0">
					<EnquiryFormResponsive />
				</Col>
			</Row>
		</PageSection>
	);
};

export default ResponsiveContactForm;
