import React from "react";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import HeroSection from "../../components/HeroSection/HeroSection";
import HeroCaption from "../../components/HeroCaption/HeroCaption";
import IndustryInterfaceCenterResponsive from "../../components/IndustryInterfaceCenterResponsive/IndustryInterfaceCenterResponsive";
import SkillDevelopmentCenterResponsive from "../../components/SkillDevelopmentCenterResponsive/SkillDevelopmentCenterResponsive";
import ProjectsAndInternshipResponsive from "../../components/ProjectsAndInternshipResponsive/ProjectsAndInternshipResponsive";
import RecruiterAndPlacementResponsive from "../../components/RecruiterAndPlacementResponsive/RecruiterAndPlacementResponsive";
import RecruiterResponsive from "../../components/RecruiterResponsive/RecruiterResponsive";
import InfographyChartResponsive from "../../components/InfographyChartResponsive/InfographyChartResponsive";
import IndustryInfographyResponsive from "../../components/IndustryInfographyResponsive/IndustryInfographyResponsive";

const IndustryInterfaceCenterPage = () => {
	return (
		<PageWrapper className="mt-n6">
			<HeroSection className="bg-page-background-11">
				<HeroCaption
					title="Industry Interface Centre"
					position="bottom-left"
					breadcrumb={true}
					breadcrumbPrefix="Home"
				/>
			</HeroSection>
			<IndustryInterfaceCenterResponsive />
			<IndustryInfographyResponsive />
			<InfographyChartResponsive />
			<SkillDevelopmentCenterResponsive />
			<ProjectsAndInternshipResponsive />
			<RecruiterAndPlacementResponsive />
			<RecruiterResponsive />
		</PageWrapper>
	);
};

export default IndustryInterfaceCenterPage;
