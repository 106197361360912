import React from "react";
import { Link } from "react-router-dom";
import { socialMediaData } from "../../static-data/socialmedia.data";
import PageSection from "../PageSection/PageSection";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";
import SocialMediaCard from "../SocialMediaCard/SocialMediaCard";

const SocialBlogResponsive = () => {
  return (
    <PageSection bgClass="white">
      <div className="d-flex align-items-baseline justify-content-between">
        <ResponsivePageHeader title="Social blog" />
        <u>
          <Link
            to={"/"}
            className="text-link-color text-underline font-paragraph-font font-md"
          >
            View More
          </Link>
        </u>
      </div>
      <div className="card-columns">
        {socialMediaData.map((data, index) => (
          <SocialMediaCard key={`social-blog-home${index}`} {...data} />
        ))}
      </div>
    </PageSection>
  );
};

export default SocialBlogResponsive;
