import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";

import PageSection from "../PageSection/PageSection";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";

const FacultyResponsive = () => {
	const [currentBreakPoint, setCurrentBreakPoint] = useState("");

	const descriptionClasses = classNames(
		"text-white mb-4 text-center text-text-color",
		{
			"font-sm":
				currentBreakPoint === "xSmall" || currentBreakPoint === "small",
		}
	);

	useEffect(() => {
		bsBreakpoints.init();
		setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

		const handleBreakPoint = (event) => {
			setCurrentBreakPoint(event.detail);
		};

		window.addEventListener("new.bs.breakpoint", handleBreakPoint);

		return () => {
			window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
		};
	}, []);

	return (
		<PageSection bgClass="background-color">
			<ResponsivePageHeader centered={true} title="Faculty" />
			<p>
				“The beauty of working in academia is that every year, every class, is a
				new adventure.” Working side-by-side with our students, our faculty
				encourage students to see with new eyes. Through our coursework,
				projects and discussions, students and faculty both learn and challenge
				each other on a daily basis.
			</p>
			<p>
				Most of our faculty have earned either their master’s or doctoral degree
				and have atleast 5 years of experience in the field they teach. Above
				all they are committed to your success and to helping you achieve more
				than you ever thought possible.
			</p>
		</PageSection>
	);
};

export default FacultyResponsive;
