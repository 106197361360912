import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";
import { Col, Row } from "react-bootstrap";
// import EnquiryFormResponsive from "../EnquiryFormResponsive/EnquiryFormResponsive";
import PageSection from "../PageSection/PageSection";
// import ContactUsForm from "../ContactUsFrom/ContactUsForm";
import { BsFillHouseFill } from "react-icons/bs";
import CustomIcon from "../CustomIcon/CustomIcon";

import { BiWorld } from "react-icons/bi";
import { AiFillPhone, AiTwotoneMail } from "react-icons/ai";
import { aboutUsCardsData } from "../../static-data/aboutus.data";
import AboutUsMiniCard from "../AboutUsMiniCard/AboutUsMiniCard";
import AlumniCard from "../AlumniCard/AlumniCard";
import { alumniData } from "../../static-data/alumni.data";
// import PageSectionHeader from "../../components/PageSectionHeader/PageSectionHeader";
const AlumniRecordResponsive = () => {
	const [currentBreakPoint, setCurrentBreakPoint] = useState("");

	useEffect(() => {
		bsBreakpoints.init();
		setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

		const handleBreakPoint = (event) => {
			setCurrentBreakPoint(event.detail);
		};

		window.addEventListener("new.bs.breakpoint", handleBreakPoint);

		return () => {
			window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
		};
	}, []);

	const descriptionClasses = classNames("mb-4 text-center text-text-color", {
		"font-sm": currentBreakPoint === "xSmall" || currentBreakPoint === "small",
	});

	return (
		<PageSection bgClass="background-white">
			<ResponsivePageHeader
				centered={true}
				title="NIBA Alumni Across the World! 
 "
			/>
			<Row>
				{alumniData.map((data, index) => {
					return (
						<Col md={3} key={"course-responsive" + index}>
							<AlumniCard {...data} />
						</Col>
					);
				})}
			</Row>
		</PageSection>
	);
};

export default AlumniRecordResponsive;
