import React from "react";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import HeroSection from "../../components/HeroSection/HeroSection";
import HeroCaption from "../../components/HeroCaption/HeroCaption";
import LibraryInfoResponsive from "../../components/LibraryInfoResponsive/LibraryInfoResponsive";
import FacultyResponsive from "../../components/FacultyResponsive/FacultyResponsive";
import CoursesResponsive from "../../components/CoursesResponsive/CoursesResponsive";
import PublicationResponsive from "../../components/PublicationResponsive/PublicationResponsive";

const LibraryandResourcesPage = () => {
	return (
		<PageWrapper className="mt-n6">
			<HeroSection className="bg-page-background-7">
				<HeroCaption
					title="Library & Resources"
					position="bottom-left"
					breadcrumb={true}
					breadcrumbPrefix="Home"
				/>
			</HeroSection>
			<LibraryInfoResponsive />
		</PageWrapper>
	);
};

export default LibraryandResourcesPage;
