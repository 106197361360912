import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import PageSection from "../../components/PageSection/PageSection";
import PageHeader from "../../components/PageHeader/PageHeader";
import PageSectionHeader from '../../components/PageSectionHeader/PageSectionHeader';

const MissionAndVisionPage = () => {
  return (
    <PageWrapper>
      <PageHeader title="Mission & Vision" />
      <PageSection>
        <Row className="align-items-stretch">
          <Col md="6 mb-4">
            <div className="p-4 h-100 bg-diag-1 text-center">
              <PageSectionHeader title="Our Mission" rightStyle="bottom" />
              <Image
                src={require("../../assets/about-us/mission.png").default}
                width={200}
                className="img-fluid mb-4"
              />
              <p>
                Study period and finally making each of them a competent
                technical & management professional. The mission of the
                institute is not only to impart quality education but also to
                produce well groomed professionals as well as good human beings
                capable of facing the challenges of the modern world. The
                Institute strives to bring out the best of the students by
                focusing on teaching, coaching and guiding students throughout
                their.
              </p>
            </div>
          </Col>
          <Col md="6  mb-4">
            <div className="p-4 h-100  bg-diag-1 text-center">
              <PageSectionHeader title="Our Vision" rightStyle="bottom" />
              <Image
                src={require("../../assets/about-us/vision.png").default}
                width={200}
                className="img-fluid mb-4"
              />
              <p>
                To become a world class Institution with disciplined academic
                excellence and prepare students with superior technological
                competence, who in turn will contribute their knowledge and
                skills for economic growth and development of the nation.
              </p>
            </div>
          </Col>
        </Row>
      </PageSection>
    </PageWrapper>
  );
};

export default MissionAndVisionPage;
