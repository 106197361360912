import React from "react";
import { Row, Col, Image } from 'react-bootstrap';
import PageHeader from "../../components/PageHeader/PageHeader";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import PageSection from "../../components/PageSection/PageSection";
import PageSectionHeader from '../../components/PageSectionHeader/PageSectionHeader';

const ConferenceHallPage = () => {
  return (
    <PageWrapper>
      <PageHeader title="Conference Hall" />
      <PageSection>
        <p>
          NIIS MBA college in bhubaneswar has fully air conditioned Auditorium,
          conference hall and meeting room equipped with all the latest gadgets
          and gizmos to provide a world class presentation. Students can use the
          audio visual aids of the media room and can conduct effective
          presentations, meetings and seminars for 25 to 300 people. This
          acoustically designed Auditorium, conference hall and meeting room of
          a high standard is also used to conduct high profile lectures,
          conferences and meetings of keynote speakers, corporate leaders and
          other renowned personalities.
        </p>
      </PageSection>
      <PageSection bgClass="light">
        <PageSectionHeader title="Gallery" leftStyle="bottom" />
        <Row className="justify-content-center">
          <Col md="6" className="mb-4">
            <Image
              src={
                require("../../assets/conference-hall/conference-hall-1.jpg").default
              }
              className="img-fluid rounded"
            />
          </Col>
          <Col md="6">
            <Image
              src={
                require("../../assets/conference-hall/conference-hall-2.jpg").default
              }
              className="img-fluid rounded"
            />
          </Col>
        </Row>
      </PageSection>
    </PageWrapper>
  );
};

export default ConferenceHallPage;
