import React from "react";
import PageWrapper from "../../components/PageWrapper/PageWrapper";
import HeroSection from "../../components/HeroSection/HeroSection";
import HeroCaption from "../../components/HeroCaption/HeroCaption";
import ProgramDescriptionResponsive from "../../components/ProgramDescriptionResponsive/ProgramDescriptionResponsive";
import ResourcesResponsive from "../../components/ResourcesResponsive/ResourcesResponsive";
import MBAChoiceOfElectivesResponsive from "../../components/MBAChoiceOfElectivesResponsive/MBAChoiceOfElectivesResponsive";

const MBACoursePage = () => {
	return (
		<PageWrapper className="mt-n6">
			<HeroSection className="bg-page-background-7">
				<HeroCaption
					title="Academics"
					position="bottom-left"
					breadcrumb={true}
					breadcrumbPrefix="Home"
				/>
			</HeroSection>
			<ProgramDescriptionResponsive
				title="B.Tech in Computer Science"
				desc="Our B.Tech in Computer Science program is designed to provide students with a solid foundation in computer science, programming languages, data structures, algorithms, and software engineering. Graduates of this program are well-equipped to pursue careers in software development, data analysis, or systems analysis and design."
			/>
			{/* <MBAChoiceOfElectivesResponsive /> */}
			{/* <ResourcesResponsive headerContent={""} /> */}
		</PageWrapper>
	);
};

export default MBACoursePage;
