import React, { useState, useEffect } from "react";
import bsBreakpoints from "bs-breakpoints";
import classNames from "classnames";
import { AiFillFilePdf } from "react-icons/ai";
import PageSection from "../PageSection/PageSection";
import ResponsivePageHeader from "../ResponsivePageHeader/ResponsivePageHeader";
import CustomIcon from "../CustomIcon/CustomIcon";
import { Col, Row } from "react-bootstrap";
import AboutUsMiniCard from "../AboutUsMiniCard/AboutUsMiniCard";
import { studentCellData } from "../../static-data/studentcell.data";
import ClubCard from "../ClubCard/ClubCard";

const StudentCellResponsive = () => {
	const [currentBreakPoint, setCurrentBreakPoint] = useState("");

	const descriptionClasses = classNames("text-center mb-4 text-text-color", {
		"font-sm": currentBreakPoint === "xSmall" || currentBreakPoint === "small",
	});

	useEffect(() => {
		bsBreakpoints.init();
		setCurrentBreakPoint(bsBreakpoints.getCurrentBreakpoint());

		const handleBreakPoint = (event) => {
			setCurrentBreakPoint(event.detail);
		};

		window.addEventListener("new.bs.breakpoint", handleBreakPoint);

		return () => {
			window.removeEventListener("new.bs.breakpoint", handleBreakPoint);
		};
	}, []);

	return (
		<div>
			<PageSection bgClass="background-color">
				<ResponsivePageHeader centered={true} title="Clubs & Committees" />
				<p className={descriptionClasses}>
					NIBA is a centre of excellence where education is a journey of
					exploring both the self and the world around. It goes beyond the
					confines of classroom and curriculum. Different learning clubs like IT
					Club (Satya), Cultural Club (Shiva), Nature's Club (Sampad), Marketing
					Club (Sambhav), HR Club (Sampark), Finance Club (Nidhi),
					Entrepreneur's Club (Sangathak), Sports Club(Swasth), Teacher's Club
					(Sikshyak), Literature Club (Smaranika) are functioning at NIIS
					specifically to cater to the needs of the students/teachers in
					realizing their innate potential in different areas of interest and
					showcasing their talent.
				</p>
				<p className={descriptionClasses}>
					Apart from providing an avenue for students to showcase their immense
					talents, these clubs also encourage students to take the initiative in
					organizing events and set new standards of excellence. To quote
					Abraham Lincoln, these are the clubs "of the students, by the
					students, for the students". A brief overview on the clubs is provided
					to give a glimpse of the activity that NIISians pursue in spite of
					their hectic academic schedule.
				</p>
			</PageSection>
			<PageSection bgClass="background-white">
				<Row>
					{studentCellData.map((data, index) => (
						<Col
							md="4 mt-2"
							className="d-flex align-items-stretch mb-4 mb-md-0"
							key={"about-us-home" + index}
						>
							<ClubCard {...data} />
						</Col>
					))}
				</Row>
			</PageSection>
		</div>
	);
};

export default StudentCellResponsive;
