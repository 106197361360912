import React from "react";
import PageHeader from "../../components/PageHeader/PageHeader";
import PageSection from "../../components/PageSection/PageSection";
import PageWrapper from "../../components/PageWrapper/PageWrapper";

const PlacementAboutUsPage = () => {
  return (
    <PageWrapper>
      <PageHeader title="How placement centre works" />
      <PageSection>
        <p>
          The Industry Interface Centre of NIIS is amongst the best in the state. Every year, a record number of students are trained and placed in the top companies across the country. Our platform empowers students and trains them to make them capable of handling changes in the market. And backed by natural resilience, self-empowered training and focus, our students are willing to come out of their comfort zone, take risks and engage challenges persistently.
Various companies from industries such as Banking, Financial services, FMCG, retail, IT etc. are regularly invited for campus placement. We have tied up with Eminent corporate houses across the globe to provide opportunities to our students.
        </p>
      </PageSection>
    </PageWrapper>
  );
};

export default PlacementAboutUsPage;
